import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import langAtom from "../atoms/persisted/lang";

import "../i18n";

const useI18n = () => {
  const language = useRecoilValue(langAtom);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      if (language.toLocaleLowerCase() !== i18n.language) {
        void i18n.changeLanguage(language.toLocaleLowerCase());
      }
    }
  }, [i18n, language]);
};

export default useI18n;
