/**
 * @generated SignedSource<<bb6f46e6cb9187d15170209dc60e32d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserColorScheme = "BLACK" | "BLUE" | "GREEN" | "WINE";
export type UserLanguage = "CS" | "SK" | "DE" | "EN";
export type User_meQuery$variables = {};
export type User_meQuery$data = {
  readonly me: {
    readonly id: string;
    readonly email: string;
    readonly fullName: string | null;
    readonly colorScheme: UserColorScheme;
    readonly language: UserLanguage;
    readonly phoneNumber: string | null;
    readonly overview: string | null;
    readonly showPhoneNumber: boolean;
    readonly image: string | null;
  } | null;
};
export type User_meQuery = {
  variables: User_meQuery$variables;
  response: User_meQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserType",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "colorScheme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "overview",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "User_meQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "User_meQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "349dd7e4d49a4f48efa4f7c29cc60cb3",
    "id": null,
    "metadata": {},
    "name": "User_meQuery",
    "operationKind": "query",
    "text": "query User_meQuery {\n  me {\n    id\n    email\n    fullName\n    colorScheme\n    language\n    phoneNumber\n    overview\n    showPhoneNumber\n    image\n  }\n}\n"
  }
};
})();

(node as any).hash = "cae5a50acaef97c6a5cdf77f4a98bf37";

export default node;
