import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { fetchQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { LatLng } from "react-native-maps";
import { uniqueId } from "lodash";
import Header, {
  RangeWrapper,
  RangeItem,
  RangeSubItem,
} from "../../components/Header";
import { BodyContentWrapper } from "../../components/wrappers";
import Slider from "../../components/Slider";
import EditValue from "../../components/EditValue";
import Button from "../../components/Button";
import Text from "../../components/Text";
import ElementWrapper from "../../components/ElementWrapper";
import TreeComponent from "../../components/tree";
import { Tree } from "../../@types/tree";

import { themeColors } from "../../atoms/persisted/theme";
import userAtom from "../../atoms/persisted/user";

import { RootStackScreenProps } from "../../@types/navigation";
import { radiusSizes } from "../../constants/Sizes";
import { getNavigationBack } from "../../utils/navigation";
import { DEFAULT_RADIUS } from "../../atoms/filter";
import { DayOfTheWeekChoices, ServiceRewardChoices } from "../../types/graphql";
import FormInput from "../../components/form";
import { getCategoriesQuery } from "../../relay/queries/Categories";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import { getServiceQuery, ServiceDetail } from "../../relay/queries/Services";
import { Services_serviceQuery } from "../../__generated__/Services_serviceQuery.graphql";
import switcherAtom from "../../atoms/switcher";
import refetchKeyAtom from "../../atoms/refetchKey";
import markersAtom from "../home/Maps/atoms";
import { useUpdateService } from "../../services/services";
import useGetServicesData from "./serviceData";
import useUserServices from "../../hooks/useUserServices";

type FormValues = {
  title: string;
  price: string;
  startDate: string;
  endDate: string;
  timeFrom: string;
  timeTo: string;
  description: string;
};

const EditAd = ({ navigation, route }: RootStackScreenProps<"EditAd">) => {
  const user = useRecoilState(userAtom);
  const theme = useRecoilValue(themeColors);
  const [switcher, setSwitcher] = useRecoilState(switcherAtom);
  const [markers, setMarkers] = useRecoilState(markersAtom);
  const marker = useMemo(() => markers?.[0] ?? null, [markers]);
  const { t } = useTranslation();

  const [, setRefetchKey] = useRecoilState(refetchKeyAtom);

  if (!route?.params?.id) throw new Error("invalid id");
  if (!user) navigation.navigate("Login");

  const { id } = route.params;

  const { environment } = useResettableRelayContext();

  const [service, setService] = useState<ServiceDetail>(null);
  const [categories, setCategories] = useState<Tree<string>>(null);

  const [loading, setLoading] = useState(false);

  const getService = useCallback(async () => {
    const { service: data } = await fetchQuery<Services_serviceQuery>(
      environment,
      getServiceQuery,
      { id }
    ).toPromise();

    if (!data) return null;

    return data;
  }, [environment, id]);

  const getCategories = useCallback(async () => {
    const { allServiceCategories: res } = await fetchQuery<{
      response: { allServiceCategories: Tree<string> };
      variables: Record<string, string>;
    }>(environment, getCategoriesQuery, {}).toPromise();

    if (!res) return null;

    return res;
  }, [environment]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [selectedDays, setSelectedDays] = useState<DayOfTheWeekChoices[]>([]);

  const [rewardTypes, setRewardTypes] = useState<ServiceRewardChoices[]>([]);

  const { refetch: refetchUserServices } = useUserServices({
    active: false,
  });

  const refetch = useCallback(async () => {
    setLoading(true);
    setRefetchKey((p) => p + 1);
    const ser = await getService();
    const cat = await getCategories();

    if (!(ser && cat)) {
      setLoading(false);
      return;
    }

    const coordinate: LatLng = {
      latitude: Number((ser.coordinates as string).split(",")[0]),
      longitude: Number((ser.coordinates as string).split(",")[1]),
    };
    setMarkers([
      {
        title: ser.coordinatesName ?? "",
        coordinate,
        identifier: uniqueId(),
        radius: ser.radius ?? 1,
      },
    ]);
    setRewardTypes([ser.reward]);
    setSelectedDays(
      ((ser.days ?? []).length === 7
        ? [...ser.days, "EVERYDAY"]
        : ser.days) as DayOfTheWeekChoices[]
    );

    setSelectedCategories(
      (ser.categories?.edges ?? []).map((item) => item.node.id)
    );
    setRadius(ser.radius);
    setSwitcher(ser.option);

    setService(ser);
    setCategories(cat);

    void refetchUserServices();

    setLoading(false);
  }, [
    setRefetchKey,
    getService,
    getCategories,
    setMarkers,
    setSwitcher,
    refetchUserServices,
  ]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const [updateService] = useUpdateService({
    onCompleted: () => void refetch(),
    onError: () => {},
  });

  const { DAYS_DATA, REWARD_DATA } = useGetServicesData();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "onChange",
  });

  useEffect(() => {
    if (!service) return;
    reset({
      description: service.description,
      title: service.title,
      timeFrom: moment(service.timeFrom as string, ["HH:mm:ss"]).format(
        "HH:mm"
      ),
      timeTo: moment(service.timeTo as string, ["HH:mm:ss"]).format("HH:mm"),
      price: service.price ? Number(service.price).toFixed(0) : undefined,
      startDate: moment(service.startDate as string, ["YYYY-MM-DD"]).format(
        "DD.MM.YYYY"
      ),
      endDate: moment(service.endDate as string, ["YYYY-MM-DD"]).format(
        "DD.MM.YYYY"
      ),
    });
  }, [reset, service]);

  const onSubmit = (data: FormValues) => {
    const { endDate, startDate, timeFrom, timeTo, ...submitData } = data;

    const sDate = moment(startDate, ["DD.MM.YYYY"]);
    const eDate = moment(endDate, ["DD.MM.YYYY"]);

    if (sDate > eDate) {
      setError("startDate", { message: t("form.errorRange") });
      return null;
    }

    if (sDate <= moment().add(-1, "day")) {
      setError("startDate", { message: t("form.errorRange") });
      return null;
    }

    const sTime = moment(timeFrom, ["HH:mm:ss"]);
    const eTime = moment(timeTo, ["HH:mm:ss"]);

    if (sTime > eTime) {
      setError("timeFrom", { message: t("form.errorRange") });
      return null;
    }

    const days = selectedDays.filter((item) => (item as string) !== "EVERYDAY");

    const coords = marker.coordinate as LatLng;

    updateService({
      id,
      startDate:
        sDate.format("YYYY-MM-DD") !== "Invalid date"
          ? sDate.format("YYYY-MM-DD")
          : null,
      timeFrom: timeFrom !== "Invalid date" ? timeFrom : null,
      timeTo: timeTo !== "Invalid date" ? timeTo : null,
      endDate:
        eDate.format("YYYY-MM-DD") !== "Invalid date"
          ? eDate.format("YYYY-MM-DD")
          : null,
      categories: selectedCategories,
      days,
      reward: rewardTypes[0],
      radius,
      coordinatesName: marker?.title ?? "",
      coordinates: coords
        ? `${coords.latitude?.toString()},${coords.longitude?.toString()}`
        : undefined,
      ...submitData,
    });
    getNavigationBack(navigation);

    return null;
  };

  const isFormValid = useMemo(
    () =>
      isValid &&
      selectedCategories.length >= 1 &&
      selectedDays.length >= 1 &&
      rewardTypes.length >= 1,
    [
      isValid,
      rewardTypes.length,
      selectedCategories.length,
      selectedDays.length,
    ]
  );

  return (
    <Header
      centerTitle={t("ad.editHeader")}
      leftAction={() => getNavigationBack(navigation)}
      switcher
      scrollingBody
      loadingBody={loading}
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <FormInput
              name="title"
              variant="text"
              required
              control={control}
              placeholder={t("ad.titlePlaceholder")}
              label={`${t("ad.title")}:`}
              returnKeyType="done"
            />
            <ElementWrapper
              title={`${t("filter.adType")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </ElementWrapper>
            <ElementWrapper title={`${t("filter.address")}:`}>
              <EditValue
                value={marker?.title ?? ""}
                buttonTitle={t("filter.change").toLocaleLowerCase()}
                onPress={() =>
                  navigation.navigate("Map", {
                    maxMarkers: 1,
                    markerRadius: radius,
                  })
                }
              />
            </ElementWrapper>
            <ElementWrapper title={`${t("filter.range")}:`}>
              <Slider
                values={radius}
                setValues={(num: number) => {
                  setRadius(num);
                  setMarkers((prev) =>
                    prev.map((prevState) => ({
                      ...prevState,
                      radius: num * 1000,
                    }))
                  );
                }}
                unit="km"
              />
            </ElementWrapper>
            <ElementWrapper
              title={`${t("filter.reward")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={REWARD_DATA}
                selectedCategories={rewardTypes}
                setSelectedCategories={setRewardTypes}
                onlyOne={switcher === "PROVIDE"}
                required
              />
            </ElementWrapper>
            {rewardTypes?.[0] === "PRICE" && (
              <FormInput
                name="price"
                variant="text"
                control={control}
                placeholder={t("filter.rewardPlaceholder")}
                keyboardType="number-pad"
                returnKeyType="done"
                required
              />
            )}
            <ElementWrapper
              title={`${t("filter.days")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={DAYS_DATA}
                selectedCategories={selectedDays}
                setSelectedCategories={setSelectedDays}
              />
            </ElementWrapper>
            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                width="100%"
                textAlign="left"
              >
                {`${t("filter.time")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeFrom"
                    required
                    variant="masked"
                    control={control}
                    type="time"
                    setError={(message) => setError("timeFrom", { message })}
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeTo"
                    variant="masked"
                    control={control}
                    required
                    type="time"
                    setError={(message) => setError("timeTo", { message })}
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                textAlign="left"
              >
                {`${t("filter.date")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="startDate"
                    variant="masked"
                    required
                    control={control}
                    type="date"
                    setError={(message) => setError("startDate", { message })}
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="endDate"
                    variant="masked"
                    required
                    control={control}
                    type="date"
                    setError={(message) => setError("startDate", { message })}
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <FormInput
              name="description"
              variant="text"
              control={control}
              required
              type="date"
              returnKeyType="go"
              textarea
              height={100}
            />
            <Button
              width="95%"
              margin="10px 0 0 0"
              justifyContent="center"
              borderRadius={radiusSizes.smallRadius}
              background={isFormValid ? theme.secondary : theme.primaryOpacity}
              onPress={handleSubmit(onSubmit)}
            >
              <Text size="21px" font="regular" textColor={theme.secondaryText}>
                {t("ad.submit")}
              </Text>
            </Button>
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default EditAd;
