import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  FC,
  useRef,
} from "react";
import { Platform, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import MapView, {
  Marker,
  LatLng,
  MapEvent,
  Region,
  Circle,
  Animated,
} from "react-native-maps";
import * as Location from "expo-location";
import { uniqueId } from "lodash";
import styled from "styled-components/native";

import Header from "../../../components/Header";
import TextInput from "../../../components/form/TextInput";

import { themeColors } from "../../../atoms/persisted/theme";

import {
  defaultMapLocation,
  MapProps,
  mapStyles,
  MapWrapper,
  MarkerType,
  SearchBar,
  searchBarStyles,
} from "./utils";
import markersAtom from "./atoms";
import { getNavigationBack } from "../../../utils/navigation";
import getAddressFromCoords from "../../../hooks/useGetAddress";
import Text from "../../../components/Text";

const MarkerComponent =
  Platform.OS === "web"
    ? (MapView as unknown as { Marker: typeof Marker }).Marker
    : Marker;

const PopOver = styled(View)`
  margin-horizontal: 8px;
`;

const Map: FC<MapProps> = (props) => {
  const theme = useRecoilValue(themeColors);
  const [granted, setGrant] = useState(false);
  const { t } = useTranslation();

  const map = useRef<MapView | null>(null);

  const [markersUk, setMarkersUK] = useRecoilState(markersAtom);
  const [address, setAddress] = useState("");

  const animateMap = (coords: LatLng, zoom?: number) => {
    if (!map.current) return;

    if (Platform.OS === "web") {
      map.current.animateToRegion(coords as Region);
    } else {
      map.current.animateCamera({
        center: coords,
        zoom,
      });
    }
  };

  const getCoordinates = useCallback(async () => {
    const [coords] = await Location.geocodeAsync(address);
    if (coords) {
      animateMap({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    }
  }, [address]);

  const markers = useMemo(
    () => (Array.isArray(markersUk) ? markersUk : [markersUk]),
    [markersUk]
  );

  const removeMarker = useCallback(
    (
      event: MapEvent<{
        action: "marker-press";
        id: string;
      }>
    ) => {
      const { id } = event.nativeEvent;
      if (!id) return;

      setMarkersUK((prev) =>
        !Array.isArray(prev)
          ? null
          : prev.filter((marker) => marker.identifier !== id)
      );
    },
    [setMarkersUK]
  );

  type NewEvent = { latLng: { lat: () => number; lng: () => number } };

  const getCoords = useCallback((e: unknown): LatLng => {
    if ((e as MapEvent)?.nativeEvent?.coordinate)
      return (e as MapEvent).nativeEvent.coordinate;

    if ((e as NewEvent).latLng) {
      const webEvent = (e as NewEvent).latLng;
      return {
        latitude: webEvent.lat(),
        longitude: webEvent.lng(),
      };
    }

    return null;
  }, []);

  const addMarker = useCallback(
    async (event: MapEvent) => {
      const coords = getCoords(event);
      if (!coords) return;

      const title = await getAddressFromCoords({ coords, skipCheck: true });

      const newMarker: MarkerType = {
        coordinate: coords,
        title,
        identifier: uniqueId(),
        radius: props.markerRadius,
      };

      setMarkersUK((prevState) => {
        if (prevState.length >= props.maxMarkers) {
          const slice = prevState.length - props.maxMarkers + 1;
          return [...prevState.slice(slice), newMarker];
        }

        return [...prevState, newMarker];
      });
      animateMap({
        longitude: Number(newMarker.coordinate.longitude),
        latitude: Number(newMarker.coordinate.latitude),
      });
    },
    [getCoords, props.markerRadius, props.maxMarkers, setMarkersUK]
  );

  useEffect(() => {
    if (address) void getCoordinates();
  }, [address, getCoordinates, granted]);

  useEffect(() => {
    if (!granted) return;

    const getCurrentLoc = async () => {
      const location = await Location.getCurrentPositionAsync();
      if (markers.length >= 1) return;

      animateMap(
        {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
        14
      );
    };

    void getCurrentLoc();
  }, [granted, markers.length]);

  const check = useCallback(async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status === "granted") setGrant(true);
  }, []);

  useEffect(() => {
    void check();
  }, [check]);

  const renderMapContent = () => (
    <>
      {markers.map((marker, i) =>
        marker?.coordinate ? (
          <MarkerComponent
            coordinate={marker.coordinate}
            pinColor={theme.primary}
            onPress={removeMarker}
            key={`marker-${i.toFixed()}`}
            {...marker}
          />
        ) : null
      )}
      {markers.map((marker, i) =>
        marker?.coordinate && marker?.radius && Platform.OS !== "web" ? (
          <Circle
            key={`circle-${i.toFixed()}`}
            center={marker.coordinate as LatLng}
            radius={marker.radius}
            fillColor={theme.primaryOpacity}
            strokeColor={theme.primary}
          />
        ) : null
      )}
    </>
  );

  return (
    <Header
      heightHeader={125}
      leftTitle={t("general.save")}
      leftAction={() =>
        props.navigation ? getNavigationBack(props.navigation) : undefined
      }
      bodyHeader={
        <>
          <SearchBar style={searchBarStyles}>
            <TextInput
              value={address}
              unFocusBorderColor={theme.border}
              focusBorderColor={theme.primary}
              onChange={setAddress}
              placeholder={t("filter.search")}
              returnKeyType="search"
              iconName="search1"
              iconType="antDesign"
            />
          </SearchBar>
          <PopOver>
            <Text style={searchBarStyles}>{t("map.longtouch")}</Text>
          </PopOver>
        </>
      }
      body={
        <>
          <MapWrapper>
            {Platform.OS === "web" ? (
              <MapView
                onPress={(e) =>
                  Platform.OS === "web" ? void addMarker(e) : undefined
                }
                onLongPress={(e) =>
                  Platform.OS !== "web" ? void addMarker(e) : undefined
                }
                showsUserLocation
                showsMyLocationButton
                showsCompass
                showsBuildings
                loadingEnabled
                style={mapStyles}
                initialRegion={
                  markers.length >= 1
                    ? ({
                        ...defaultMapLocation,
                        ...markers[0].coordinate,
                      } as Region)
                    : defaultMapLocation
                }
                ref={map}
              >
                {renderMapContent()}
              </MapView>
            ) : (
              <Animated
                onPress={(e) =>
                  Platform.OS === "web" ? void addMarker(e) : undefined
                }
                onLongPress={(e) =>
                  Platform.OS !== "web" ? void addMarker(e) : undefined
                }
                showsUserLocation
                showsMyLocationButton
                showsCompass
                showsBuildings
                loadingEnabled
                style={mapStyles}
                initialRegion={
                  markers.length >= 1
                    ? ({
                        ...defaultMapLocation,
                        ...markers[0].coordinate,
                      } as Region)
                    : defaultMapLocation
                }
                ref={map}
              >
                {renderMapContent()}
              </Animated>
            )}
          </MapWrapper>
        </>
      }
    />
  );
};

export default Map;
