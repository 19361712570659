import { atom } from "recoil";
import { UserService } from "../../relay/queries/Services";

export const activeServicesAtom = atom<UserService[]>({
  key: "activeServicesAtom",
  default: [],
});

export const inactiveServicesAtom = atom<UserService[]>({
  key: "inactiveServicesAtom",
  default: [],
});
