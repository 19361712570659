import {
  Environment,
  Network,
  RecordSource,
  Store,
  SubscribeFunction,
  GraphQLResponse,
  Observable,
} from "relay-runtime";
import { RelayObservable } from "relay-runtime/lib/network/RelayObservable";
import { SubscriptionClient } from "subscriptions-transport-ws";

// upstream (relay generation)
// eslint-disable-next-line import/no-cycle
import fetchGraphQL from "./fetch";

import relayTransactionLogger from "./util";
import getEnvVars from "../environment";

export const { WSS_URL } = getEnvVars();

const subscribe: SubscribeFunction = (request, variables) => {
  const subscriptionClient = new SubscriptionClient(WSS_URL, {
    reconnect: true,
    connectionParams: {
      headers: {
        // Authorization: `Bearer ${""}`,
      },
    },
  });
  const subscribeObservable = subscriptionClient.request({
    query: request.text ?? "",
    operationName: request.name,
    variables,
  });

  return Observable.from(
    subscribeObservable as unknown
  ) as RelayObservable<GraphQLResponse>;
};

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchGraphQL, subscribe),
    store: new Store(new RecordSource()),
    log: __DEV__ ? relayTransactionLogger : null,
  });
}

export default createRelayEnvironment;
