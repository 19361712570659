import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import s from "styled-components/native";

import { Tag, Button, Location, Row } from "../Header";
import { ColumnWrapper } from "../wrappers";
import Text from "../Text";
import { View, TouchableOpacity } from "../Themed";
import Icon from "../Icon";
import { themeColors } from "../../atoms/persisted/theme";
import { SectionItemProps } from "./SectionItem";

const Wrapper = s(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  padding: 17px;
  margin: 7px 0px;
  border-radius: 24px;
  overflow: hidden;
`;

const Column = s(View)`
  alignItems: center;
  justify-content: center;
  flexDirection: row;
  width: 100%;
`;

const ContentWrap = s(View)`
  flex: 8
`;

const ButtonWrap = s(TouchableOpacity)`
  flex: 2;
  justify-content: center;
  align-items: center;
`;

const TightSectionItem = ({
  item,
  onPress,
  onButtonPress,
}: SectionItemProps) => {
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();
  const isReported = useMemo(
    () => item?.status === "RESOLVED_REPORT",
    [item?.status]
  );
  const isProviding = useMemo(() => item?.option === "PROVIDE", [item?.option]);
  const pressAction = useCallback(() => onPress?.(item), [item, onPress]);

  if (!item) return null;
  return (
    <Wrapper
      background={isReported ? theme.secondaryOpacity : theme.primarySurface}
      onPress={pressAction}
    >
      <Column>
        <ContentWrap>
          <Row padding={5}>
            <ColumnWrapper style={{ flex: 1 }}>
              {isReported && (
                <Text
                  size="15px"
                  flex="3"
                  textAlign="left"
                  textColor={theme.secondary}
                >
                  {`⚠ ${t("ad.reported")}`}
                </Text>
              )}
              <Text
                size="13px"
                flex="3"
                textAlign="left"
                textColor={theme.highlightText}
              >
                {item.title}
              </Text>
            </ColumnWrapper>
            <Tag
              background={
                isProviding ? theme.successSurface : theme.primaryOpacity
              }
              marginLeft={30}
            >
              <Text
                textColor={isProviding ? theme.successText : theme.primary}
                size="10px"
              >
                {isProviding ? t("filter.sell") : t("filter.offer")}
              </Text>
            </Tag>
          </Row>
          <Row padding={5}>
            <Text
              font="bold"
              textColor={theme.secondary}
              size="15px"
              textAlign="left"
              style={{ maxWidth: "50%" }}
              flex="1"
            >
              {item.price &&
                `${Number(item.price).toFixed(0)} ${item.currency}`}
              {!item.price &&
                item.reward &&
                t(
                  `filter.${
                    (item.reward as string).toLowerCase() as
                      | "free"
                      | "exchange"
                      | "price"
                  }`
                )}
            </Text>
            <Location>
              <Icon
                type="materialIcons"
                name="location-pin"
                size={13}
                color={theme.border}
              />
              <Text
                textColor={theme.border}
                size="12px"
                textAlign="left"
                margin="0 0 0 5px"
              >
                {item.coordinatesName}
              </Text>
            </Location>
          </Row>
        </ContentWrap>
        <ButtonWrap
          onPress={(e) => {
            e.stopPropagation();
            onButtonPress?.(item);
          }}
        >
          <Button background={theme.secondarySurface}>
            <Icon
              type="fontAwesome5"
              name={item ? "pen" : "check"}
              size={13}
              color={theme.text}
            />
          </Button>
        </ButtonWrap>
      </Column>
    </Wrapper>
  );
};

export default TightSectionItem;
