import { useCallback, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { fetchQuery } from "react-relay";

import userAtom from "../atoms/persisted/user";
import { meQuery } from "../relay/queries/User";
import { User_meQuery } from "../__generated__/User_meQuery.graphql";
import { ACCESS_TOKEN_KEY } from "../atoms/accessToken";
import { getStorageValue } from "../services/storage";
import { theme } from "../atoms/persisted/theme";
import langAtom from "../atoms/persisted/lang";
import { useResettableRelayContext } from "../relay/ResettableRelayProvider";

const useAuthUser = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const setTheme = useSetRecoilState(theme);
  const setLanguage = useSetRecoilState(langAtom);

  const { environment } = useResettableRelayContext();

  const refetchUser = useCallback(async () => {
    const access = await getStorageValue(ACCESS_TOKEN_KEY);
    if (!access) return;

    const { me: userData } = await fetchQuery<User_meQuery>(
      environment,
      meQuery,
      {}
    ).toPromise();

    setUser(userData);

    setTheme(userData.colorScheme ?? "BLUE");
    setLanguage(userData.language);
  }, [environment, setLanguage, setTheme, setUser]);

  const checkUser = useCallback(async () => {
    const access = await getStorageValue(ACCESS_TOKEN_KEY);
    if (!access) {
      setUser(null);
    }
    if (!user) {
      await refetchUser();
    }
  }, [refetchUser, setUser, user]);

  useEffect(() => {
    void checkUser();
  }, [checkUser]);

  return { checkUser, refetchUser } as const;
};

export default useAuthUser;
