const iconSizes = {
  basicSize: 30,
  middleSize: 25,
  smallSize: 20,
};

const inputSizes = {
  maxLength: 50,
  commentLength: 1000,
  height: 49,
};

const radiusSizes = {
  miniRadius: 5,
  smallRadius: 10,
  mediumRadius: 15,
  basicRadius: 20,
  biggerRadius: 25,
  largeRadius: 40,
};

export { iconSizes, inputSizes, radiusSizes };
