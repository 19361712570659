/**
 * @generated SignedSource<<2dca34544d58637a3a937728fb0b1891>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ColorSchemeChoices = "BLACK" | "BLUE" | "GREEN" | "WINE";
export type LanguageChoices = "CS" | "SK" | "DE" | "EN";
export type UserRoleChoices = "SUPERADMIN" | "ADMIN" | "CUSTOMER" | "GUEST";
export type CreateUserInput = {
  email: string;
  fullName: string;
  phoneNumber: string;
  showPhoneNumber: boolean;
  language: LanguageChoices;
  vendor: string;
  colorScheme: ColorSchemeChoices;
  role?: UserRoleChoices | null;
  clientMutationId?: string | null;
};
export type User_createUserMutation$variables = {
  input: CreateUserInput;
};
export type User_createUserMutation$data = {
  readonly createUser: {
    readonly obj: {
      readonly id: string;
      readonly email: string;
    } | null;
  } | null;
};
export type User_createUserMutation = {
  variables: User_createUserMutation$variables;
  response: User_createUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUserPayload",
    "kind": "LinkedField",
    "name": "createUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_createUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_createUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b3f883904769310dcc0e8c8c14f3ec45",
    "id": null,
    "metadata": {},
    "name": "User_createUserMutation",
    "operationKind": "mutation",
    "text": "mutation User_createUserMutation(\n  $input: CreateUserInput!\n) {\n  createUser(input: $input) {\n    obj {\n      id\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "faac3660239c4e6aacdfb918c91e9c16";

export default node;
