import { graphql } from "react-relay";

const meQuery = graphql`
  query User_meQuery {
    me {
      id
      email
      fullName
      colorScheme
      language
      phoneNumber
      overview
      showPhoneNumber
      image
      phoneNumber
    }
  }
`;

const getUser = graphql`
  query User_userQuery($id: ID!) {
    user(id: $id) {
      id
      email
      fullName
      colorScheme
      language
      phoneNumber
      overview
      showPhoneNumber
      image
      phoneNumber
    }
  }
`;

const loginMutation = graphql`
  mutation User_tokenAuthMutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;

const refreshAccessToken = graphql`
  mutation User_refreshTokenMutation($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

const createUserMutation = graphql`
  mutation User_createUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      obj {
        id
        email
      }
    }
  }
`;

const resetPasswordMutation = graphql`
  mutation User_resetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      ok
    }
  }
`;

const updateUserMutation = graphql`
  mutation User_updateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      obj {
        id
        email
        fullName
        colorScheme
        language
        phoneNumber
        overview
        showPhoneNumber
        image
      }
    }
  }
`;

const updateUserPasswordMutation = graphql`
  mutation User_updateUserPasswordMutation($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      obj {
        id
      }
    }
  }
`;

const deleteUserMutation = graphql`
  mutation User_deleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      obj {
        id
      }
    }
  }
`;

export {
  meQuery,
  loginMutation,
  createUserMutation,
  refreshAccessToken,
  resetPasswordMutation,
  updateUserMutation,
  getUser,
  updateUserPasswordMutation,
  deleteUserMutation,
};
