import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { CompositeNavigationProp } from "@react-navigation/native";

import { RootTabParamList, RootStackParamList } from "../@types/navigation";

const getNavigationBack = (
  nav: NativeStackNavigationProp<RootStackParamList, keyof RootStackParamList>
) => {
  if (nav.canGoBack()) {
    nav.goBack();
  } else {
    nav.navigate("Root");
  }
};

const getNavigationTabBack = (
  nav: CompositeNavigationProp<
    BottomTabNavigationProp<RootTabParamList, keyof RootTabParamList>,
    NativeStackNavigationProp<RootStackParamList, keyof RootStackParamList>
  >
) => {
  if (nav.canGoBack()) {
    nav.goBack();
  } else {
    nav.navigate("Root");
  }
};

export { getNavigationBack, getNavigationTabBack };
