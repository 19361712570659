import * as React from "react";
import {
  Text as DefaultText,
  View as DefaultView,
  TouchableOpacity as DefaultTouchableOpacity,
  ColorValue,
} from "react-native";
import { useRecoilValue } from "recoil";
import { Colors, themeColors } from "../atoms/persisted/theme";
import { ColorSchemeChoices } from "../types/graphql";

export function useThemeColor(
  props: { color?: string },
  colorName: keyof typeof Colors[ColorSchemeChoices]
) {
  const theme = useRecoilValue(themeColors);
  const colorFromProps = props.color;

  if (colorFromProps) {
    return colorFromProps;
  }

  return theme[colorName];
}

type ThemeProps = {
  background?: string;
};

type ThemeTextProps = {
  textColor?: string;
};

export type TextProps = ThemeTextProps & DefaultText["props"];
export type ViewProps = ThemeProps & DefaultView["props"];
export type TouchableOpacityProps = ThemeProps &
  DefaultTouchableOpacity["props"];

export function Text(props: TextProps) {
  const { style, textColor, ...otherProps } = props;
  const color = useThemeColor({ color: textColor }, "text") as ColorValue;
  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const { style, background, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { color: background },
    "transparent"
  ) as ColorValue;

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function TouchableOpacity(props: TouchableOpacityProps) {
  const { style, background, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { color: background },
    "transparent"
  ) as ColorValue;

  return (
    <DefaultTouchableOpacity
      style={[{ backgroundColor }, style]}
      {...otherProps}
    />
  );
}
