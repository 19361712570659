/**
 * @generated SignedSource<<865430c147791a97c7ff8c4ff8f9c51a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserInput = {
  id: any;
  clientMutationId?: string | null;
};
export type User_deleteUserMutation$variables = {
  input: DeleteUserInput;
};
export type User_deleteUserMutation$data = {
  readonly deleteUser: {
    readonly obj: {
      readonly id: string;
    } | null;
  } | null;
};
export type User_deleteUserMutation = {
  variables: User_deleteUserMutation$variables;
  response: User_deleteUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUserPayload",
    "kind": "LinkedField",
    "name": "deleteUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_deleteUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_deleteUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53b9f40a27e06bcd20a91b23b61ae20f",
    "id": null,
    "metadata": {},
    "name": "User_deleteUserMutation",
    "operationKind": "mutation",
    "text": "mutation User_deleteUserMutation(\n  $input: DeleteUserInput!\n) {\n  deleteUser(input: $input) {\n    obj {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a677ee4d1a24373812ba25c0ddfcf81";

export default node;
