import React, { useEffect } from "react";

import { ScrollView, Platform } from "react-native";
import Constants from "expo-constants";
import s from "styled-components/native";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import Button from "../../components/Button";
import { View } from "../../components/Themed";
import Text from "../../components/Text";
import { CenterWrapper } from "../../components/wrappers";

import { themeColors } from "../../atoms/persisted/theme";
import { getStorageValue, setStorageValue } from "../../services/storage";

import IllustrationCloud from "../../../assets/icons/auth/illustration_cloud.svg";
import IllustrationHero from "../../../assets/icons/auth/illustration_hero.svg";
import IllustrationPanoramaWhite from "../../../assets/icons/auth/illustration_panorama_white.svg";

import { RootStackScreenProps } from "../../@types/navigation";

import { radiusSizes } from "../../constants/Sizes";

const Wrapper = s(View)`
  padding-top: ${Platform.OS === "ios" ? Constants.statusBarHeight + 5 : 15}px;
  flex: 1;
`;

const ImageWrapper = s(View)`
  height: ${Platform.OS === "web" ? "250px" : "150px"};
`;

const Auth = ({ navigation }: RootStackScreenProps<"Auth">) => {
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();

  useEffect(() => {
    const getInitialRouteName = async () => {
      try {
        const root = await getStorageValue("@app_auth_root");
        if (root) {
          navigation.replace("Root");
        }
      } catch (e) {
        console.warn(e);
      }
    };

    void getInitialRouteName();
  }, [navigation]);

  return (
    <Wrapper background={theme.primary}>
      <ScrollView>
        <CenterWrapper background={theme.primary}>
          <Text
            size="23px"
            lineHeight="23px"
            font="regular"
            textColor={theme.primaryText}
          >
            {t("auth.root.title")}
          </Text>
          <IllustrationCloud
            opacity={0.4}
            style={{ position: "relative", top: -5, left: -135 }}
          />
          <IllustrationCloud
            opacity={0.4}
            style={{ position: "relative", top: -30, right: -175 }}
          />
          <Text
            size="37px"
            font="regular"
            lineHeight="37px"
            textAlign="center"
            margin="-20px 0"
            textColor={theme.primaryText}
          >
            {t("auth.root.header")}
          </Text>
          <IllustrationHero style={{ marginTop: 30 }} />
          <Text
            margin="15px"
            size="15px"
            font="light"
            width={Platform.OS === "web" ? "350px" : null}
            textColor={theme.primaryText}
          >
            {t("auth.root.text")}
          </Text>
          <ImageWrapper background={theme.primary}>
            <IllustrationPanoramaWhite
              opacity={0.4}
              style={{ marginTop: -30 }}
            />
          </ImageWrapper>
          <Button
            justifyContent="center"
            webMaxWidth="320px"
            width="98%"
            borderRadius={radiusSizes.smallRadius}
            background={theme.secondary}
            onPress={async () => {
              try {
                await setStorageValue("@app_auth_root", "true");
              } catch (e) {
                console.warn(e);
              }
              navigation.replace("Root");
            }}
            style={{
              position: "relative",
              top: Platform.OS === "web" ? -100 : -90,
            }}
          >
            <Text size="21px" font="bold" textColor={theme.secondaryText}>
              {t("auth.root.button")}
            </Text>
          </Button>
        </CenterWrapper>
      </ScrollView>
    </Wrapper>
  );
};
export default Auth;
