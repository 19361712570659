import { AtomEffect, DefaultValue } from "recoil";
import AsyncStorageLib from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import { recoilPersist } from "recoil-persist";
import { removeStorageValue, setStorageValue } from "../../services/storage";

const { persistAtom } = recoilPersist({
  key: "@persistedStates",
  storage: Platform.OS === "web" ? localStorage : AsyncStorageLib,
});

// custom made persister to save atoms in AsyncStorage
const customAtomPersister = <T>(key: string): AtomEffect<T> => {
  const remove = () => void removeStorageValue(key);
  const set = (newVal: T) =>
    void setStorageValue(
      key,
      // prevent ""value""
      JSON.stringify(newVal)
    );

  return ({ setSelf, onSet }) => {
    setSelf(
      AsyncStorageLib.getItem(key).then((savedValue) => {
        if (savedValue == null) return new DefaultValue();

        try {
          const ret = JSON.parse(savedValue) as T;
          return ret;
        } catch (e) {
          return savedValue as unknown as T;
        }
      })
    );

    onSet((newValue, _, isReset) => {
      // generally dont want to save null/undefined in storage
      if (!newValue || isReset) {
        remove();
        return;
      }

      void set(newValue);
    });
  };
};

const persister = (key: string) => {
  if (Platform.OS === "web") return persistAtom;

  return customAtomPersister(key);
};

export default persister;
