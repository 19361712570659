/**
 * @generated SignedSource<<02efd3958c9fd090c94517f0253e465a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserColorScheme = "BLACK" | "BLUE" | "GREEN" | "WINE";
export type UserLanguage = "CS" | "SK" | "DE" | "EN";
export type User_userQuery$variables = {
  id: string;
};
export type User_userQuery$data = {
  readonly user: {
    readonly id: string;
    readonly email: string;
    readonly fullName: string | null;
    readonly colorScheme: UserColorScheme;
    readonly language: UserLanguage;
    readonly phoneNumber: string | null;
    readonly overview: string | null;
    readonly showPhoneNumber: boolean;
    readonly image: string | null;
  } | null;
};
export type User_userQuery = {
  variables: User_userQuery$variables;
  response: User_userQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "UserType",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "colorScheme",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "overview",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "showPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_userQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_userQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8410c055da5441df87a7f583b9938319",
    "id": null,
    "metadata": {},
    "name": "User_userQuery",
    "operationKind": "query",
    "text": "query User_userQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    id\n    email\n    fullName\n    colorScheme\n    language\n    phoneNumber\n    overview\n    showPhoneNumber\n    image\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ce24e730dcc45fe7e9eb9d142922b93";

export default node;
