import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Dimensions, LayoutChangeEvent, Platform } from "react-native";
import s from "styled-components/native";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import { useRecoilValue } from "recoil";

import { themeColors } from "../atoms/persisted/theme";
import { DEFAULT_RADIUS } from "../atoms/filter";

import { Text, View } from "./Themed";

const Wrapper = s(View)`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const Dragger = s(View)`
  position: relative;
  top: 2px;
  height: 15px;
  width: 15px;
  border-radius: 50px;
  margin: 20px;
`;

const Label = s(Text)`
  margin: 5px;
  width: 15%;
  text-align: center;
  font-family: regular;
  font-size: 13px;
`;

type Props = {
  values: number;
  setValues: Dispatch<SetStateAction<number>>;
  unit: string;
};

const Slider = (props: Props) => {
  const theme = useRecoilValue(themeColors);
  const sliderValuesChange = (values: [number, number]) =>
    props.setValues(values[1]);

  const [width, setWidth] = useState<number | null>(
    Platform.OS === "web" ? null : Dimensions.get("window").width * 0.6
  );

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    if (Platform.OS !== "web") return;

    const { width: viewWidth } = event.nativeEvent.layout;
    setWidth(viewWidth * 0.5);
  }, []);

  // TODO: refactor (no time for this for now...)
  return (
    <Wrapper onLayout={onLayout}>
      <Label>{`${0} ${props.unit}`}</Label>
      <MultiSlider
        selectedStyle={{
          backgroundColor: theme.secondary,
        }}
        unselectedStyle={{
          backgroundColor: theme.secondarySurface,
        }}
        trackStyle={{ height: 5 }}
        values={[0, props.values]}
        sliderLength={width}
        onValuesChange={sliderValuesChange}
        containerStyle={{ marginLeft: 8, marginRight: 8, width: "100%" }}
        min={1}
        max={DEFAULT_RADIUS}
        enabledOne={false}
        isMarkersSeparated
        step={1}
        customMarkerRight={() => <Dragger background={theme.secondary} />}
        customMarkerLeft={() => null}
      />
      <Label>{`${props.values} ${props.unit}`}</Label>
    </Wrapper>
  );
};

export default Slider;
