import React, { ReactElement } from "react";
import { Platform, View } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import s from "styled-components/native";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { useRecoilState, useRecoilValue } from "recoil";

import { themeColors } from "../atoms/persisted/theme";
import userAtom from "../atoms/persisted/user";

import NotFoundScreen from "../screens/NotFoundScreen";
import Home from "../screens/home";
import Filter from "../screens/home/Filter";
import Map from "../screens/home/Maps";
import Profile from "../screens/profile";
import Ads from "../screens/profile/Ads";
import EditProfile from "../screens/profile/EditProfile";
import Settings from "../screens/profile/Settings";
import AddAd from "../screens/ad/AddAd";
import EditAd from "../screens/ad/EditAd";
import User from "../screens/ad/User";
import Auth from "../screens/auth";
import Register from "../screens/auth/Register";
import Login from "../screens/auth/Login";
import ForgotPassword from "../screens/auth/ForgotPassword";
import Detail from "../screens/ad/DetailAd";
import EditPassword from "../screens/profile/EditPassword";
import DeleteUser from "../screens/profile/DeleteUser";
import WebView from "../screens/common/WebView";

import HomeIcon from "../../assets/icons/tab/home.svg";
import AddIcon from "../../assets/icons/tab/add.svg";
import UserIcon from "../../assets/icons/tab/user.svg";

import { RootStackParamList, RootTabParamList } from "../@types/navigation";
import useAuthUser from "../hooks/useAuthUser";
import useUserServices from "../hooks/useUserServices";
import { navigationRef } from "./utils";

const TabBarSelected = s(View)<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  background-color:  ${(props) => props.backgroundColor};
  width: 57px;
  height: 4px;
  borderBottomLeftRadius: 20px;
  borderBottomRightRadius: 20px;
`;

const AddTab = s(View)`
  border-radius: 50px;
`;

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              Home: "home",
            },
          },
          Add: {
            screens: {
              Add: "add",
            },
          },
          Profile: {
            screens: {
              Profile: "profile",
            },
          },
        },
      },
      Auth: "auth",
      Login: "login",
      Register: "register",
      ForgotPassword: "forgotPassword",
      Filter: "filter",
      Map: "map",
      EditProfile: "editProfile",
      Settings: "settings",
      NotFound: "*",
      DetailAd: "detail",
      User: "user",
      Ads: "ads",
      AddAd: "addAd",
      EditAd: "editAd",
      DeleteUser: "deleteUser",
      WebView: "webView",
    },
  },
};

const BottomTab = createBottomTabNavigator<RootTabParamList>();

const BottomTabNavigator = () => {
  const theme = useRecoilValue(themeColors);
  const user = useRecoilValue(userAtom);
  const { refetchUser } = useAuthUser();
  const { refetch: refetchServices } = useUserServices();

  const TabBarIcon = (props: { icon: ReactElement; focused: boolean }) => (
    <>
      {props.focused ? (
        <TabBarSelected backgroundColor={theme.secondary} />
      ) : null}
      {props.icon}
    </>
  );

  const focusedIcon = (focus: boolean): string =>
    focus ? theme.border : theme.secondarySurface;

  const focus = () => {
    void refetchUser();
    void refetchServices();
  };

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarHideOnKeyboard: true,
        headerShown: false,
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: theme.primarySurface,
          paddingBottom: 0,
          borderTopColor: theme.primarySurface,
          paddingHorizontal: Platform.OS === "web" ? "10%" : 0,
        },
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={Home}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              icon={<HomeIcon fill={focusedIcon(focused)} />}
              focused={focused}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Add"
        component={user ? AddAd : Login}
        options={{
          tabBarIcon: ({ focused }) => (
            <AddTab>
              <AddIcon fill={focused ? theme.secondaryDark : theme.secondary} />
            </AddTab>
          ),
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={user ? Profile : Login}
        listeners={{
          focus,
        }}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              icon={<UserIcon fill={focusedIcon(focused)} />}
              focused={focused}
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const Navigation = () => {
  const [user] = useRecoilState(userAtom);
  const { checkUser } = useAuthUser();

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <Stack.Navigator
        initialRouteName="Auth"
        screenOptions={() => ({
          headerShown: false,
        })}
        screenListeners={{
          state: checkUser,
        }}
      >
        <Stack.Screen name="Root" component={BottomTabNavigator} />
        <Stack.Screen name="Auth" component={Auth} />
        <Stack.Screen name="Filter" component={Filter} />
        <Stack.Screen name="Map" component={Map} />
        <Stack.Screen name="AddAd" component={user ? AddAd : Login} />
        <Stack.Screen name="Ads" component={Ads} />
        <Stack.Screen
          name="EditProfile"
          component={user ? EditProfile : Login}
        />
        <Stack.Screen name="Settings" component={user ? Settings : Login} />
        <Stack.Screen name="EditAd" component={user ? EditAd : Login} />
        <Stack.Screen name="Register" component={Register} />
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
        <Stack.Screen name="DetailAd" component={Detail} />
        <Stack.Screen name="User" component={User} />
        <Stack.Screen name="EditPassword" component={EditPassword} />
        <Stack.Screen name="DeleteUser" component={DeleteUser} />
        <Stack.Screen name="NotFound" component={NotFoundScreen} />
        <Stack.Screen name="WebView" component={WebView} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
