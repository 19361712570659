import montserratLight from "../../assets/fonts/Montserrat-Light.otf";
import montserratRegular from "../../assets/fonts/Montserrat-Regular.otf";
import montserratBold from "../../assets/fonts/Montserrat-Bold.otf";

const customFonts = {
  light: montserratLight,
  regular: montserratRegular,
  bold: montserratBold,
};

export default customFonts;
