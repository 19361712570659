import React, { Dispatch, SetStateAction, useRef } from "react";
import {
  Modal as MobileModal,
  TextInput,
  KeyboardType,
  KeyboardAvoidingView,
  Switch,
  Platform,
} from "react-native";
import s from "styled-components/native";
import WebModal from "modal-react-native-web";
import { useRecoilValue } from "recoil";

import TextInputForm from "./form/TextInput";
import Button from "./Button";
import { EmptySpace } from "./wrappers";
import { View, TouchableOpacity } from "./Themed";
import Text from "./Text";
import Icon from "./Icon";

import { radiusSizes } from "../constants/Sizes";

import { themeColors } from "../atoms/persisted/theme";

const ModalWrapper = s(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ModalView = s(View)`
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.25);
  width: ${Platform.OS === "web" ? "420px" : "320px"};
`;

const ContentModalView = s(View)`
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const CloseButton = s(TouchableOpacity)`
  align-self: flex-end;
  margin-right: 10px;
  margin-top: 10px;
`;

const WrapperButtons = s(View)`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-bottom: 10px;
  justify-content: center;
`;

const ModalButton = s(Button)`
  min-width: 35%;
  margin: 0 5px;
`;

const KeyboardView = s(KeyboardAvoidingView)`
  width: 100%;
  align-items: center;
  padding-bottom: 50px;
`;

const SwitchWrapper = s(View)`
  flex-direction: row;
  width 100%;
`;

const SwitchItem = s(View)<{ alignItems: string }>`
  flex: 1;
  align-items: ${(props) => props.alignItems};
  padding: 0 5px;
`;

type Props = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  titleIcon?: () => JSX.Element;
  title: string;
  description?: string;
  ok?: string;
  nok?: string;
  okAction?: () => void;
  nokAction?: () => void;
  closeAction?: () => void;
  input?: {
    inputValue: string;
    setInput: Dispatch<SetStateAction<string>>;
    inputLabel: string;
    inputError: string;
    keyboardType: KeyboardType;
    focus?: boolean;
  };
  switch?: {
    switchValue: boolean;
    setSwitch: Dispatch<SetStateAction<boolean>>;
    okText: string;
    nokText: string;
  };
  onCloseOkAction?: boolean;
  body?: JSX.Element;
};

const ModalComponent = (props: Props) => {
  const inputRef = useRef<TextInput>(null);

  const theme = useRecoilValue(themeColors);

  const toggleSwitch = (setState: Dispatch<SetStateAction<boolean>>) => {
    setState((previousState) => !previousState);
  };

  const renderContent = () => (
    <ModalWrapper background={theme.darkOpacity}>
      <KeyboardView behavior="position">
        <ModalView background={theme.primarySurface}>
          <CloseButton
            onPress={() => {
              if (props.closeAction) {
                props.closeAction();
              }
              props.setVisible(!props.visible);
              props.onCloseOkAction && props.okAction();
            }}
          >
            <Icon type="ionicons" name="close" size={24} color={theme.black} />
          </CloseButton>
          <ContentModalView>
            {props.titleIcon?.()}
            <Text size="24px" lineHeight="24px" font="regular">
              {props.title}
            </Text>
            <EmptySpace height={15} />
            {props.description && (
              <Text size="14px" font="regular">
                {props.description}
              </Text>
            )}
            {props.body}
            <EmptySpace height={15} />
            {props.switch && (
              <SwitchWrapper>
                <SwitchItem alignItems="flex-end">
                  <Text size="14px" font="regular">
                    {props.switch.switchValue
                      ? props.switch.okText
                      : props.switch.nokText}
                  </Text>
                </SwitchItem>
                <SwitchItem alignItems="flex-start">
                  <Switch
                    onValueChange={() => {
                      if (props.switch?.switchValue !== undefined) {
                        toggleSwitch(props.switch.setSwitch);
                      }
                    }}
                    value={props.switch.switchValue}
                  />
                </SwitchItem>
                <EmptySpace height={20} />
              </SwitchWrapper>
            )}
            {props.input && (
              <>
                <TextInputForm
                  ref={inputRef}
                  value={props.input.inputValue}
                  onChange={(text) => props.input?.setInput(text)}
                  placeholder={props.input.inputLabel}
                  returnKeyType="send"
                  error={props.input.inputError}
                  onSubmitEditing={() => props.okAction()}
                  keyboardType={props.input.keyboardType}
                />
                <EmptySpace height={20} />
              </>
            )}
            <WrapperButtons>
              {props.ok && (
                <ModalButton
                  width="150px"
                  justifyContent="center"
                  borderRadius={radiusSizes.smallRadius}
                  background={theme.secondary}
                  onPress={() => {
                    props.okAction();
                  }}
                >
                  <Text
                    size="15px"
                    font="regular"
                    textColor={theme.secondaryText}
                  >
                    {props.ok}
                  </Text>
                </ModalButton>
              )}
              {props.nok && (
                <ModalButton
                  width="150px"
                  justifyContent="center"
                  borderColor={theme.text}
                  background={theme.white}
                  borderRadius={radiusSizes.smallRadius}
                  onPress={() => {
                    if (props.nokAction) {
                      props.nokAction();
                    }
                    props.setVisible(!props.visible);
                  }}
                >
                  <Text size="15px" font="regular" textColor={theme.text}>
                    {props.nok}
                  </Text>
                </ModalButton>
              )}
            </WrapperButtons>
          </ContentModalView>
        </ModalView>
      </KeyboardView>
    </ModalWrapper>
  );

  if (props.visible && Platform.OS === "web") {
    return (
      <WebModal
        appElement={document.getElementById("root")}
        ariaHideApp={false}
        animationType="fade"
        transparent
        visible={props.visible}
        onShow={() => {
          if (props.input?.focus) {
            inputRef.current?.focus();
          }
        }}
      >
        {renderContent()}
      </WebModal>
    );
  }

  return props.visible ? (
    <MobileModal
      animationType="fade"
      transparent
      visible={props.visible}
      onShow={() => {
        if (props.input?.focus) {
          inputRef.current?.focus();
        }
      }}
    >
      {renderContent()}
    </MobileModal>
  ) : null;
};

export default ModalComponent;
