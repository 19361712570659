import React, { FC } from "react";
import { WebView } from "react-native-webview";
import { useNavigation } from "@react-navigation/native";

import Header from "../../components/Header";
import { BodyContentWrapper } from "../../components/wrappers";

type Props = {
  url: string;
  callbackUrl?: string;
  title?: string;
  callback?: () => void;
};

const WebViewComponent: FC<Props> = (props: Props) => {
  const navigation = useNavigation();
  return (
    <Header
      centerTitle={props.title}
      leftAction={() => {
        if (navigation.canGoBack()) {
          navigation.goBack();
        }
      }}
      scrollingBody
      bodyOverflow="visible"
      body={
        <BodyContentWrapper>
          <WebView
            onNavigationStateChange={(navState) =>
              navState.url === props.callbackUrl && props.callback?.()
            }
            source={{
              uri: props.url,
            }}
            startInLoadingState
          />
        </BodyContentWrapper>
      }
    />
  );
};

export default WebViewComponent;
