import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "react-native";
import { useRecoilValue } from "recoil";
import s from "styled-components/native";

import { Tag, Button, Location, Row } from "../Header";
import Text from "../Text";
import { TouchableOpacity } from "../Themed";
import Icon from "../Icon";
import { themeColors } from "../../atoms/persisted/theme";
import type { SectionListDataType } from ".";
import { cutString } from "../../services/text";

const Wrapper = s(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  padding: 17px;
  margin: 7px 0;
  border-radius: 24px;
  overflow: hidden;
`;

const ItemImage = s(Image)`
  border-radius: 10px;
  height: 35px;
  width: 35px;
  resize-mode: cover;
`;

export type SectionItemProps = {
  item: SectionListDataType;
  onPress?: (item: SectionListDataType) => void;
  onButtonPress?: (item: SectionListDataType) => void;
};

const SectionItem = ({
  item,
  onPress,
  onButtonPress: __,
}: SectionItemProps) => {
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();

  const categoriesText = useMemo(() => {
    if (!item.categories?.edges) return "";
    const names = item.categories.edges.map(({ node }) => node.title);
    return names.join(", ");
  }, [item.categories.edges]);

  const isProviding = useMemo(() => item.option === "PROVIDE", [item.option]);

  return (
    <Wrapper background={theme.primarySurface} onPress={() => onPress?.(item)}>
      <Row padding={10}>
        {item?.user?.image ? (
          <ItemImage source={{ uri: item?.user.image }} />
        ) : (
          <Icon type="ionicons" name="person" size={80} color={theme.primary} />
        )}
        <Text size="17px" flex="3" textAlign="left" margin="0 15px">
          {cutString(item.title, 64)}
        </Text>
        <Button background={theme.secondaryOpacity}>
          <Icon
            type="simpleLineIcons"
            name="arrow-right"
            size={16}
            color={theme.secondary}
          />
        </Button>
      </Row>
      <Row padding={10}>
        <Tag
          background={isProviding ? theme.successSurface : theme.primaryOpacity}
          marginLeft={30}
        >
          <Text
            textColor={isProviding ? theme.successText : theme.primary}
            size="10px"
          >
            {isProviding ? t("filter.sell") : t("filter.offer")}
          </Text>
        </Tag>
        <Text
          font="bold"
          textColor={theme.highlightText}
          size="13px"
          padding="0 0 0 10px"
          textAlign="left"
          style={{ maxWidth: "50%" }}
        >
          {categoriesText}
        </Text>
      </Row>
      <Row padding={10}>
        <Text
          font="bold"
          textColor={theme.secondary}
          size="15px"
          margin="0 0 0 30px"
          textAlign="left"
          style={{ maxWidth: "50%" }}
          flex="1"
        >
          {item.price && Number(item.price).toFixed(0)}
          {!item.price &&
            item.reward &&
            t(
              `filter.${
                item.reward.toLowerCase() as "free" | "price" | "exchange"
              }`
            )}
        </Text>
        <Location>
          <Icon
            type="materialIcons"
            name="location-pin"
            size={13}
            color={theme.border}
          />
          <Text
            textColor={theme.border}
            size="12px"
            textAlign="left"
            margin="0 0 0 5px"
          >
            {item.coordinatesName}
          </Text>
        </Location>
      </Row>
    </Wrapper>
  );
};

export default SectionItem;
