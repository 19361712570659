import React, { useState, forwardRef } from "react";
import s from "styled-components/native";
import { TextInput, KeyboardType, ReturnKeyType, Platform } from "react-native";
import { useRecoilValue } from "recoil";

import { TouchableOpacity, View } from "../Themed";
import ElementWrapper from "../ElementWrapper";
import Text from "../Text";
import Icon from "../Icon";

import { themeColors } from "../../atoms/persisted/theme";

import { iconSizes } from "../../constants/Sizes";

import Eye from "../../../assets/icons/input/eye.svg";
import EyeSplash from "../../../assets/icons/input/eyeSlash.svg";

export const Input = s(TextInput)<{
  color: string;
  size?: number;
  height?: number;
  autoComplete?: string;
  width?: string;
}>`
  padding-horizontal: 15px;
  padding-vertical: 5px;
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: ${(props) => (props.size ? props.size : 13)}px;
  color: ${(props) => props.color};
  height: ${(props) => props.height ?? 25}px;
  ${Platform.OS === "web" ? "outline-style: none;" : null}
`;

const InputButton = s(TouchableOpacity)`
  padding-right: 5px;
  flex: 1;
`;

const InputIcon = s(View)`
  width: 20px;
  margin-left: 20px;
`;

type Props = {
  value: string;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmitEditing?: () => void;
  size?: number;
  height?: number;
  color?: string;
  focusBorderColor?: string;
  unFocusBorderColor?: string;
  label?: string;
  labelColor?: string;
  placeholder?: string;
  keyboardType?: KeyboardType;
  returnKeyType?: ReturnKeyType | "return";
  error?: string;
  secureTextEntry?: boolean;
  iconType?: string;
  iconName?: string;
  textarea?: boolean;
};

const FormTextInput = forwardRef<TextInput, Props>(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      onSubmitEditing,
      size,
      height,
      color,
      focusBorderColor,
      unFocusBorderColor,
      label,
      labelColor,
      placeholder,
      keyboardType,
      returnKeyType,
      error,
      secureTextEntry,
      iconType,
      iconName,
      textarea,
    },
    ref
  ) => {
    const theme = useRecoilValue(themeColors);
    const [focus, setFocus] = useState(false);
    const [visibility, setVisibility] = useState(false);

    return (
      <>
        <ElementWrapper
          title={label}
          titleColor={labelColor}
          border={`1px solid ${
            focus
              ? focusBorderColor ?? theme.border
              : unFocusBorderColor ?? theme.transparent
          }`}
          height={height}
        >
          {iconType && iconName && (
            <InputIcon>
              <Icon
                type={iconType}
                name={iconName}
                size={16}
                color={focus ? theme.primary : theme.input.border}
              />
            </InputIcon>
          )}
          <Input
            ref={ref}
            style={{ flex: 9 }}
            color={color ?? theme.text}
            value={value}
            size={size}
            onChangeText={(text) => onChange(text)}
            placeholder={placeholder}
            placeholderTextColor={theme.input.placeholder}
            secureTextEntry={!secureTextEntry ? false : !visibility}
            onSubmitEditing={onSubmitEditing ?? undefined}
            onFocus={() => {
              setFocus(true);
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={() => {
              setFocus(false);
              if (onBlur) {
                onBlur();
              }
            }}
            keyboardType={keyboardType ?? "default"}
            returnKeyType={(returnKeyType as ReturnKeyType) ?? "next"}
            multiline={textarea ?? false}
            height={textarea && height}
            autoComplete={Platform.OS === "web" ? "password-new" : "off"}
          />
          {secureTextEntry && (
            <InputButton onPress={() => setVisibility(!visibility)}>
              {visibility ? (
                <Eye height={iconSizes.smallSize} width={iconSizes.smallSize} />
              ) : (
                <EyeSplash
                  height={iconSizes.smallSize}
                  width={iconSizes.smallSize}
                />
              )}
            </InputButton>
          )}
        </ElementWrapper>
        <Text
          size="12px"
          textColor={theme.error}
          display={!!error}
          textAlign="left"
          width="100%"
        >
          {error ?? ""}
        </Text>
      </>
    );
  }
);

export default FormTextInput;
