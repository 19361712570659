import { useRecoilState } from "recoil";
import { useCallback, useState } from "react";
import { fetchQuery } from "react-relay";
import { useResettableRelayContext } from "../relay/ResettableRelayProvider";
import { getAllUserServices, UserService } from "../relay/queries/Services";
import { Services_allUserServicesQuery } from "../__generated__/Services_allUserServicesQuery.graphql";
import {
  activeServicesAtom,
  inactiveServicesAtom,
} from "../screens/profile/states";
import { getStorageValue } from "../services/storage";
import { ACCESS_TOKEN_KEY } from "../atoms/accessToken";

type UserServicesProps =
  | {
      active?: boolean;
    }
  | undefined;

const useServices = (active?: boolean) => {
  const [inactiveServices, setInactiveServices] =
    useRecoilState(inactiveServicesAtom);
  const [activeServices, setActiveServices] =
    useRecoilState(activeServicesAtom);

  const set = useCallback(
    (services: UserService[]) => {
      if (typeof active !== "boolean") {
        setInactiveServices(services.filter((item) => !item.isActive));
        setActiveServices(services.filter((item) => item.isActive));
        return;
      }

      if (active) setActiveServices(services);
      else setInactiveServices(services);
    },
    [active, setActiveServices, setInactiveServices]
  );

  const get = useCallback(() => {
    if (typeof active !== "boolean") return null;
    if (active) return activeServices;
    return inactiveServices;
  }, [active, activeServices, inactiveServices]);

  const transferService = ({ id, to }: { id: string; to: boolean }) => {
    if (to) {
      const service = inactiveServices.find((item) => item.id === id);
      if (!service) return;

      setActiveServices((prev) => [...prev, { ...service, isActive: true }]);
      setInactiveServices((prev) => prev.filter((item) => item.id !== id));
    } else {
      const service = activeServices.find((item) => item.id === id);
      if (!service) return;

      setInactiveServices((prev) => [...prev, { ...service, isActive: true }]);
      setActiveServices((prev) => prev.filter((item) => item.id !== id));
    }
  };

  return [get(), set, transferService] as const;
};

const useUserServices = (props: UserServicesProps = {}) => {
  const [loading, setLoading] = useState(false);
  const { environment } = useResettableRelayContext();

  const [services, set, transfer] = useServices(props.active);

  const getServices = useCallback(async () => {
    const access = await getStorageValue(ACCESS_TOKEN_KEY);
    if (!access) return null;

    const { allUserServices } = await fetchQuery<Services_allUserServicesQuery>(
      environment,
      getAllUserServices,
      { isActive: props.active }
    ).toPromise();

    if (!allUserServices) return null;
    return allUserServices;
  }, [environment, props.active]);

  const refetchServices = useCallback(async () => {
    setLoading(true);
    const data = await getServices();
    if (data) set(data.edges.map(({ node }) => node));
    setLoading(false);
  }, [getServices, set]);

  return {
    data: services,
    isLoading: loading,
    refetch: refetchServices,
    transferService: transfer,
  } as const;
};

export default useUserServices;
