import * as Location from "expo-location";
import { LatLng } from "react-native-maps";

type GetAddressProps = { coords: LatLng; skipCheck?: boolean };

const getAddressFromCoords = async ({ coords, skipCheck }: GetAddressProps) => {
  if (!skipCheck) {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      return null;
    }
  }

  const [locationAddress] = await Location.reverseGeocodeAsync(coords, {
    useGoogleMaps: true,
  });

  const city = locationAddress.city === undefined ? "" : locationAddress.city;
  const district = locationAddress.district
    ? `${city && " - "}${locationAddress.district}`
    : "";

  return `${locationAddress.name}, ${city}${district}`;
};

export default getAddressFromCoords;
