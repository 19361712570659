/**
 * @generated SignedSource<<e2ee590bbb59b45c540c327d2cd3e02e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceCurrency = "CZK" | "EUR";
export type ServiceOption = "PROVIDE" | "REQUEST";
export type ServiceReward = "FREE" | "PRICE" | "EXCHANGE";
export type ServiceStatus = "PENDING" | "ACTIVE" | "REJECTED" | "PENDING_REVIEW" | "RESOLVED_REPORT";
export type Services_allUserServicesQuery$variables = {
  isActive?: boolean | null;
};
export type Services_allUserServicesQuery$data = {
  readonly allUserServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly categories: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly title: string;
            } | null;
          } | null>;
        };
        readonly price: any | null;
        readonly description: string | null;
        readonly title: string;
        readonly reward: ServiceReward | null;
        readonly radius: number;
        readonly coordinatesName: string | null;
        readonly isActive: boolean;
        readonly option: ServiceOption;
        readonly status: ServiceStatus;
        readonly startDate: any | null;
        readonly endDate: any | null;
        readonly timeFrom: any | null;
        readonly user: {
          readonly image: string | null;
          readonly id: string;
        };
        readonly timeTo: any | null;
        readonly currency: ServiceCurrency | null;
        readonly days: ReadonlyArray<string | null>;
      } | null;
    } | null>;
  } | null;
};
export type Services_allUserServicesQuery = {
  variables: Services_allUserServicesQuery$variables;
  response: Services_allUserServicesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isActive"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isActive",
        "variableName": "isActive"
      }
    ],
    "concreteType": "ServiceTypeConnection",
    "kind": "LinkedField",
    "name": "allUserServices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceCategoryTypeConnection",
                "kind": "LinkedField",
                "name": "categories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceCategoryTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServiceCategoryType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "radius",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinatesName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "option",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "days",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_allUserServicesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Services_allUserServicesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c0be398d47181ca0e733d3068efc3230",
    "id": null,
    "metadata": {},
    "name": "Services_allUserServicesQuery",
    "operationKind": "query",
    "text": "query Services_allUserServicesQuery(\n  $isActive: Boolean\n) {\n  allUserServices(isActive: $isActive) {\n    edges {\n      node {\n        id\n        categories {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        price\n        description\n        title\n        reward\n        radius\n        coordinatesName\n        isActive\n        option\n        status\n        startDate\n        endDate\n        timeFrom\n        user {\n          image\n          id\n        }\n        timeTo\n        currency\n        days\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3213f82bbb524e588a5704f14e70b965";

export default node;
