import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components/native";
import { Platform, Image } from "react-native";

import { fetchQuery } from "relay-runtime";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "../../components/Themed";
import { BodyRow } from "../../components/Header";
import { filterAtomState } from "../../atoms/filter";
import { Tree } from "../../@types/tree";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import { getCategoriesQuery } from "../../relay/queries/Categories";
import { themeColors } from "../../atoms/persisted/theme";
import Icon from "../../components/Icon";

const AccordionBodyWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ItemWrapper = styled(TouchableOpacity)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: ${Platform.OS === "web" ? "30" : "45"}%;
  height: 250px;
  margin: ${Platform.OS === "web" ? "1.5" : "2.5"}%;

  border-radius: 15px;
`;

const IMAGE_SIZE = Platform.OS === "web" ? 90 : 70;

const ItemImage = styled(Image)`
  border-radius: 10px;
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
  resize-mode: cover;
`;

const SubText = styled(Text)`
  padding: 5px 15px 0px 15px;
  font-size: ${Platform.OS === "web" ? "18" : "12"}px;
  overflow: hidden;
  width: 85%;
  text-align: center;
  min-height: 33px;
`;

const Title = styled(TouchableOpacity)`
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-left: 15px;
  height: 40px;
`;

type CategoriesProps = {
  isExpanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

const Categories: FC<CategoriesProps> = ({ isExpanded, setExpanded }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useRecoilState(filterAtomState);
  const { environment } = useResettableRelayContext();
  const theme = useRecoilValue(themeColors);

  const toggleAccordion = () => setExpanded((prev) => !prev);

  const [categories, setCategories] = useState<Tree<string>>(null);

  const getCategories = useCallback(async () => {
    const { allServiceCategories: res } = await fetchQuery<{
      response: { allServiceCategories: Tree<string> };
      variables: Record<string, string>;
    }>(environment, getCategoriesQuery, {}).toPromise();

    if (!res) return null;

    return res;
  }, [environment]);

  const refetchCategories = useCallback(async () => {
    const cats = await getCategories();
    setCategories(cats);
  }, [getCategories]);

  const handleCategoryClick = (id: string) => {
    const childCategories = (categories?.edges ?? [])
      .filter((cat) => cat.node?.parent?.id === id)
      .map((cat) => cat.node.id);

    setFilter((prevState) => ({
      ...prevState,
      categories: [...(prevState.categories ?? []), id, ...childCategories],
    }));
  };

  useEffect(() => {
    if (isExpanded) void refetchCategories();
  }, [refetchCategories, isExpanded]);

  useEffect(() => void refetchCategories(), [refetchCategories]);

  const items = useMemo(
    () =>
      (categories?.edges ?? [])
        .filter((cat) => !cat.node.parent)
        .map((cat) => ({
          id: cat.node.id,
          title: cat.node.title,
          subs: cat.node.subcategories,
          image: cat.node.image,
        })),
    [categories?.edges]
  );

  if ((filter.categories ?? []).length >= 1) return null;

  return (
    <BodyRow
      justifyContent="flex-start"
      style={{ flexDirection: "column", marginTop: -15 }}
    >
      <Title
        onPress={toggleAccordion}
        background={isExpanded ? theme.secondary : theme.secondarySurface}
      >
        <Icon
          type="ionicons"
          name={isExpanded ? "folder-open-outline" : "folder-outline"}
          size={IMAGE_SIZE - 60}
          color={isExpanded ? theme.primaryText : theme.text}
        />
        <Text
          style={{
            marginLeft: 10,
            color: isExpanded ? theme.primaryText : theme.text,
          }}
        >
          {t("filter.adType")}
        </Text>
      </Title>
      {isExpanded && (
        <AccordionBodyWrapper style={{ paddingLeft: 0 }}>
          {items.map((cat) => (
            <ItemWrapper
              key={cat.id}
              onPress={() => handleCategoryClick(cat.id)}
            >
              {cat.image ? (
                <ItemImage source={{ uri: cat.image }} />
              ) : (
                <Icon
                  type="ionicons"
                  name="folder-outline"
                  size={IMAGE_SIZE - 20}
                  color={theme.primary}
                />
              )}
              <Text
                numberOfLines={2}
                style={{
                  fontSize: Platform.OS === "web" ? 28 : 18,
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                {cat.title}
              </Text>

              <SubText numberOfLines={2}>
                {cat.subs.edges.map((item) => item.node.title).join(", ")}
              </SubText>
            </ItemWrapper>
          ))}
        </AccordionBodyWrapper>
      )}
    </BodyRow>
  );
};

export default Categories;
