import React, { useState, useEffect, useCallback } from "react";
import { Platform } from "react-native";
import { fetchQuery } from "react-relay";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import s from "styled-components/native";
import * as Linking from "expo-linking";
import { themeColors } from "../../atoms/persisted/theme";

import Header, { ItemImage, BodyRow } from "../../components/Header";
import Button from "../../components/Button";
import Section from "../../components/section";
import Icon from "../../components/Icon";
import { View } from "../../components/Themed";
import { getNavigationBack } from "../../utils/navigation";
import ConfirmModal from "../../components/ConfirmModal";
import {
  BodyContentWrapper,
  EmptySpace,
  RowWrapper,
} from "../../components/wrappers";
import Text from "../../components/Text";

import { RootStackScreenProps } from "../../@types/navigation";

import { radiusSizes } from "../../constants/Sizes";

import getEnvVars from "../../environment";
import { getAllUserServices, UserService } from "../../relay/queries/Services";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import { getUser } from "../../relay/queries/User";
import { User_userQuery } from "../../__generated__/User_userQuery.graphql";
import { Services_allUserServicesQuery } from "../../__generated__/Services_allUserServicesQuery.graphql";

import useGoogleAds from "../../components/AdMob";

const { SCHEME } = getEnvVars();

const BodyItemLeft = s(View)`
  flex: 1;
`;

const BodyItemRight = s(View)`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

type UserType = User_userQuery["response"]["user"];
const User = ({ navigation, route }: RootStackScreenProps<"User">) => {
  const theme = useRecoilValue(themeColors);
  const [informationModal, setInformationModal] = useState<boolean>(false);
  const { t } = useTranslation();

  if (!route.params.id) throw new Error("invalid Id");

  const { id } = route.params;

  const { environment } = useResettableRelayContext();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<UserType>(null);
  const [services, setServices] = useState<UserService[]>([]);

  const showAd = useGoogleAds();

  const fetchUser = useCallback(async () => {
    const { user: fetched } = await fetchQuery<User_userQuery>(
      environment,
      getUser,
      { id }
    ).toPromise();
    if (!fetched) return null;

    return fetched;
  }, [environment, id]);

  const fetchServices = useCallback(async () => {
    const { allUserServices } = await fetchQuery<Services_allUserServicesQuery>(
      environment,
      getAllUserServices,
      { isActive: true }
    ).toPromise();

    if (!allUserServices) return null;
    return allUserServices;
  }, [environment]);

  const refetch = useCallback(async () => {
    setLoading(true);
    setUser(await fetchUser());
    const set = await fetchServices();
    setServices(set.edges.map(({ node }) => node));
    setLoading(false);
  }, [fetchServices, fetchUser]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  useEffect(() => {
    if (route.params?.id) {
      if (Platform.OS === "web") {
        try {
          window.location.href = `${SCHEME}user/?id=${route.params?.id}`;
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }, [route.params?.id]);

  return (
    <Header
      centerTitle={user?.fullName}
      leftAction={() => getNavigationBack(navigation)}
      loadingBody={loading}
      loadingHeader={loading}
      body={
        <BodyContentWrapper>
          <Section data={services} sectionTitle={t("profile.activeAds")} />
        </BodyContentWrapper>
      }
      bodyOverflow="visible"
      bodyHeader={
        <BodyRow height={50} marginBottom={20}>
          <BodyItemLeft>
            <ItemImage
              source={{
                uri: user?.image ?? "",
              }}
            />
          </BodyItemLeft>
          <BodyItemRight>
            <Button
              width="60px"
              height="60px"
              justifyContent="center"
              padding="0"
              borderRadius={radiusSizes.biggerRadius}
              background={theme.secondary}
              onPress={() => {
                setInformationModal(true);
                showAd();
              }}
            >
              <Icon
                type="materialIcons"
                name="email"
                size={20}
                color={theme.primaryText}
              />
            </Button>
          </BodyItemRight>
          <ConfirmModal
            visible={informationModal}
            setVisible={setInformationModal}
            title={t("detail.modal.information.title")}
            body={
              <RowWrapper>
                <Button
                  justifyContent="center"
                  borderRadius={radiusSizes.smallRadius}
                  background={theme.secondary}
                  onPress={() => Linking.openURL(`mailto:${user?.email ?? ""}`)}
                  width="40%"
                >
                  <Icon
                    type="materialIcons"
                    size={14}
                    name="email"
                    color={theme.white}
                  />
                  <Text
                    size="14px"
                    font="regular"
                    textColor={theme.white}
                    margin="0 5px"
                  >
                    {t("detail.modal.contact.email")}
                  </Text>
                </Button>
                {(user?.showPhoneNumber || !!user) && (
                  <>
                    <EmptySpace width={15} />
                    <Button
                      justifyContent="center"
                      borderRadius={radiusSizes.smallRadius}
                      background={theme.white}
                      onPress={() =>
                        Linking.openURL(`tel:${user?.phoneNumber}`)
                      }
                      borderColor={theme.text}
                      width="40%"
                    >
                      <Icon
                        type="fontAwesome"
                        size={14}
                        name="phone"
                        color={theme.text}
                      />
                      <Text
                        size="14px"
                        font="regular"
                        textColor={theme.text}
                        margin="0 5px"
                      >
                        {t("detail.modal.contact.phone")}
                      </Text>
                    </Button>
                  </>
                )}
              </RowWrapper>
            }
          />
        </BodyRow>
      }
    />
  );
};

export default User;
