import { useMutation, UseMutationConfig } from "react-relay";

import { useSetRecoilState } from "recoil";

import {
  createUserMutation,
  resetPasswordMutation,
  loginMutation,
  updateUserPasswordMutation,
  deleteUserMutation,
} from "../relay/queries/User";
import {
  User_tokenAuthMutation,
  User_tokenAuthMutation$data,
} from "../__generated__/User_tokenAuthMutation.graphql";
import { removeStorageValue, setStorageValue } from "./storage";
import accessTokenAtom, {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../atoms/accessToken";
import {
  User_createUserMutation,
  User_createUserMutation$data,
} from "../__generated__/User_createUserMutation.graphql";
import {
  CreateUserInput,
  ResetPasswordInput,
  UpdateUserPasswordInput,
  DeleteUserInput,
} from "../types/graphql";
import { User_resetPasswordMutation } from "../__generated__/User_resetPasswordMutation.graphql";
import { User_updateUserPasswordMutation } from "../__generated__/User_updateUserPasswordMutation.graphql";
import { User_deleteUserMutation } from "../__generated__/User_deleteUserMutation.graphql";

type AuthHookProps = {
  onError?: (e: Error) => void;
  onSuccess?: (res: User_tokenAuthMutation$data) => void;
};
export const useHandleLogin = ({ onError, onSuccess }: AuthHookProps) => {
  const [commit] = useMutation<User_tokenAuthMutation>(loginMutation);
  const setAccessChange = useSetRecoilState(accessTokenAtom);

  const login = (email: string, password: string) => {
    const mutationConfig: UseMutationConfig<User_tokenAuthMutation> = {
      variables: {
        email,
        password,
      },
      onCompleted: (res: User_tokenAuthMutation$data) => {
        if (res.tokenAuth) {
          void setStorageValue(REFRESH_TOKEN_KEY, res.tokenAuth.refreshToken);
          void setStorageValue(ACCESS_TOKEN_KEY, res.tokenAuth.token);
          setAccessChange(true);
        }
        onSuccess?.(res);
      },
      onError,
    };

    commit(mutationConfig);
  };

  return [login] as const;
};

type AuthRegisterProps = {
  onError?: (e: Error) => void;
  onCompleted?: (res: User_createUserMutation$data) => void;
};
export const useHandleRegister = ({
  onError,
  onCompleted,
}: AuthRegisterProps) => {
  const [commit] = useMutation<User_createUserMutation>(createUserMutation);

  const register = (input: CreateUserInput) => {
    const mutationConfig: UseMutationConfig<User_createUserMutation> = {
      variables: {
        input,
      },
      onError,
      onCompleted,
    };

    commit(mutationConfig);
  };

  return [register] as const;
};

export const useResetPassword = () => {
  const [commit] = useMutation<User_resetPasswordMutation>(
    resetPasswordMutation
  );

  const resetPassword = (input: ResetPasswordInput) => {
    const mutationConfig: UseMutationConfig<User_resetPasswordMutation> = {
      variables: {
        input,
      },
      onError: (e: Error): void => {
        console.warn("error", e);
        // throw new Error(e.message);
      },
    };

    commit(mutationConfig);
  };

  return [resetPassword] as const;
};

export const useEditPassword = () => {
  const [commit] = useMutation<User_updateUserPasswordMutation>(
    updateUserPasswordMutation
  );

  const editUserPassword = (
    input: UpdateUserPasswordInput,
    config: {
      onError: (e: Error) => void;
      onSuccess: () => void;
    }
  ) => {
    const mutationConfig: UseMutationConfig<User_updateUserPasswordMutation> = {
      variables: {
        input,
      },
      onError: config.onError,
      onCompleted: config.onSuccess,
    };

    commit(mutationConfig);
  };

  return [editUserPassword] as const;
};

export const useDeleteAccount = () => {
  const [commit] = useMutation<User_deleteUserMutation>(deleteUserMutation);

  const deleteUserPassword = (
    input: DeleteUserInput,
    config: {
      onError: (e: Error) => void;
      onSuccess: () => void;
    }
  ) => {
    const mutationConfig: UseMutationConfig<User_deleteUserMutation> = {
      variables: {
        input,
      },
      onError: config.onError,
      onCompleted: config.onSuccess,
    };

    commit(mutationConfig);
  };

  return [deleteUserPassword] as const;
};

export const handleLogout = async () => {
  await removeStorageValue(ACCESS_TOKEN_KEY);
  await removeStorageValue(REFRESH_TOKEN_KEY);
};
