import i18n, { ReadCallback } from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import * as Localization from "expo-localization";

import csLang from "./translations/cs.json";
import skLang from "./translations/sk.json";
import deLang from "./translations/de.json";
import enLang from "./translations/en.json";

const langs = {
  cs: csLang,
  sk: skLang,
  de: deLang,
  en: enLang,
};

const getLang = (
  language: string,
  namespace: string,
  callback: ReadCallback
) => {
  if (langs[language])
    callback(undefined, langs[language as keyof typeof langs]);
  else callback(undefined, langs.en);
};

void i18n
  .use(
    resourcesToBackend((...args) => {
      getLang(...args);
    })
  )
  .use(initReactI18next)
  .init({
    lng: Localization.locale.substring(0, 2),
    fallbackLng: "en",
    defaultNS: "common",

    // Remove `translation` ns
    ns: [],
    react: {
      // react-native cannot use i18n with suspense yet
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
