import { graphql } from "react-relay";
import { Services_allServicesQuery$data } from "../../__generated__/Services_allServicesQuery.graphql";
import { Services_allUserServicesQuery } from "../../__generated__/Services_allUserServicesQuery.graphql";
import { Services_serviceQuery$data } from "../../__generated__/Services_serviceQuery.graphql";

export type Service =
  Services_allServicesQuery$data["allServices"]["edges"][0]["node"];

export type ServiceDetail = Services_serviceQuery$data["service"];

export type UserService =
  Services_allUserServicesQuery["response"]["allUserServices"]["edges"][0]["node"];

const createServiceMutation = graphql`
  mutation Services_createServiceMutation($input: CreateServiceInput!) {
    createService(input: $input) {
      obj {
        id
      }
    }
  }
`;

const updateService = graphql`
  mutation Services_updateServiceMutation($input: UpdateServiceInput!) {
    updateService(input: $input) {
      obj {
        id
      }
    }
  }
`;

const getAllUserServices = graphql`
  query Services_allUserServicesQuery($isActive: Boolean) {
    allUserServices(isActive: $isActive) {
      edges {
        node {
          id
          categories {
            edges {
              node {
                id
                title
              }
            }
          }
          price
          description
          title
          reward
          radius
          coordinatesName
          isActive
          option
          status
          startDate
          endDate
          timeFrom
          user {
            image
            id
          }
          timeTo
          currency
          days
        }
      }
    }
  }
`;

const getServiceQuery = graphql`
  query Services_serviceQuery($id: ID!) {
    service(id: $id) {
      id
      user {
        id
        email
        fullName
        phoneNumber
        image
        showPhoneNumber
      }
      title
      description
      days
      categories {
        edges {
          node {
            title
            id
          }
        }
      }
      timeTo
      timeFrom
      startDate
      endDate
      radius
      reward
      price
      currency
      coordinatesName
      coordinates
      option
      isActive
      distance
      status
    }
  }
`;

const getAllServicesQuery = graphql`
  query Services_allServicesQuery(
    $radius: Int!
    $coordinates: Point!
    $option: ServiceOptionChoices!
    $categories: [PrimaryKey]
    $rewards: [ServiceRewardChoices]
    $days: [DayOfTheWeekChoices]
    $priceFrom: Int
    $priceTo: Int
    $currency: CurrencyChoices
    $startDate: Date
    $endDate: Date
    $timeFrom: Time
    $timeTo: Time
  ) {
    allServices(
      radius: $radius
      coordinates: $coordinates
      option: $option
      categories: $categories
      rewards: $rewards
      days: $days
      priceFrom: $priceFrom
      priceTo: $priceTo
      currency: $currency
      startDate: $startDate
      endDate: $endDate
      timeFrom: $timeFrom
      timeTo: $timeTo
    ) {
      edges {
        node {
          id
          price
          days
          user {
            image
            id
          }
          description
          title
          coordinatesName
          categories {
            edges {
              node {
                id
                title
              }
            }
          }
          currency
          option
          status
          reward
        }
      }
    }
  }
`;

export {
  createServiceMutation,
  getAllServicesQuery,
  getServiceQuery,
  getAllUserServices,
  updateService,
};
