import { atom } from "recoil";
import { UserLanguage } from "../../types/graphql";
import persistAtom from "./utils";

const atomKey = "langAtom";
const langAtom = atom<UserLanguage | null>({
  key: atomKey,
  default: null,
  effects_UNSTABLE: [persistAtom(atomKey)],
});

export default langAtom;
