import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import s from "styled-components/native";

import userAtom from "../../atoms/persisted/user";
import Header, { ItemImage, BodyRow } from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Section from "../../components/section";
import Icon from "../../components/Icon";
import { View } from "../../components/Themed";
import TightSectionItem from "../../components/section/TightSectionItem";
import ConfirmModal from "../../components/ConfirmModal";
import { BodyContentWrapper } from "../../components/wrappers";

import { RootTabScreenProps } from "../../@types/navigation";
import { radiusSizes } from "../../constants/Sizes";

import { themeColors } from "../../atoms/persisted/theme";
import refetchKeyAtom from "../../atoms/refetchKey";

import { handleLogout } from "../../services/auth";
import useUserServices from "../../hooks/useUserServices";

const BodyItemLeft = s(View)`
  flex: 1;
`;

const ButtonItem = s(View)<{ borderRadius?: number }>`
  flex: 1;
  margin: 5px 5px;
  ${radiusSizes.basicRadius && `border-radius: ${radiusSizes.basicRadius}px`}
`;

const BodyItemRight = s(View)`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

const Profile = ({ navigation }: RootTabScreenProps<"Profile">) => {
  const [user, setUser] = useRecoilState(userAtom);
  const theme = useRecoilValue(themeColors);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const refetchKey = useRecoilValue(refetchKeyAtom);

  const {
    data: services,
    isLoading: loading,
    refetch,
  } = useUserServices({
    active: true,
  });

  useEffect(() => {
    void refetch();
  }, [refetch, refetchKey]);

  const shadowsStyles = StyleSheet.create({
    shadowContainer: {
      shadowColor: theme.primary,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.1,
      shadowRadius: 5,
    },
  });

  return (
    <Header
      centerTitle={user.fullName}
      loadingBody={loading}
      body={
        <BodyContentWrapper>
          <Section
            data={services}
            sectionItem={TightSectionItem}
            onButtonPress={({ id }) => navigation.navigate("EditAd", { id })}
            onPress={({ id }) => navigation.navigate("DetailAd", { id })}
            sectionTitle={t("profile.activeAds")}
            sectionHeaderAction={() => navigation.navigate("AddAd")}
          />
          <ConfirmModal
            visible={modalVisible}
            setVisible={setModalVisible}
            title={t("profile.signout.header")}
            description={t("profile.signout.description")}
            ok={t("general.yes")}
            okAction={() => {
              void handleLogout();
              setUser(null);
              setModalVisible(false);
            }}
            nok={t("general.no")}
            nokAction={() => setModalVisible(false)}
          />
        </BodyContentWrapper>
      }
      bodyOverflow="visible"
      bodyHeader={
        <>
          <BodyRow height={50} marginBottom={20}>
            <BodyItemLeft>
              <ItemImage
                source={{
                  uri: user.image,
                }}
              />
            </BodyItemLeft>
            <BodyItemRight>
              <Button
                width="auto"
                padding="7px 10px"
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={theme.secondarySurface}
                onPress={() => setModalVisible(true)}
              >
                <Icon
                  type="fontAwesome"
                  name="power-off"
                  size={13}
                  color={theme.text}
                />
                <Text
                  size="13px"
                  font="regular"
                  textColor={theme.text}
                  padding="0 0 0 10px"
                >
                  {t("profile.logout")}
                </Text>
              </Button>
            </BodyItemRight>
          </BodyRow>
          <BodyRow>
            <ButtonItem
              style={shadowsStyles.shadowContainer}
              background={theme.primarySurface}
            >
              <Button
                padding="25px 0"
                flexDirection="column"
                justifyContent="center"
                borderRadius={radiusSizes.basicRadius}
                background={theme.primarySurface}
                onPress={() => navigation.navigate("Ads")}
              >
                <Icon
                  type="entypo"
                  name="text-document-inverted"
                  size={30}
                  color={theme.primary}
                />
                <Text
                  size="13px"
                  padding="10px 0 0 0"
                  font="regular"
                  textColor={theme.text}
                >
                  {t("profile.inActiveAds")}
                </Text>
              </Button>
            </ButtonItem>
            <ButtonItem
              style={shadowsStyles.shadowContainer}
              background={theme.primarySurface}
            >
              <Button
                padding="25px 0"
                flexDirection="column"
                justifyContent="center"
                borderRadius={radiusSizes.basicRadius}
                background={theme.primarySurface}
                onPress={() => navigation.navigate("EditProfile")}
              >
                <Icon
                  type="ionicons"
                  name="person"
                  size={30}
                  color={theme.primary}
                />
                <Text
                  size="13px"
                  padding="10px 0 0 0"
                  font="regular"
                  textColor={theme.text}
                >
                  {t("profile.editProfile")}
                </Text>
              </Button>
            </ButtonItem>
            <ButtonItem
              style={shadowsStyles.shadowContainer}
              background={theme.primarySurface}
            >
              <Button
                padding="25px 0"
                flexDirection="column"
                justifyContent="center"
                borderRadius={radiusSizes.basicRadius}
                background={theme.primarySurface}
                onPress={() => navigation.navigate("Settings")}
              >
                <Icon
                  type="ionicons"
                  name="settings-sharp"
                  size={30}
                  color={theme.primary}
                />
                <Text
                  size="13px"
                  padding="10px 0 0 0"
                  font="regular"
                  textColor={theme.text}
                >
                  {t("profile.settings")}
                </Text>
              </Button>
            </ButtonItem>
          </BodyRow>
        </>
      }
    />
  );
};

export default Profile;
