import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Keyboard } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { themeColors } from "../../atoms/persisted/theme";

import LoadingComponent from "../../components/Loading";
import {
  EmptySpace,
  CenterWrapper,
  RowWrapper,
  BodyContentWrapper,
} from "../../components/wrappers";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Text from "../../components/Text";

import { radiusSizes } from "../../constants/Sizes";

import { getNavigationBack } from "../../utils/navigation";

import { RootStackScreenProps } from "../../@types/navigation";
import { useResetPassword } from "../../services/auth";
import FormInput from "../../components/form";

type FormValues = {
  email: string;
};

const ForgotPassword = ({
  navigation,
}: RootStackScreenProps<"ForgotPassword">) => {
  const [resetPassword] = useResetPassword();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const theme = useRecoilValue(themeColors);

  const onSubmit = (data: FormValues) => {
    setLoading(true);
    Keyboard.dismiss();

    try {
      resetPassword({ email: data.email.toLocaleLowerCase() });
      navigation.navigate("Login", { type: "forgotPassword" });
    } catch (e) {
      console.warn(e);
      setError("email", { message: t("general.network") });
    }

    setLoading(false);
    return null;
  };

  useEffect(() => reset({}), [reset]);

  return (
    <Header
      centerTitle={t("auth.forgotPassword.title")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <LoadingComponent visible={loading} />
            <EmptySpace height={35} />
            <Text
              size="30px"
              font="regular"
              lineHeight="36px"
              textColor={theme.title}
            >
              {t("auth.forgotPassword.header")}
            </Text>
            <EmptySpace height={35} />
            <Text size="15px" font="light" textColor={theme.text}>
              {t("auth.forgotPassword.text")}
            </Text>
            <EmptySpace height={35} />
            <EmptySpace height={15} />
            <FormInput
              name="email"
              variant="email"
              control={control}
              iconType="materialCommunityIcons"
              iconName="email"
              size={15}
              required
              height={50}
              placeholder={t("auth.forgotPassword.email")}
              returnKeyType="done"
              keyboardType="email-address"
            />
            <EmptySpace height={55} />
            <CenterWrapper>
              <Button
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={isValid ? theme.secondary : theme.primaryOpacity}
                disabled={!isValid}
                onPress={handleSubmit(onSubmit)}
              >
                <Text
                  size="21px"
                  font="regular"
                  textColor={theme.secondaryText}
                >
                  {t("auth.forgotPassword.submit")}
                </Text>
              </Button>
            </CenterWrapper>
            <EmptySpace height={35} />
            <CenterWrapper>
              <Button
                justifyContent="center"
                onPress={() => navigation.navigate("Register")}
              >
                <RowWrapper>
                  <Text
                    size="15px"
                    font="light"
                    textColor={theme.highlightText}
                  >
                    {t("auth.forgotPassword.alreadyHasntAccount").concat(" ")}
                  </Text>
                  <Text
                    size="15px"
                    font="regular"
                    underline
                    textColor={theme.highlightText}
                  >
                    {t("auth.forgotPassword.alreadyHasntAccountSubmit")}
                  </Text>
                </RowWrapper>
              </Button>
            </CenterWrapper>
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default ForgotPassword;
