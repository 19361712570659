import React, { useRef, Suspense } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar, Platform } from "react-native";
import DropdownAlert from "react-native-dropdownalert";
import { RecoilRoot } from "recoil";

import useNotifications from "./src/hooks/useNotifications";
import useI18n from "./src/hooks/useI18n";
import useCachedResources from "./src/hooks/useCachedResources";
import useConnectionInfo from "./src/hooks/useConnectionInfo";
import useSentry from "./src/hooks/useSentry";

import LoadingComponent from "./src/components/Loading";

import { ResettableRelayProvider } from "./src/relay/ResettableRelayProvider";
import createRelayEnvironment from "./src/relay";

import Navigation from "./src/navigation";

import getEnvVars from "./src/environment";
import useScripts from "./src/hooks/useScripts";

const { GOOGLE_ADS_WEB_ID, GOOGLE_ADS_WEB_SLOT } = getEnvVars();

export default function App() {
  const isLoadingComplete = useCachedResources();
  const dropdownAlertRef = useRef<DropdownAlert>(null);

  if (Platform.OS !== "web") {
    // LogBox.ignoreAllLogs();
  }

  useSentry();
  useNotifications();
  useConnectionInfo(dropdownAlertRef);
  useScripts();

  if (!isLoadingComplete) {
    return null;
  }

  const RecoilRootBody = () => {
    useI18n();

    return (
      <Suspense fallback={<LoadingComponent visible />}>
        <SafeAreaProvider>
          <StatusBar barStyle="light-content" />
          <Navigation />
        </SafeAreaProvider>
        <DropdownAlert
          ref={dropdownAlertRef}
          defaultContainer={{
            padding: 8,
            paddingTop: StatusBar.currentHeight,
            flexDirection: "row",
          }}
        />
        {Platform.OS === "web" && (
          <div>
            <script
              async
              src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${GOOGLE_ADS_WEB_ID}`}
              crossOrigin="anonymous"
            />
            <ins
              className="adsbygoogle"
              style={{
                width: 728,
                height: 90,
                display: "block",
                margin: "auto",
              }}
              data-ad-client={GOOGLE_ADS_WEB_ID}
              data-ad-slot={GOOGLE_ADS_WEB_SLOT}
            />
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
          </div>
        )}
      </Suspense>
    );
  };
  return (
    <Suspense fallback={null}>
      <ResettableRelayProvider createRelayEnvironment={createRelayEnvironment}>
        <RecoilRoot>
          <RecoilRootBody />
        </RecoilRoot>
      </ResettableRelayProvider>
    </Suspense>
  );
}
