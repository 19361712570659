import { useMutation, UseMutationConfig } from "react-relay";

import { useSetRecoilState } from "recoil";

import { updateUserMutation } from "../relay/queries/User";
import {
  User_updateUserMutation,
  User_updateUserMutation$variables,
  User_updateUserMutation$data,
} from "../__generated__/User_updateUserMutation.graphql";
import { theme } from "../atoms/persisted/theme";
import langAtom from "../atoms/persisted/lang";
import userAtom from "../atoms/persisted/user";

type UpdateUserPayload = Omit<User_updateUserMutation$variables["input"], "id">;
const useUpdateUser = () => {
  const [commit] = useMutation<User_updateUserMutation>(updateUserMutation);
  const updateTheme = useSetRecoilState(theme);
  const updateLang = useSetRecoilState(langAtom);
  const updateUserAtom = useSetRecoilState(userAtom);

  const updateUser = (id: string, payload: UpdateUserPayload) => {
    const mutationConfig: UseMutationConfig<User_updateUserMutation> = {
      variables: {
        input: { id, ...payload },
      },
      onCompleted: (res: User_updateUserMutation$data) => {
        if (res?.updateUser?.obj) {
          updateLang(res.updateUser.obj.language);
          updateTheme(res.updateUser.obj.colorScheme);

          // update current user state
          updateUserAtom((prevState) => ({
            ...prevState,
            ...res.updateUser.obj,
          }));
        }
      },
      onError: (e: Error): void => {
        console.warn("error", e.message);
      },
    };

    commit(mutationConfig);
  };

  return [updateUser] as const;
};

export default useUpdateUser;
