import { Share } from "react-native";
import { Buffer } from "buffer";

const onShare = async (value: string) => {
  try {
    const result = await Share.share({
      message: value,
    });
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        // shared with activity type of result.activityType
      } else {
        // shared
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    console.warn(error);
  }
};

const validatePassword = (value: string) => {
  const expression = /(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;

  return expression.test(String(value).toLowerCase());
};

const validateEmail = (value: string) => {
  const expression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
  return expression.test(String(value).toLowerCase());
};

const validateNumber = (value: string) => {
  const expression = /^\d+$/;
  return expression.test(String(value).toLowerCase());
};

const cutString = (value: string, limit: number) =>
  value.length > limit ? value.substring(0, limit).concat("...") : value;

const numbersOnly = (value: string) => !!/^\d+$/.test(value.toString());

const base64 = (value: string, replace?: string) =>
  Buffer.from(value, "base64")
    .toString()
    .replace(replace ?? "", "");

export {
  validatePassword,
  validateEmail,
  validateNumber,
  onShare,
  cutString,
  numbersOnly,
  base64,
};
