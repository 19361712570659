import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import DropdownAlert from "react-native-dropdownalert";
import { StatusBar } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { useForm } from "react-hook-form";
import { uniqueId } from "lodash";

import Header from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { View } from "../../components/Themed";
import { CenterWrapper, EmptySpace } from "../../components/wrappers";

import { themeColors } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";
import { ContextType } from "../../components/form/types";
import userAtom from "../../atoms/persisted/user";
import FormInput from "../../components/form";
import { useEditPassword } from "../../services/auth";

type FormValues = {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
};

const EditPassword = ({ navigation }: RootStackScreenProps<"EditPassword">) => {
  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isValid },
  } = useForm<FormValues, ContextType>({
    mode: "onChange",
    context: { refKey: uniqueId() },
  });
  const { t } = useTranslation();

  const user = useRecoilValue(userAtom);
  const theme = useRecoilValue(themeColors);
  const dropdownAlertRef = useRef<DropdownAlert>(null);
  const [updatePassword] = useEditPassword();

  useEffect(() => reset({}), [reset, user]);

  const onSubmit = (data: FormValues) => {
    if (data.newPassword1 !== data.newPassword2) {
      setError("newPassword2", {
        message: t("editProfile.notMatchingPasswords"),
      });
      return;
    }

    updatePassword(
      {
        id: user?.id,
        passwordDb: data.oldPassword,
        // NOTE: this should be handled on FE
        password: data.newPassword1,
        passwordCheck: data.newPassword1,
      },
      {
        onError: (e: Error) => {
          // HACK: BE returns BS types
          const message = ` - ${e.message
            .replaceAll(/[[|\]|']/g, "")
            .split(",")
            .join("\n - ")}`;

          setError("newPassword2", {
            message,
          });
        },
        onSuccess: () =>
          dropdownAlertRef?.current?.alertWithType(
            "success",
            "",
            t("editProfile.successfullyEdited")
          ),
      }
    );
  };

  return (
    <Header
      centerTitle={t("editProfile.editPassword")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      bodyOverflow="visible"
      body={
        <KeyboardAwareScrollView enableOnAndroid>
          <CenterWrapper>
            <View
              style={{
                borderRadius: radiusSizes.basicRadius,
                justifyContent: "center",
                width: "100%",
                maxWidth: 1000,
                alignSelf: "center",
              }}
            >
              <EmptySpace height={25} />
              <FormInput
                name="oldPassword"
                variant="text"
                required
                control={control}
                iconType="fontAwesome"
                iconName="lock"
                size={15}
                height={50}
                secureTextEntry
                placeholder={t("editProfile.oldPassword")}
                returnKeyType="done"
              />

              <EmptySpace height={15} />
              <FormInput
                name="newPassword1"
                variant="text"
                required
                control={control}
                iconType="fontAwesome"
                iconName="lock"
                size={15}
                height={50}
                secureTextEntry
                placeholder={t("editProfile.newPassword")}
                returnKeyType="done"
              />
              <EmptySpace height={15} />
              <FormInput
                name="newPassword2"
                variant="text"
                required
                control={control}
                iconType="fontAwesome"
                iconName="lock"
                size={15}
                height={50}
                secureTextEntry
                placeholder={t("editProfile.newPasswordAgain")}
                returnKeyType="done"
              />
              <EmptySpace height={15} />
              <Button
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={isValid ? theme.secondary : theme.primaryOpacity}
                disabled={!isValid}
                onPress={handleSubmit(onSubmit)}
              >
                <Text
                  size="14px"
                  font="regular"
                  textColor={theme.secondaryText}
                >
                  {t("editProfile.submit")}
                </Text>
              </Button>
            </View>
          </CenterWrapper>
          <DropdownAlert
            ref={dropdownAlertRef}
            defaultContainer={{
              padding: 8,
              paddingTop: StatusBar.currentHeight,
              flexDirection: "row",
            }}
          />
        </KeyboardAwareScrollView>
      }
    />
  );
};

export default EditPassword;
