import React, { forwardRef, useState } from "react";
import { Platform, Pressable, View } from "react-native";
import s from "styled-components/native";
import { useRecoilValue } from "recoil";

import { Ionicons } from "@expo/vector-icons";

import { themeColors } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

const P = s(Pressable)<{ border: string; background: string }>`
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: ${radiusSizes.miniRadius}px;
    border: 2px solid  ${(props) => props.border} !important;
    background: ${(props) => props.background};
    margin-left: 10px;
    margin-right: 10px;
    ${Platform.OS === "web" ? "outlineStyle: none;" : null}

`;

type CheckboxAType = {
  value: boolean;
  setValue: (value: boolean) => void;
};

export const Checkbox = ({ value, setValue }: CheckboxAType) => {
  const theme = useRecoilValue(themeColors);

  const onCheckmarkPress = () => {
    setValue(!value);
  };

  return (
    <P
      border={!value ? theme.input.border : theme.secondary}
      background={!value ? "transparent" : theme.secondary}
      onPress={() => onCheckmarkPress()}
    >
      {value && <Ionicons name="checkmark" size={16} color="white" />}
    </P>
  );
};

type FormCheckboxProp = {
  onChange: (...event: unknown[]) => void;
  onBlur: () => void;
  value: boolean;
};

export const FormCheckbox = forwardRef<View, FormCheckboxProp>(
  ({ onBlur, onChange, value }, ref) => {
    const theme = useRecoilValue(themeColors);
    const [focus, setFocus] = useState(false);

    const handleChange = () => {
      onChange(!value);
    };

    return (
      <P
        border={value || focus ? theme.primary : theme.input.border}
        background={!value ? "transparent" : theme.primary}
        onPress={handleChange}
        ref={ref}
        onBlur={() => {
          setFocus(false);
          onBlur?.();
        }}
        onFocus={() => {
          setFocus(true);
        }}
      >
        {value && <Ionicons name="checkmark" size={16} color="white" />}
      </P>
    );
  }
);
