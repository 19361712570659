import Constants from "expo-constants";

const SHARED = {
  GOOGLE_ADS_ANDROID_ID: "ca-app-pub-3940256099942544/1033173712",
  GOOGLE_ADS_IOS_ID: "ca-app-pub-3940256099942544/1033173712",
  GOOGLE_ADS_WEB_ID: "ca-pub-1234567890123456",
  GOOGLE_ADS_WEB_SLOT: "1234567890",
  SENTRY_DSN: "https://3ffb7567048b4e298d8a3672f49ac290@sentry.thinkeasy.cz/59",
};

const ENV = {
  local: {
    ...SHARED,
    SENTRY_DEBUG: true,
    SENTRY: false,
    SCHEME: "exp://127.0.0.1:19000/--/",
    API_URL: "https://sousede-be.dev.thinkeasy.cz",
    QRCODE_URL: "https://sousede-webapp.dev.thinkeasy.cz",
    WSS_URL: "",
    GOOGLE_MAPS_APIKEY: "AIzaSyAwdN3CYoWEimg3H_gbhTHdHMWqdg6SyXI",
    ADS: "EMULATOR",
  },
  dev: {
    ...SHARED,
    SENTRY_DEBUG: true,
    SENTRY: true,
    SCHEME: "sousede-app://",
    API_URL: "https://sousede-be.dev.thinkeasy.cz",
    QRCODE_URL: "https://sousede-webapp.dev.thinkeasy.cz",
    WSS_URL: "",
    GOOGLE_MAPS_APIKEY: "AIzaSyAwdN3CYoWEimg3H_gbhTHdHMWqdg6SyXI",
    ADS: "DEVICE",
  },
  stage: {
    ...SHARED,
    SENTRY_DEBUG: true,
    SENTRY: true,
    SCHEME: "sousede-app://",
    API_URL: "https://sousede-be.stage.thinkeasy.cz",
    QRCODE_URL: "https://sousede-webapp.stage.thinkeasy.cz",
    WSS_URL: "",
    ADS: "DEVICE",
    GOOGLE_MAPS_APIKEY: "AIzaSyAwdN3CYoWEimg3H_gbhTHdHMWqdg6SyXI",
  },
  prod: {
    ...SHARED,
    SENTRY_DEBUG: false,
    SENTRY: true,
    SCHEME: "sousede-app://",
    QRCODE_URL: "https://happy-sousede.com",
    WSS_URL: "",
    API_URL: "https://api.happy-sousede.com",
    ADS: "DEVICE",
    GOOGLE_MAPS_APIKEY: "AIzaSyAwdN3CYoWEimg3H_gbhTHdHMWqdg6SyXI",
  },
};

const getEnvVars = (
  env = (Constants.manifest?.extra as { env: string })?.env ?? ""
) => {
  if (env === "dev") {
    return ENV.dev;
  }
  if (env === "stage") {
    return ENV.stage;
  }
  if (env === "prod") {
    return ENV.prod;
  }
  return ENV.local;
};

export default getEnvVars;
