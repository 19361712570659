import { useMutation, UseMutationConfig } from "react-relay";

import { CreateServiceInput, UpdateServiceInput } from "../types/graphql";
import {
  Services_createServiceMutation,
  Services_createServiceMutation$data,
} from "../__generated__/Services_createServiceMutation.graphql";
import {
  createServiceMutation,
  updateService,
} from "../relay/queries/Services";
import {
  Services_updateServiceMutation,
  Services_updateServiceMutation$data,
} from "../__generated__/Services_updateServiceMutation.graphql";

type CreateServiceHookProps = {
  onCompleted?: (res: Services_createServiceMutation$data) => void;
  onError?: (e: Error) => void;
};

export const useCreateService = ({
  onCompleted,
  onError,
}: CreateServiceHookProps) => {
  const [commit] = useMutation<Services_createServiceMutation>(
    createServiceMutation
  );

  const createService = (payload: CreateServiceInput) => {
    const mutationConfig: UseMutationConfig<Services_createServiceMutation> = {
      variables: {
        input: payload,
      },
      onCompleted,
      onError,
    };

    commit(mutationConfig);
  };

  return [createService] as const;
};

type UpdateServiceHookProps = {
  onCompleted?: (res: Services_updateServiceMutation$data) => void;
  onError?: (e: Error) => void;
};

export const useUpdateService = ({
  onCompleted,
  onError,
}: UpdateServiceHookProps) => {
  const [commit] = useMutation<Services_updateServiceMutation>(updateService);

  const updateServiceAction = (payload: UpdateServiceInput) => {
    const mutationConfig: UseMutationConfig<Services_updateServiceMutation> = {
      variables: {
        input: payload,
      },
      onCompleted,
      onError,
    };

    commit(mutationConfig);
  };

  return [updateServiceAction] as const;
};
