import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Image, Platform } from "react-native";
import { useRecoilValue } from "recoil";
import s from "styled-components/native";
import QRCode from "react-qr-code";

import Header from "../../components/Header";
import { View } from "../../components/Themed";
import { BodyContentWrapper } from "../../components/wrappers";
import ElementWrapper from "../../components/ElementWrapper";
import TreeComponent from "../../components/tree";
import { Tree } from "../../@types/tree";

import { themeColors, theme } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";

import getEnvVars from "../../environment";
import { ColorSchemeChoices, UserLanguage } from "../../types/graphql";
import useUpdateUser from "../../services/user";
import userAtom from "../../atoms/persisted/user";

const { QRCODE_URL } = getEnvVars();

const BodyRow = s(View)<{ height?: number; marginBottom?: number }>`
  flex-direction: row;
  ${(props) => (props.height ? `height: ${props.height}px;` : null)} 
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : null}
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

const BodyItemLeft = s(View)`
  flex: 1;
`;

const ItemImage = s(Image)`
  position: relative;
  top: -50px;
  border-radius: ${radiusSizes.basicRadius}px;
  height: 100px;
  width: 100px;
  resize-mode: cover;
`;

const Settings = ({ navigation }: RootStackScreenProps<"Settings">) => {
  const currentTheme = useRecoilValue(theme);
  const user = useRecoilValue(userAtom);
  const Colors = useRecoilValue(themeColors);
  const [selectedColors, setSelectedColors] = useState<ColorSchemeChoices[]>([
    currentTheme,
  ]);
  const [selectedColorsError, setSelectedColorsError] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState<UserLanguage[]>([
    user.language,
  ]);
  const [selectedLanguageError, setSelectedLanguageError] = useState("");
  const firstUpdate = useRef(true);
  const firstUpdate1 = useRef(true);
  const [updateUser] = useUpdateUser();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedColors.length <= 0) {
      setSelectedColorsError(t("form.selectValue"));
      return;
    }
    if (firstUpdate.current) {
      setSelectedColorsError("");
      firstUpdate.current = false;
      return;
    }

    if (selectedColors[0] !== user.colorScheme)
      updateUser(user.id, { colorScheme: selectedColors[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColors]);

  useEffect(() => {
    setLoading(true);
    if (selectedLanguage.length <= 0) {
      setLoading(false);
      setSelectedLanguageError(t("form.selectValue"));
      return;
    }
    if (firstUpdate1.current) {
      setLoading(false);
      setSelectedLanguageError("");
      firstUpdate1.current = false;
      return;
    }
    const language = selectedLanguage[0];

    if (language !== user.language) {
      updateUser(user.id, { language });
      if (Platform.OS === "web") {
        getNavigationBack(navigation);
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const COLORS_DATA: Tree<ColorSchemeChoices> = {
    edges: [
      {
        node: {
          id: "BLUE",
          title: t("settings.color.blue"),
        },
      },
      {
        node: {
          id: "BLACK",
          title: t("settings.color.black"),
        },
      },
      {
        node: {
          id: "GREEN",
          title: t("settings.color.green"),
        },
      },
      {
        node: {
          id: "WINE",
          title: t("settings.color.wine"),
        },
      },
    ],
  };

  const LANGUAGE_DATA: Tree<UserLanguage> = {
    edges: [
      {
        node: {
          id: "CS",
          title: t("settings.language.cs"),
        },
      },
      {
        node: {
          id: "EN",
          title: t("settings.language.en"),
        },
      },
      {
        node: {
          id: "DE",
          title: t("settings.language.de"),
        },
      },
      {
        node: {
          id: "SK",
          title: t("settings.language.sk"),
        },
      },
    ],
  };

  return (
    <Header
      centerTitle={t("settings.header")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      bodyOverflow="visible"
      body={
        <BodyContentWrapper>
          <ElementWrapper
            title={`${t("settings.languageTitle")}:`}
            background={Colors.transparent}
            error={selectedLanguageError}
          >
            {loading ? (
              <ActivityIndicator />
            ) : (
              <TreeComponent
                data={LANGUAGE_DATA}
                selectedCategories={selectedLanguage}
                setSelectedCategories={setSelectedLanguage}
                onlyOne
                required
              />
            )}
          </ElementWrapper>
          <ElementWrapper
            title={`${t("settings.colorTitle")}:`}
            background={Colors.transparent}
            error={selectedColorsError}
          >
            <TreeComponent
              data={COLORS_DATA}
              selectedCategories={selectedColors}
              setSelectedCategories={setSelectedColors}
              onlyOne
              required
            />
          </ElementWrapper>
          <ElementWrapper
            title={`${t("settings.qrCodeTitle")}:`}
            background={Colors.transparent}
            error={selectedColorsError}
          >
            <QRCode value={`${QRCODE_URL}/user/?id=${user.id}`} />
          </ElementWrapper>
        </BodyContentWrapper>
      }
    />
  );
};

export default Settings;
