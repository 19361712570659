/**
 * @generated SignedSource<<5219040de586b844878eeb89d3dc7345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPasswordInput = {
  id: any;
  passwordDb: string;
  password: string;
  passwordCheck: string;
  clientMutationId?: string | null;
};
export type User_updateUserPasswordMutation$variables = {
  input: UpdateUserPasswordInput;
};
export type User_updateUserPasswordMutation$data = {
  readonly updateUserPassword: {
    readonly obj: {
      readonly id: string;
    } | null;
  } | null;
};
export type User_updateUserPasswordMutation = {
  variables: User_updateUserPasswordMutation$variables;
  response: User_updateUserPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPasswordPayload",
    "kind": "LinkedField",
    "name": "updateUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_updateUserPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_updateUserPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9434e495650aad207191d3456612dc0b",
    "id": null,
    "metadata": {},
    "name": "User_updateUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation User_updateUserPasswordMutation(\n  $input: UpdateUserPasswordInput!\n) {\n  updateUserPassword(input: $input) {\n    obj {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a6a85c1933893726f70bb3a701c6348";

export default node;
