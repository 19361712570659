import { LatLng } from "react-native-maps";
import { atom, selector } from "recoil";
import {
  CurrencyChoices,
  DayOfTheWeekChoices,
  ServiceOptionChoices,
  ServiceRewardChoices,
} from "../types/graphql";
import switcherAtom from "./switcher";

export const DEFAULT_RADIUS = 100; // km
export const DEFAULT_COORDS = {
  latitude: 50.08804,
  longitude: 14.42076,
};

export type Filter = {
  coordinates: LatLng;
  radius: number; // in km
  radiusSlider: boolean; // true -> slider, false -> manual input
  // optional fields
  days?: DayOfTheWeekChoices[];
  rewards?: ServiceRewardChoices[];
  priceFrom?: number;
  priceTo?: number;
  categories?: string[];
  startDate?: string;
  endDate?: string;
  timeFrom?: string;
  timeTo?: string;
  currency?: CurrencyChoices;

  // unnecessary fields
  coordinatesName: string;
};

type FilterReturnType = Omit<Filter, "coordinates"> & {
  coordinates: string;
  option: ServiceOptionChoices;
};

export const defaultFilterState: Filter = {
  coordinates: DEFAULT_COORDS,
  radius: DEFAULT_RADIUS,
  coordinatesName: "Prague",
  radiusSlider: false,
};

export const filterAtomState = atom<Filter>({
  key: "filter",
  default: defaultFilterState,
});

export const filterQuerySelector = selector<FilterReturnType>({
  key: "filterQuerySelector",
  get: ({ get }) => {
    const option = get(switcherAtom);
    const filter = get(filterAtomState);

    // development purposes -> never true
    if (!(filter && option)) return null;

    const { radius = DEFAULT_RADIUS, coordinates, ...filterData } = filter;

    const currentFilter: FilterReturnType = {
      option,
      radius: radius ?? 0,
      coordinates: `${coordinates.latitude},${coordinates.longitude}`,
      ...filterData,
    };

    return currentFilter;
  },
});
