import s from "styled-components/native";

import { TouchableOpacity } from "./Themed";

const Button = s(TouchableOpacity)<{
  justifyContent?: string;
  borderRadius?: number;
  borderColor?: string;
  margin?: string;
  width?: string;
  height?: string;
  padding?: string;
  alignItems?: string;
  webMaxWidth?: string;
  flexDirection?: string;
}>`
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  padding: ${(props) => props.padding ?? "15px"};
  margin: ${(props) => props.margin ?? "0px"};
  ${(props) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}px;` : null} 
  ${(props) => (props.margin ? `margin: ${props.margin};` : null)}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : null} 
  border: ${(props) =>
    props.borderColor ? "1px solid ".concat(props.borderColor) : "none"};
  width: ${(props) => props.width ?? "100%"} ;
  ${(props) => (props.borderRadius ? `height: ${props.height};` : null)} 
  align-items: ${(props) => props.alignItems ?? "center"};
  ${(props) => (props.webMaxWidth ? `max-width: ${props.webMaxWidth};` : null)} 
  `;

export default Button;
