import s from "styled-components/native";

import { Text as T } from "./Themed";

const Text = s(T)<{
  font?: string;
  size?: string;
  textAlign?: string;
  lineHeight?: string;
  position?: string;
  width?: string;
  padding?: string;
  margin?: string;
  display?: boolean;
  flex?: string;
  underline?: boolean;
}>`
  ${(props) => (props.position ? `position: ${props.position};` : null)}
  font-family: ${(props) => props.font ?? "regular"};
  font-size: ${(props) => props.size ?? "18px"};
  text-align: ${(props) => props.textAlign ?? "center"};
  line-height: ${(props) => props.lineHeight ?? "21px"};
  ${(props) => (props.padding ? `padding: ${props.padding};` : null)} 
  ${(props) => (props.margin ? `margin: ${props.margin};` : null)} 
  ${(props) => (props.flex ? `flex: ${props.flex};` : null)} 
  ${(props) =>
    props.display !== undefined
      ? `display: ${!props.display ? "none" : "flex"};`
      : null} 
  text-decoration: ${(props) => (props.underline ? "underline" : "none")}
  ${(props) => (props.width ? `width: ${props.width};` : null)}
`;

export default Text;
