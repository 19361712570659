import { useEffect, RefObject } from "react";
import { useNetInfo } from "@react-native-community/netinfo";
import { useTranslation } from "react-i18next";
import DropdownAlert from "react-native-dropdownalert";

const useConnectionInfo = (dropdownAlertRef: RefObject<DropdownAlert>) => {
  const netInfo = useNetInfo();
  const { t } = useTranslation();
  useEffect(() => {
    if (netInfo.isInternetReachable === false) {
      dropdownAlertRef.current?.alertWithType(
        "error",
        t("general.noInternetHeader"),
        t("general.noInternetMessage")
      );
    }
  }, [dropdownAlertRef, netInfo.isInternetReachable, t]);
};

export default useConnectionInfo;
