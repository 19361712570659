import React from "react";
import s from "styled-components/native";
import { useRecoilValue } from "recoil";

import { TouchableOpacity, Text } from "./Themed";
import { themeColors } from "../atoms/persisted/theme";

const Wrapper = s(TouchableOpacity)`
  flex: 1;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Label = s(Text)`
  flex: 3;
  padding: 0 15px;
  text-align: left;
  font-family: regular;
  font-size: 13px;
`;

const TextButton = s(Text)`
  flex: 2;
  padding-right: 10px;
  text-align: right;
`;

type Props = {
  value: string;
  buttonTitle: string;
  onPress?: () => void;
};

const EditValue = (props: Props) => {
  const theme = useRecoilValue(themeColors);

  return (
    <Wrapper onPress={() => props.onPress?.()}>
      <Label>{props.value}</Label>
      <TextButton>
        <Text
          textColor={theme.highlightText}
          style={{ flex: 2, textDecorationLine: "underline" }}
        >
          {props.buttonTitle}
        </Text>
      </TextButton>
    </Wrapper>
  );
};

export default EditValue;
