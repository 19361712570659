import { createNavigationContainerRef } from "@react-navigation/native";
import { RootStackParamList } from "../@types/navigation";

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export function navigate<I extends keyof RootStackParamList>(
  name: I,
  params?: RootStackParamList[I]
) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}
