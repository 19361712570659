import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import s from "styled-components/native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useForm } from "react-hook-form";
import { uniqueId } from "lodash";
import { Platform } from "react-native";

import { pickImage } from "../../services/imagePicker";

import Header, { ItemImage, BodyRow } from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { View, TouchableOpacity } from "../../components/Themed";
import {
  CenterWrapper,
  EmptySpace,
  RowWrapper,
} from "../../components/wrappers";
import ConfirmModal from "../../components/ConfirmModal";
import LoadingComponent from "../../components/Loading";
import Icon from "../../components/Icon";

import { themeColors } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";
import { ContextType } from "../../components/form/types";
import userAtom from "../../atoms/persisted/user";
import FormInput from "../../components/form";
import useUpdateUser from "../../services/user";
import { getReactNativeFile } from "../../services/file";

const BodyItemLeft = s(View)`
  flex: 1;
`;

const BodyItemRight = s(View)`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

type FormValues = {
  fullName: string;
  phoneNumber: string;
  overview: string;
  showPhoneNumber: boolean;
  password: string;
};

const EditProfile = ({ navigation }: RootStackScreenProps<"EditProfile">) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<FormValues, ContextType>({
    mode: "onChange",
    context: { refKey: uniqueId() },
  });
  const { t } = useTranslation();

  const user = useRecoilValue(userAtom);
  const theme = useRecoilValue(themeColors);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateUser] = useUpdateUser();

  const [profileError, setProfileError] = useState<string>("");

  useEffect(() => {
    reset(
      user
        ? {
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            overview: user.overview,
            showPhoneNumber: user.showPhoneNumber,
          }
        : {}
    );
  }, [reset, user]);

  const onSubmit = (data: FormValues) => {
    updateUser(user.id, data);
    setModalVisible(false);
    setLoading(false);
    if (navigation.canGoBack()) navigation.goBack();
    return null;
  };
  const isWeb = useMemo(() => Platform.OS === "web", []);

  const handleUploadImage = async () => {
    if (isWeb) return;

    setLoading(true);
    const result = await pickImage();

    setProfileError("");
    if (result) {
      const profilePicture = getReactNativeFile(result);

      updateUser(user.id, {
        image: profilePicture,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <LoadingComponent visible={loading} />
      <Header
        centerTitle={t("editProfile.header")}
        leftAction={() => getNavigationBack(navigation)}
        scrollingBody
        bodyOverflow="visible"
        bodyHeader={
          <>
            <BodyRow height={50} marginBottom={20} style={{ marginTop: 63 }}>
              <BodyItemLeft>
                <TouchableOpacity
                  activeOpacity={isWeb ? 1 : 0.5}
                  onPress={() => void handleUploadImage()}
                >
                  <View>
                    {!isWeb && (
                      <View
                        style={{
                          position: "absolute",
                          top: -65,
                          left: user.image !== "" && user.image ? 80 : 100,
                          backgroundColor: theme.secondary,
                          width: 25,
                          height: 25,
                          borderRadius: 15,
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: 10,
                        }}
                      >
                        <Icon
                          type="fontAwesome5"
                          name="pen"
                          size={13}
                          color={theme.white}
                        />
                      </View>
                    )}
                    {user.image !== "" && user.image ? (
                      <ItemImage
                        source={{
                          uri: user.image,
                        }}
                      />
                    ) : (
                      <View
                        style={{
                          marginLeft: 20,
                          marginTop: -60,
                          width: 100,
                          height: 100,
                          backgroundColor: theme.white,
                          borderRadius: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Icon
                          type="ionicons"
                          name="person"
                          size={100}
                          color={theme.primary}
                        />
                      </View>
                    )}
                  </View>
                </TouchableOpacity>
                {profileError !== "" && (
                  <Text size="14px" font="regular" textColor={theme.primary}>
                    {profileError}
                  </Text>
                )}
              </BodyItemLeft>
              <BodyItemRight>
                <View style={{ position: "relative", top: -25 }}>
                  <Button
                    width="auto"
                    padding="7px 10px"
                    justifyContent="center"
                    borderRadius={radiusSizes.smallRadius}
                    background={theme.secondarySurface}
                    onPress={() => navigation.navigate("EditPassword")}
                  >
                    <Icon
                      type="fontAwesome"
                      name="key"
                      size={13}
                      color={theme.text}
                    />
                    <Text
                      size="13px"
                      font="regular"
                      textColor={theme.text}
                      padding="0 0 0 10px"
                    >
                      {t("editProfile.editPassword")}
                    </Text>
                  </Button>
                  <Button
                    margin="10px 0"
                    width="auto"
                    padding="7px 10px"
                    justifyContent="center"
                    borderRadius={radiusSizes.smallRadius}
                    background={theme.secondary}
                    onPress={() => navigation.navigate("DeleteUser")}
                  >
                    <Icon
                      type="antDesign"
                      name="deleteuser"
                      size={13}
                      color={theme.white}
                    />
                    <Text
                      size="13px"
                      font="regular"
                      textColor={theme.white}
                      padding="0 0 0 10px"
                    >
                      {t("editProfile.deleteUser")}
                    </Text>
                  </Button>
                </View>
              </BodyItemRight>
            </BodyRow>
          </>
        }
        body={
          <KeyboardAwareScrollView enableOnAndroid>
            <CenterWrapper>
              <View
                style={{
                  borderRadius: radiusSizes.basicRadius,
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: 1000,
                  alignSelf: "center",
                }}
              >
                <FormInput
                  name="fullName"
                  variant="text"
                  control={control}
                  iconType="ionicons"
                  iconName="person"
                  required
                  size={15}
                  height={50}
                  placeholder={t("editProfile.fullname")}
                  returnKeyType="next"
                />

                <EmptySpace height={15} />
                <FormInput
                  name="phoneNumber"
                  variant="phone"
                  control={control}
                  required
                  iconType="fontAwesome"
                  iconName="phone"
                  size={15}
                  height={50}
                  placeholder={t("editProfile.phone")}
                  returnKeyType="next"
                  keyboardType="phone-pad"
                />
                <EmptySpace height={15} />
                <FormInput
                  name="overview"
                  variant="text"
                  control={control}
                  iconType="ionicons"
                  iconName="document-text-outline"
                  size={15}
                  height={100}
                  placeholder={t("editProfile.note")}
                  returnKeyType="go"
                  textarea
                />
                <EmptySpace height={15} />
                <RowWrapper>
                  <FormInput
                    name="showPhoneNumber"
                    variant="checkbox"
                    control={control}
                  />
                  <Text
                    size="15px"
                    flex="1"
                    textAlign="left"
                    textColor={theme.input.text}
                  >
                    {t("auth.register.checkbox")}
                  </Text>
                </RowWrapper>
                <EmptySpace height={15} />
                <Button
                  justifyContent="center"
                  borderRadius={radiusSizes.smallRadius}
                  background={isValid ? theme.secondary : theme.primaryOpacity}
                  disabled={!isValid}
                  onPress={() => setModalVisible(true)}
                >
                  <Text
                    size="14px"
                    font="regular"
                    textColor={theme.secondaryText}
                  >
                    {t("editProfile.submit")}
                  </Text>
                </Button>
              </View>
            </CenterWrapper>
            <ConfirmModal
              visible={modalVisible}
              setVisible={setModalVisible}
              title={t("editProfile.alert.title")}
              description={t("editProfile.alert.description")}
              ok={t("general.yes")}
              okAction={handleSubmit(onSubmit)}
              nok={t("general.no")}
              nokAction={() => setModalVisible(false)}
            />
          </KeyboardAwareScrollView>
        }
      />
    </>
  );
};

export default EditProfile;
