import { fetchQuery } from "react-relay";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Location from "expo-location";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";

import { ActivityIndicator, Platform } from "react-native";
import { LatLng } from "react-native-maps";
import Header, { BodyRow } from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Section from "../../components/section";
import Icon from "../../components/Icon";
import { BodyContentWrapper } from "../../components/wrappers";

import { themeColors } from "../../atoms/persisted/theme";
import {
  filterQuerySelector,
  filterAtomState,
  defaultFilterState,
} from "../../atoms/filter";

import { radiusSizes } from "../../constants/Sizes";

import { RootTabScreenProps } from "../../@types/navigation";
import { getAllServicesQuery, Service } from "../../relay/queries/Services";
import { Services_allServicesQuery } from "../../__generated__/Services_allServicesQuery.graphql";
import { TouchableOpacity, View } from "../../components/Themed";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import getAddressFromCoords from "../../hooks/useGetAddress";
import { cutString } from "../../services/text";
import Categories from "./Categories";

const Home = ({ navigation }: RootTabScreenProps<"Home">) => {
  const theme = useRecoilValue(themeColors);
  const [filter, setFilter] = useRecoilState(filterAtomState);
  const queryFilter = useRecoilValue(filterQuerySelector);
  const [areCategoriesOpened, setOpenCategories] = useState(false);
  const { t } = useTranslation();

  const { environment } = useResettableRelayContext();
  const [serviceData, setServiceData] = useState<Service[]>([]);

  const [currentPosition, setCurrentPosition] = useState<
    LatLng & { valid: boolean; name: string }
  >(null);

  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState<{
    loading: boolean;
    accepted: boolean;
  }>({ loading: false, accepted: false });

  const [loadingQuery, setLoadingQuery] = useState(false);

  // cannot use lazyLoadedQuery due to bad code structure
  const getData = useCallback(async () => {
    const { allServices } = await fetchQuery<Services_allServicesQuery>(
      environment,
      getAllServicesQuery,
      queryFilter
    ).toPromise();
    if (!allServices?.edges) {
      console.warn("refetch unsuccessful");
      return [];
    }

    return Object.values(allServices.edges).map(({ node }) => node);
  }, [environment, queryFilter]);

  const refetch = useCallback(async () => {
    // setLoadingQuery(true);
    const data = await getData();
    // setLoadingQuery(false);
    setServiceData(data);
  }, [getData]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  const getCurrentPosition = useCallback(async () => {
    setLoadingCurrentLocation({ loading: true, accepted: false });
    try {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setLoadingCurrentLocation({ loading: false, accepted: false });
        return;
      }

      const location = await Location.getCurrentPositionAsync();

      const coords = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      };

      const name = await getAddressFromCoords({ coords, skipCheck: true });

      setCurrentPosition({ ...location.coords, valid: true, name });
      setFilter((state) => ({
        ...state,
        coordinates: coords,
        coordinatesName: name,
      }));

      setLoadingCurrentLocation({ loading: false, accepted: true });
    } catch {
      setLoadingCurrentLocation({ loading: false, accepted: false });
    }
  }, [setFilter]);

  useEffect(() => {
    void getCurrentPosition();
  }, [getCurrentPosition]);

  const flexibleFilter = useMemo(
    () =>
      currentPosition?.valid === true
        ? {
            ...defaultFilterState,
            coordinates: {
              latitude: currentPosition.latitude,
              longitude: currentPosition.longitude,
            },
            coordinatesName: currentPosition.name,
          }
        : defaultFilterState,
    [currentPosition]
  );

  const isAdjustedFilter = useMemo(
    () => !_.isEqual(filter, flexibleFilter),
    [filter, flexibleFilter]
  );

  const loadingText = useMemo(() => {
    if (loadingCurrentLocation.loading) return t("home.loadingMessage.loading");

    return loadingCurrentLocation.accepted
      ? currentPosition?.name
      : t("home.loadingMessage.denied");
  }, [
    currentPosition?.name,
    loadingCurrentLocation.accepted,
    loadingCurrentLocation.loading,
    t,
  ]);

  return (
    <Header
      centerTitle={t("home.header")}
      switcher
      leftAction={
        isAdjustedFilter || areCategoriesOpened
          ? () => [setOpenCategories(false), setFilter(flexibleFilter)]
          : undefined
      }
      bodyHeader={
        <BodyRow justifyContent="flex-start" alignItems="center">
          <Button
            width={Platform.OS === "web" ? "auto" : "100px"}
            padding="7px 15px"
            justifyContent="center"
            borderRadius={radiusSizes.smallRadius}
            background={
              isAdjustedFilter ? theme.secondary : theme.secondarySurface
            }
            onPress={() => navigation.navigate("Filter")}
          >
            <Icon
              type="fontAwesome"
              name="filter"
              size={13}
              color={isAdjustedFilter ? theme.primaryText : theme.text}
            />
            <View style={{ marginLeft: 5 }} />
            <Text
              size="13px"
              font="regular"
              textColor={isAdjustedFilter ? theme.primaryText : theme.text}
            >
              {t("home.filter")}
            </Text>
          </Button>
          {isAdjustedFilter && (
            <TouchableOpacity
              style={{
                marginLeft: 5,
                padding: 10,
                borderRadius: 5,
                backgroundColor: theme.secondarySurface,
              }}
              onPress={() => setFilter(flexibleFilter)}
            >
              <Icon
                type="antDesign"
                name="close"
                size={13}
                color={theme.text}
              />
            </TouchableOpacity>
          )}
          {_.isEqual(flexibleFilter.coordinates, filter.coordinates) && (
            <View style={{ display: "flex", flexDirection: "row" }}>
              {loadingCurrentLocation.loading && (
                <ActivityIndicator
                  size="small"
                  color={theme.secondary}
                  style={{ marginLeft: 10 }}
                />
              )}
              {loadingCurrentLocation.accepted &&
                !loadingCurrentLocation.loading && (
                  <View style={{ marginLeft: 10, marginTop: 4 }}>
                    <Icon
                      type="fontAwesome5"
                      name="check"
                      size={13}
                      color={theme.primary}
                    />
                  </View>
                )}
              <Text
                textColor={
                  !loadingCurrentLocation.loading
                    ? theme.primary
                    : theme.primaryOpacity
                }
                size="13px"
                font="regular"
                style={{ marginLeft: 10 }}
              >
                {Platform.OS === "web"
                  ? loadingText
                  : cutString(loadingText, 30)}
              </Text>
            </View>
          )}
        </BodyRow>
      }
      loadingBody={loadingQuery}
      body={
        <BodyContentWrapper>
          <Section
            additionalHeaderComponent={
              <Categories
                isExpanded={areCategoriesOpened}
                setExpanded={setOpenCategories}
              />
            }
            data={serviceData}
            onPress={({ id }) => navigation.navigate("DetailAd", { id })}
            refresh={!serviceData}
            onRefresh={refetch}
          />
        </BodyContentWrapper>
      }
    />
  );
};

export default Home;
