import React, { Dispatch, SetStateAction, useState } from "react";

import Branch from "./Branch";

import { View } from "../Themed";

import { Tree } from "../../@types/tree";

type Props = {
  data: Tree<string>;
  selectedCategories: string[];
  setSelectedCategories: Dispatch<SetStateAction<string[]>>;
  onlyOne?: boolean;
  required?: boolean;
};

const TreeContainer = ({
  data,
  selectedCategories,
  setSelectedCategories,
  onlyOne,
  required,
}: Props) => {
  const [collapseCategories, setCollapseCategories] = useState<string[]>([]);
  const filtered = (data?.edges ?? []).filter(({ node }) => !node.parent);
  const d = { edges: filtered };
  return (
    <View style={{ width: "100%" }}>
      {filtered.map(({ node }) => (
        <Branch
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          collapseCategories={collapseCategories}
          setCollapseCategories={setCollapseCategories}
          key={node.id}
          data={d}
          item={node}
          level={0}
          onlyOne={onlyOne}
          required={required}
        />
      ))}
    </View>
  );
};

export default TreeContainer;
