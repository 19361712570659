/* eslint-disable import/prefer-default-export */
import { graphql } from "react-relay";

const getCategoriesQuery = graphql`
  query Categories_AllServiceCategoriesQuery {
    allServiceCategories {
      edges {
        node {
          id
          title
          image
          parent {
            id
          }
          subcategories {
            edges {
              node {
                id
                title
                parent {
                  id
                }
                subcategories {
                  edges {
                    node {
                      id
                      title
                      parent {
                        id
                      }
                      subcategories {
                        edges {
                          node {
                            id
                            title
                            parent {
                              id
                            }
                            subcategories {
                              edges {
                                node {
                                  id
                                  title
                                  parent {
                                    id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { getCategoriesQuery };
