import React, { useState, useEffect } from "react";
import { Keyboard } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { useForm } from "react-hook-form";
import { uniqueId } from "lodash";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";

import { themeColors } from "../../atoms/persisted/theme";

import LoadingComponent from "../../components/Loading";
import {
  EmptySpace,
  CenterWrapper,
  RowWrapper,
  BodyContentWrapper,
} from "../../components/wrappers";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Text from "../../components/Text";
import ConfirmModal from "../../components/ConfirmModal";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { useHandleLogin } from "../../services/auth";
import { ContextType } from "../../components/form/types";
import FormInput from "../../components/form";
import useAuthUser from "../../hooks/useAuthUser";

type FormValues = {
  email: string;
  password: string;
};
const Login = ({ route, navigation }: RootStackScreenProps<"Login">) => {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { isValid },
  } = useForm<FormValues, ContextType>({
    mode: "onChange",
    context: { refKey: uniqueId() },
  });
  const context = useResettableRelayContext();
  const { checkUser } = useAuthUser();

  const [handleLogin] = useHandleLogin({
    onError: (e) => {
      setError("password", {
        message: e.message
          ? t("form.enterValidCredentials")
          : t("general.network"),
      });
    },
    onSuccess: async () => {
      await checkUser();
      context.resetRelayEnvironment();
      navigation.replace("Root");
    },
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  useEffect(() => {
    reset({});
    if (route.params?.type === "forgotPassword") {
      setModalMessage(t("auth.login.alert.forgotPassword"));
      setModalVisible(true);
    }
    if (route.params?.type === "register") {
      setModalMessage(t("auth.login.alert.register"));
      setModalVisible(true);
    }
  }, [reset, route.params?.type, t]);

  const onSubmit = ({ email, password }: FormValues) => {
    setLoading(true);
    Keyboard.dismiss();

    handleLogin(email.toLocaleLowerCase(), password);

    setLoading(false);
    return null;
  };

  return (
    <Header
      centerTitle={t("auth.login.title")}
      scrollingBody
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <LoadingComponent visible={loading} />
            <EmptySpace height={35} />
            <Text
              size="30px"
              font="regular"
              lineHeight="36px"
              textColor={theme.title}
            >
              {t("auth.login.header")}
            </Text>
            <EmptySpace height={35} />
            <Text size="15px" font="light" textColor={theme.text}>
              {t("auth.login.text")}
            </Text>
            <EmptySpace height={50} />
            <FormInput
              name="email"
              variant="email"
              required
              control={control}
              iconType="materialCommunityIcons"
              iconName="email"
              size={15}
              height={50}
              keyboardType="email-address"
              placeholder={t("auth.register.email")}
              returnKeyType="next"
            />
            <EmptySpace height={15} />
            <FormInput
              name="password"
              variant="text"
              required
              control={control}
              iconType="fontAwesome"
              iconName="lock"
              size={15}
              height={50}
              secureTextEntry
              placeholder={t("auth.login.password")}
              returnKeyType="done"
            />
            <EmptySpace height={10} />
            <RowWrapper style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onPress={() => navigation.navigate("ForgotPassword")}
                width="auto"
              >
                <Text
                  size="15px"
                  font="regular"
                  textColor={theme.highlightText}
                >
                  {t("auth.login.forgotPassword")}
                </Text>
              </Button>
            </RowWrapper>

            <EmptySpace height={50} />
            <CenterWrapper>
              <Button
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={!isValid ? theme.primaryOpacity : theme.secondary}
                disabled={!isValid}
                onPress={handleSubmit(onSubmit)}
              >
                <Text
                  size="21px"
                  font="regular"
                  textColor={theme.secondaryText}
                >
                  {t("auth.login.submit")}
                </Text>
              </Button>
            </CenterWrapper>
            <EmptySpace height={35} />
            <CenterWrapper>
              <RowWrapper>
                <Text size="15px" font="light" textColor={theme.highlightText}>
                  {t("auth.login.alreadyHasntAccount").concat(" ")}
                </Text>
                <Button
                  padding="0px"
                  onPress={() => navigation.navigate("Register")}
                  width="auto"
                  height="15px"
                >
                  <Text
                    size="15px"
                    font="regular"
                    underline
                    textColor={theme.highlightText}
                  >
                    {t("auth.login.alreadyHasntAccountSubmit")}
                  </Text>
                </Button>
              </RowWrapper>
            </CenterWrapper>
            <ConfirmModal
              visible={modalVisible}
              setVisible={setModalVisible}
              title={t("auth.login.alert.header")}
              description={modalMessage}
              ok={t("general.ok")}
              okAction={() => navigation.replace("Login")}
              closeAction={() => navigation.replace("Login")}
            />
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default Login;
