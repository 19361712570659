import { atom } from "recoil";

const accessTokenAtom = atom({
  key: "accessToken",
  default: false,
});

export const ACCESS_TOKEN_KEY = "@access_token";
export const REFRESH_TOKEN_KEY = "@refresh_token";

export default accessTokenAtom;
