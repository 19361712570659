import { setGoogleApiKey } from "expo-location";
import { useEffect } from "react";
import { Platform } from "react-native";

import getEnvVars from "../environment";

const { GOOGLE_MAPS_APIKEY } = getEnvVars();

setGoogleApiKey(GOOGLE_MAPS_APIKEY);

const url = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_APIKEY}&v=weekly`;
const useScripts = () => {
  useEffect(() => {
    if (Platform.OS !== "web" || !url) return;

    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.id = "googleMapScript";

    document.body.appendChild(script);
  }, []);
};

export default useScripts;
