import React, { FC, useState } from "react";
import { Environment } from "relay-runtime";
import { RelayEnvironmentProvider } from "react-relay";
import createCtx from "../utils/createCtx";

interface Context {
  /**
   * Current Relay environment.
   */
  environment: Environment;

  /**
   * Discard the current Relay environment and
   * create a new one.
   */
  resetRelayEnvironment: () => void;
}

interface Props {
  /**
   * Factory function for generating Relay environments.
   */
  createRelayEnvironment: () => Environment;
}

const [useResettableRelayContext, Provider] = createCtx<Context>();

const ResettableRelayProvider: FC<Props> = ({
  children,
  createRelayEnvironment,
}) => {
  const [environment, setEnvironment] = useState<Environment>(
    createRelayEnvironment()
  );

  const resetRelayEnvironment = (evn?: Environment): void => {
    setEnvironment(evn ?? createRelayEnvironment());
  };

  return (
    <Provider value={{ environment, resetRelayEnvironment }}>
      <RelayEnvironmentProvider environment={environment}>
        {children}
      </RelayEnvironmentProvider>
    </Provider>
  );
};

export { useResettableRelayContext, ResettableRelayProvider };
