/**
 * @generated SignedSource<<46b8dc89f499317eaff6d50e51a0e93b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CurrencyChoices = "CZK" | "EUR";
export type DayOfTheWeekChoices = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
export type ServiceCurrency = "CZK" | "EUR";
export type ServiceOption = "PROVIDE" | "REQUEST";
export type ServiceOptionChoices = "PROVIDE" | "REQUEST";
export type ServiceReward = "FREE" | "PRICE" | "EXCHANGE";
export type ServiceRewardChoices = "FREE" | "PRICE" | "EXCHANGE";
export type ServiceStatus = "PENDING" | "ACTIVE" | "REJECTED" | "PENDING_REVIEW" | "RESOLVED_REPORT";
export type Services_allServicesQuery$variables = {
  radius: number;
  coordinates: any;
  option: ServiceOptionChoices;
  categories?: ReadonlyArray<any | null> | null;
  rewards?: ReadonlyArray<ServiceRewardChoices | null> | null;
  days?: ReadonlyArray<DayOfTheWeekChoices | null> | null;
  priceFrom?: number | null;
  priceTo?: number | null;
  currency?: CurrencyChoices | null;
  startDate?: any | null;
  endDate?: any | null;
  timeFrom?: any | null;
  timeTo?: any | null;
};
export type Services_allServicesQuery$data = {
  readonly allServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly price: any | null;
        readonly days: ReadonlyArray<string | null>;
        readonly user: {
          readonly image: string | null;
          readonly id: string;
        };
        readonly description: string | null;
        readonly title: string;
        readonly coordinatesName: string | null;
        readonly categories: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly title: string;
            } | null;
          } | null>;
        };
        readonly currency: ServiceCurrency | null;
        readonly option: ServiceOption;
        readonly status: ServiceStatus;
        readonly reward: ServiceReward | null;
      } | null;
    } | null>;
  } | null;
};
export type Services_allServicesQuery = {
  variables: Services_allServicesQuery$variables;
  response: Services_allServicesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categories"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coordinates"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currency"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "days"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "option"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceFrom"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceTo"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "radius"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rewards"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeFrom"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeTo"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "categories",
        "variableName": "categories"
      },
      {
        "kind": "Variable",
        "name": "coordinates",
        "variableName": "coordinates"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "days",
        "variableName": "days"
      },
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "option",
        "variableName": "option"
      },
      {
        "kind": "Variable",
        "name": "priceFrom",
        "variableName": "priceFrom"
      },
      {
        "kind": "Variable",
        "name": "priceTo",
        "variableName": "priceTo"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      },
      {
        "kind": "Variable",
        "name": "rewards",
        "variableName": "rewards"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "timeFrom",
        "variableName": "timeFrom"
      },
      {
        "kind": "Variable",
        "name": "timeTo",
        "variableName": "timeTo"
      }
    ],
    "concreteType": "ServiceTypeConnection",
    "kind": "LinkedField",
    "name": "allServices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "days",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinatesName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceCategoryTypeConnection",
                "kind": "LinkedField",
                "name": "categories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceCategoryTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServiceCategoryType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "option",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_allServicesQuery",
    "selections": (v15/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v10/*: any*/),
      (v4/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Operation",
    "name": "Services_allServicesQuery",
    "selections": (v15/*: any*/)
  },
  "params": {
    "cacheID": "3e501c75e07322a2b625ad3ef639ac3f",
    "id": null,
    "metadata": {},
    "name": "Services_allServicesQuery",
    "operationKind": "query",
    "text": "query Services_allServicesQuery(\n  $radius: Int!\n  $coordinates: Point!\n  $option: ServiceOptionChoices!\n  $categories: [PrimaryKey]\n  $rewards: [ServiceRewardChoices]\n  $days: [DayOfTheWeekChoices]\n  $priceFrom: Int\n  $priceTo: Int\n  $currency: CurrencyChoices\n  $startDate: Date\n  $endDate: Date\n  $timeFrom: Time\n  $timeTo: Time\n) {\n  allServices(radius: $radius, coordinates: $coordinates, option: $option, categories: $categories, rewards: $rewards, days: $days, priceFrom: $priceFrom, priceTo: $priceTo, currency: $currency, startDate: $startDate, endDate: $endDate, timeFrom: $timeFrom, timeTo: $timeTo) {\n    edges {\n      node {\n        id\n        price\n        days\n        user {\n          image\n          id\n        }\n        description\n        title\n        coordinatesName\n        categories {\n          edges {\n            node {\n              id\n              title\n            }\n          }\n        }\n        currency\n        option\n        status\n        reward\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7afe19de0e89fc964cc51758ece78de";

export default node;
