/**
 * @generated SignedSource<<83b4180eb14824e94173ed7478efa93a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type User_refreshTokenMutation$variables = {
  refreshToken: string;
};
export type User_refreshTokenMutation$data = {
  readonly refreshToken: {
    readonly token: string | null;
    readonly refreshToken: string | null;
  } | null;
};
export type User_refreshTokenMutation = {
  variables: User_refreshTokenMutation$variables;
  response: User_refreshTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "refreshToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "refreshToken",
        "variableName": "refreshToken"
      }
    ],
    "concreteType": "Refresh",
    "kind": "LinkedField",
    "name": "refreshToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_refreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_refreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9a3cf6ec45fb8a5114783cc402692b52",
    "id": null,
    "metadata": {},
    "name": "User_refreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation User_refreshTokenMutation(\n  $refreshToken: String!\n) {\n  refreshToken(refreshToken: $refreshToken) {\n    token\n    refreshToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbcf6a12788e49cb7329a80e98ea2f0a";

export default node;
