import { useEffect } from "react";
import * as Sentry from "sentry-expo";
import getEnvVars from "../environment";

const useSentry = () => {
  useEffect(() => {
    const { SENTRY, SENTRY_DSN, SENTRY_DEBUG } = getEnvVars();

    if (SENTRY) {
      Sentry.init({
        dsn: SENTRY_DSN,
        enableInExpoDevelopment: true,
        debug: SENTRY_DEBUG,
      });
    }
  }, []);
};

export default useSentry;
