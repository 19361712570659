import { atom } from "recoil";
import { User_meQuery$data } from "../../__generated__/User_meQuery.graphql";
import persistAtom from "./utils";

type UserType = User_meQuery$data[keyof User_meQuery$data] | null;
const atomKey = "userAtom";

const userAtom = atom<UserType>({
  key: atomKey,
  default: null,
  effects_UNSTABLE: [persistAtom(atomKey)],
});

export default userAtom;
