import { useTranslation } from "react-i18next";
import { FieldError } from "react-hook-form";
import { Variants } from "./types";

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const useErrorMessages = () => {
  const { t } = useTranslation();
  const getErrorMessage = (err: FieldError) => {
    if (!err) return null;

    if (err.message) return err.message;
    switch (err.type) {
      case "required":
        return t("form.emptyField");
      case "pattern": {
        if (err.ref.name === "email") return t("form.invalidEmail");

        return t("form.invalidField");
      }
      default:
        return t("form.invalidField");
    }
  };

  return [getErrorMessage] as const;
};

export const getRegexPattern = (variant: Variants) => {
  switch (variant) {
    case "phone":
      return phoneRegex;
    case "email":
      return emailRegex;

    default:
      return undefined;
  }
};

export const DATE_DDMMYYYY = [
  /[0-3]/,
  /\d/,
  ".",
  /[0-1]/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const TIME_HHMM = [/[0-2]/, /\d/, ":", /[0-5]/, /\d/];
