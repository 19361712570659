import React, { useState, Dispatch, SetStateAction, useEffect } from "react";

import NodeContainer from "./Node";
import { Node, Tree } from "../../@types/tree";

type BranchProps<I> = {
  item: Node<I>;
  level: number;
  selectedCategories: string[];
  setSelectedCategories: Dispatch<SetStateAction<string[]>>;
  collapseCategories: string[];
  setCollapseCategories: Dispatch<SetStateAction<string[]>>;
  onlyOne?: boolean;
  data: Tree<I>;
  required?: boolean;
};

const Branch = <I extends string>({
  item,
  level,
  selectedCategories,
  setSelectedCategories,
  collapseCategories,
  setCollapseCategories,
  onlyOne,
  data,
  required,
}: BranchProps<I>) => {
  const [selected, setSelected] = useState<boolean>(false);

  const hasNodes = (item.subcategories?.edges?.length ?? 0) > 0;

  useEffect(() => {
    if (collapseCategories) {
      const a = collapseCategories.find((i) => i === item.id);
      setSelected(!!a);
    }
  }, [collapseCategories, item.id]);

  type FlatNode = {
    id: string;
    parent?: string;
    nodes: { node: Node<string> }[];
  };

  function flatten(node: Node<string>, parent?: Node<string>): FlatNode[] {
    const nodes: FlatNode[] = [
      {
        id: node.id,
        parent: parent?.id,
        nodes: node.subcategories?.edges ?? [],
      },
    ];

    if (Array.isArray(node.subcategories?.edges)) {
      for (const child of node.subcategories?.edges) {
        nodes.push(...flatten(child.node, node));
      }
    } else if (typeof node.subcategories?.edges === "object") {
      nodes.push(...flatten(node.subcategories?.edges, node));
    }

    return nodes;
  }

  function findHighest(node: FlatNode, nodes: FlatNode[]): FlatNode[] {
    const flat: FlatNode[] = [
      { id: node.id, parent: node?.id, nodes: node.nodes },
    ];
    const { parent } = node;

    if (parent) {
      const managerObject = nodes.find((a) => a.id === parent);
      flat.push(...findHighest(managerObject, nodes));
    }

    return flat;
  }

  const getParents = (id: string): string[] => {
    const flat = data.edges.flatMap((n) => flatten(n.node));
    const node = flat.find((a) => a.id === id);
    const a = findHighest(node, flat);
    return a.map((b) => b.id);
  };

  const renderBranches = () => {
    if (hasNodes) {
      const newLevel = level + 1;
      const parentIds = getParents(item.subcategories.edges[0].node.id);

      return item.subcategories.edges
        .filter(({ node }) => parentIds.includes(node.parent?.id ?? ""))
        .map(({ node }) => (
          <Branch
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            collapseCategories={collapseCategories}
            setCollapseCategories={setCollapseCategories}
            key={node.id}
            item={node}
            data={data}
            onlyOne={onlyOne}
            level={newLevel}
            required={required}
          />
        ));
    }

    return null;
  };

  const toggleSelected = () => {
    const c = collapseCategories.find((i) => i === item.id);
    if (c) {
      const f = collapseCategories.filter((i) => i !== item.id);
      setCollapseCategories(f);
    } else {
      if (!item.parent) {
        setCollapseCategories([item.id]);
        return;
      }
      setCollapseCategories([...collapseCategories, item.id]);
    }
  };

  const flattenIds = (rootNode: Node<string>): string[] => {
    const nodes: string[] = [];
    const queue = [rootNode];

    while (queue.length > 0) {
      const node = queue.shift();

      if (Array.isArray(node.subcategories?.edges)) {
        for (const child of node.subcategories?.edges) {
          queue.push({ ...child.node });
        }
      } else if (typeof node.subcategories?.edges === "object") {
        queue.push(node.subcategories.edges);
      }

      nodes.push(node.id);
    }

    return nodes;
  };

  const checkParent = (id: string, categories: string[]) => {
    const flat = data.edges.flatMap((n) => flatten(n.node));
    const t = flat.find((a) => a.id === id);
    if (t && t.nodes?.length > 0) {
      const children = t.nodes.flatMap(({ node }) => node.id);
      let condition = true;
      children?.forEach((a) => {
        if (!categories?.includes(a)) {
          condition = false;
        }
      });
      if (condition) {
        setSelectedCategories([...categories, t.id]);
        checkParent(t?.parent, [...categories, t.id]);
      }
    }
    if (t?.parent) {
      checkParent(t?.parent, categories);
    }
  };

  const onCheck = (id: string) => {
    const find = selectedCategories?.find((a) => a === id);
    if (!find) {
      if (!onlyOne) {
        const categories = [
          ...(selectedCategories ?? []),
          ...(flattenIds(item) ?? []),
        ];
        setSelectedCategories(categories);
        const flat = data.edges.flatMap(({ node }) => flatten(node));
        const t = flat.find((a) => a.id === id);
        checkParent(t.parent, categories);
      } else {
        setSelectedCategories([id]);
      }
    } else if (!required) {
      const flat = [...flattenIds(item), ...getParents(id)];
      setSelectedCategories([
        ...selectedCategories.filter((a) => !flat.includes(a)),
      ]);
    } else {
      const flat = [...flattenIds(item), ...getParents(id)];
      const aa = selectedCategories.filter((a) => !flat.includes(a));
      console.warn(aa);
      if (aa.length > 1) {
        setSelectedCategories(aa);
      }
    }
  };

  return (
    <>
      <NodeContainer
        item={item}
        selected={selected}
        selectedCategories={selectedCategories}
        hasNodes={hasNodes}
        level={level}
        onToggle={toggleSelected}
        onCheck={(id: string) => onCheck(id)}
      />
      {selected && renderBranches()}
    </>
  );
};

export default Branch;
