import React, { useEffect, useMemo, useRef } from "react";
import { Controller, Path } from "react-hook-form";
import { TextInput } from "react-native";
import { useRecoilState, useRecoilValue } from "recoil";

import { themeColors } from "../../atoms/persisted/theme";
import { formRefStatesAtom } from "./state";

import FormTextInput from "./TextInput";
import MaskInput from "./MaskInput";

import { useErrorMessages, getRegexPattern } from "./utils";
import { ContextType, FormInputProps, VariantInputMap } from "./types";
import { FormCheckbox } from "./Checkbox";

const FormInput = <I, T extends ContextType>(
  formProps: FormInputProps<I, T>
) => {
  const { control, rules, name, variant, ...inputProps } = formProps;
  const Colors = useRecoilValue(themeColors);
  const [getErrorMessage] = useErrorMessages();
  const inputRef = useRef<TextInput>(null);

  const stateKey = useMemo(() => {
    const { _options: options } = control;

    const key = (options.context as { refKey?: string })?.refKey;
    if (!key) return null;

    return `stateKey-${key}`;
  }, [control]);

  const [values, setValues] = useRecoilState(formRefStatesAtom(stateKey));

  useEffect(() => {
    if (!values[name] && !!inputRef && !!stateKey && variant !== "checkbox") {
      setValues({ [name]: inputRef.current });
    }
  }, [name, setValues, stateKey, values, variant]);

  const nextRef = useMemo(() => {
    const nextKeyIndex = Object.keys(values).indexOf(name) + 1;
    return Object.values(values)[nextKeyIndex] ?? null;
  }, [name, values]);

  return (
    <Controller
      name={name as Path<I>}
      control={control}
      rules={{
        ...rules,
        required: inputProps.required,
        pattern: getRegexPattern(variant),
      }}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        if (variant === "checkbox")
          return (
            <FormCheckbox
              ref={inputRef}
              onChange={onChange}
              onBlur={onBlur}
              value={value as boolean}
            />
          );

        if (variant === "masked")
          return (
            <MaskInput
              ref={inputRef}
              value={value as string}
              onChange={onChange}
              onBlur={onBlur}
              error={getErrorMessage(fieldState.error)}
              {...(inputProps as VariantInputMap["masked"])}
            />
          );

        return (
          <FormTextInput
            ref={inputRef}
            onSubmitEditing={() => nextRef?.focus?.()}
            color={Colors.highlightText}
            focusBorderColor={Colors.primary}
            unFocusBorderColor={Colors.border}
            error={getErrorMessage(fieldState.error)}
            value={value as string}
            onBlur={onBlur}
            onChange={onChange}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export default FormInput;
