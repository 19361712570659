/**
 * @generated SignedSource<<6c3814625d19ad69aaeff116f0a9bf6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CurrencyChoices = "CZK" | "EUR";
export type DayOfTheWeekChoices = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
export type ServiceRewardChoices = "FREE" | "PRICE" | "EXCHANGE";
export type UpdateServiceInput = {
  id: any;
  title?: string | null;
  description?: string | null;
  categories?: ReadonlyArray<any> | null;
  days?: ReadonlyArray<DayOfTheWeekChoices> | null;
  startDate?: any | null;
  endDate?: any | null;
  timeFrom?: any | null;
  timeTo?: any | null;
  reward?: ServiceRewardChoices | null;
  price?: any | null;
  currency?: CurrencyChoices | null;
  coordinates?: any | null;
  coordinatesName?: string | null;
  radius?: number | null;
  isActive?: boolean | null;
  clientMutationId?: string | null;
};
export type Services_updateServiceMutation$variables = {
  input: UpdateServiceInput;
};
export type Services_updateServiceMutation$data = {
  readonly updateService: {
    readonly obj: {
      readonly id: string;
    } | null;
  } | null;
};
export type Services_updateServiceMutation = {
  variables: Services_updateServiceMutation$variables;
  response: Services_updateServiceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateServicePayload",
    "kind": "LinkedField",
    "name": "updateService",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_updateServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Services_updateServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "91cc69714ede740c0f7b0b19da872337",
    "id": null,
    "metadata": {},
    "name": "Services_updateServiceMutation",
    "operationKind": "mutation",
    "text": "mutation Services_updateServiceMutation(\n  $input: UpdateServiceInput!\n) {\n  updateService(input: $input) {\n    obj {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf30581494b7973f04eccfd599b83e01";

export default node;
