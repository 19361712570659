import s from "styled-components/native";
import { View } from "./Themed";

const EmptySpace = s(View)<{ height?: number; width?: number }>`
  height: ${(props) => props.height ?? 0}px;
  width: ${(props) => props.width ?? 0}px;
`;

const Wrapper = s(View)<{
  backgroundColor?: string;
  flex?: number;
  marginVertical?: number;
}>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  width: 100%;
  margin-vertical: ${(props) =>
    props.marginVertical ? props.marginVertical : 0}px;
`;

const CenterWrapper = s(View)`
  align-items: center;
`;

const BodyContentWrapper = s(View)`
  width: 100%;
  max-Width: 1000px;
  alignSelf: center
`;

const RowWrapper = s(View)`
  flex-direction: row;
`;
const ColumnWrapper = s(View)`
  flex-direction: column;
`;

export {
  EmptySpace,
  Wrapper,
  CenterWrapper,
  RowWrapper,
  ColumnWrapper,
  BodyContentWrapper,
};
