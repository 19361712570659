/**
 * @generated SignedSource<<aea3e4e8be13659c9f72c0ebfae27791>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CurrencyChoices = "CZK" | "EUR";
export type DayOfTheWeekChoices = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
export type ServiceOptionChoices = "PROVIDE" | "REQUEST";
export type ServiceRewardChoices = "FREE" | "PRICE" | "EXCHANGE";
export type CreateServiceInput = {
  title: string;
  description?: string | null;
  categories: ReadonlyArray<any>;
  days?: ReadonlyArray<DayOfTheWeekChoices> | null;
  startDate?: any | null;
  endDate?: any | null;
  timeFrom?: any | null;
  timeTo?: any | null;
  reward?: ServiceRewardChoices | null;
  price?: any | null;
  currency?: CurrencyChoices | null;
  coordinates: any;
  coordinatesName?: string | null;
  radius: number;
  option: ServiceOptionChoices;
  clientMutationId?: string | null;
};
export type Services_createServiceMutation$variables = {
  input: CreateServiceInput;
};
export type Services_createServiceMutation$data = {
  readonly createService: {
    readonly obj: {
      readonly id: string;
    } | null;
  } | null;
};
export type Services_createServiceMutation = {
  variables: Services_createServiceMutation$variables;
  response: Services_createServiceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateServicePayload",
    "kind": "LinkedField",
    "name": "createService",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_createServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Services_createServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "813d76f047995003e99cb5c5881c45da",
    "id": null,
    "metadata": {},
    "name": "Services_createServiceMutation",
    "operationKind": "mutation",
    "text": "mutation Services_createServiceMutation(\n  $input: CreateServiceInput!\n) {\n  createService(input: $input) {\n    obj {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba506cb078f243090405412883b7cc78";

export default node;
