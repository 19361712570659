import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import Header, { ItemImage, BodyRow } from "../../components/Header";
import Section from "../../components/section";
import { View } from "../../components/Themed";
import TightSectionItem from "../../components/section/TightSectionItem";
import ConfirmModal from "../../components/ConfirmModal";
import { BodyContentWrapper } from "../../components/wrappers";

import { RootStackScreenProps } from "../../@types/navigation";
import { getNavigationBack } from "../../utils/navigation";
import userAtom from "../../atoms/persisted/user";
import useUserServices from "../../hooks/useUserServices";
import refetchKeyAtom from "../../atoms/refetchKey";

const Ads = ({ navigation }: RootStackScreenProps<"Ads">) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const user = useRecoilValue(userAtom);
  const refetchKey = useRecoilValue(refetchKeyAtom);
  const { t } = useTranslation();

  const {
    data: services,
    isLoading: loading,
    refetch,
  } = useUserServices({
    active: false,
  });

  useEffect(() => {
    void refetch();
  }, [refetch, refetchKey]);

  return (
    <Header
      centerTitle={t("ads.header")}
      leftAction={() => getNavigationBack(navigation)}
      loadingBody={loading}
      body={
        <BodyContentWrapper>
          <Section
            data={services}
            sectionItem={TightSectionItem}
            sectionTitle={t("ads.inactiveAds")}
            onButtonPress={({ id }) => navigation.navigate("EditAd", { id })}
            onPress={({ id }) => navigation.navigate("DetailAd", { id })}
          />
          <ConfirmModal
            visible={modalVisible}
            setVisible={setModalVisible}
            title={t("detail.modal.active.title")}
            description={t("detail.modal.active.description")}
            ok={t("general.yes")}
            okAction={() => setModalVisible(false)}
            nok={t("general.no")}
            nokAction={() => setModalVisible(false)}
          />
        </BodyContentWrapper>
      }
      bodyOverflow="visible"
      bodyHeader={
        <>
          <BodyRow height={50} marginBottom={20}>
            <View style={{ flex: 1 }}>
              <ItemImage
                source={{
                  uri: user.image,
                }}
              />
            </View>
          </BodyRow>
        </>
      }
    />
  );
};

export default Ads;
