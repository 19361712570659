import React, { useState, forwardRef } from "react";
import moment from "moment";
import { TextInput } from "react-native";
import { useRecoilValue } from "recoil";
import MaskInput from "react-native-mask-input";

import { useTranslation } from "react-i18next";
import ElementWrapper from "../ElementWrapper";
import { themeColors } from "../../atoms/persisted/theme";
import { MaskedInputProps } from "./types";
import { DATE_DDMMYYYY, TIME_HHMM } from "./utils";
import Text from "../Text";

type MaskedFormInputProps = MaskedInputProps & {
  value: string;
  onChange: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  labelColor?: string;
  error?: string;
};

const MaskedFormInput = forwardRef<TextInput, MaskedFormInputProps>(
  (
    {
      value,
      onChange,
      label,
      labelColor,
      type,
      setError,
      error,
      onBlur,
      onFocus,
    },
    ref
  ) => {
    const theme = useRecoilValue(themeColors);
    const [borderColor, setBorderColor] = useState(theme.transparent);
    const { t } = useTranslation();

    const getMask = (maskType: string) => {
      if (maskType === "time") {
        return {
          mask: TIME_HHMM,
          format: "DD.MM.YYYY HH:mm",
          prefix: "11.11.1111 ",
        };
      }
      return { mask: DATE_DDMMYYYY, format: "DD.MM.YYYY", prefix: "" };
    };

    return (
      <>
        <ElementWrapper
          title={label}
          titleColor={labelColor}
          border={`1px solid ${borderColor}`}
        >
          <MaskInput
            ref={ref}
            value={value}
            onChangeText={(masked) => {
              if (type === "time") {
                if (masked.length >= 5) {
                  const m = moment(masked, ["HH:mm"]);
                  if (m.toString() === "Invalid date") {
                    onChange("");
                  } else {
                    onChange(masked);
                  }
                } else {
                  onChange(masked);
                }
              } else {
                onChange(masked);
              }
            }}
            mask={getMask(type).mask}
            style={{
              color: theme.text,
              width: "100%",
              textAlign: "center",

              // HACK: actually exists
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              outlineStyle: "none",
            }}
            keyboardType="number-pad"
            onFocus={() => {
              onFocus?.();
              setBorderColor(theme.border);
            }}
            onBlur={() => {
              onBlur?.();
              const validatedValue = moment(
                `${getMask(type).prefix}${value}`,
                getMask(type).format,
                true
              );
              if (value && !validatedValue.isValid()) {
                setBorderColor(theme.error);
                setError(t("form.invalidField"));
              } else {
                setBorderColor(theme.transparent);
              }
            }}
          />
        </ElementWrapper>
        <Text
          size="12px"
          textColor={theme.error}
          display={!!error}
          textAlign="left"
          width="100%"
        >
          {error ?? ""}
        </Text>
      </>
    );
  }
);

export default MaskedFormInput;
