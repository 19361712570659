import React, { FC, useState, useEffect } from "react";
import {
  ActivityIndicator,
  DevSettings,
  Dimensions,
  Platform,
} from "react-native";
import s from "styled-components/native";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { themeColors } from "../atoms/persisted/theme";
import { radiusSizes } from "../constants/Sizes";

import Text from "./Text";
import Button from "./Button";
import { View } from "./Themed";

const windowHeight = Dimensions.get("window").height;

const LoadingContainer = s(View)<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  align-items: center;
  justify-content: center;
  zIndex: 1;
`;

type Props = {
  visible: boolean;
};

const LoadingComponent: FC<Props> = (props: Props) => {
  const [serverDown, setServerDown] = useState(false);
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();
  useEffect(() => {
    setServerDown(false);
    const timer = setTimeout(() => {
      setServerDown(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
      setServerDown(false);
    };
  }, [props.visible]);

  return props.visible ? (
    <LoadingContainer height={windowHeight} background={theme.background}>
      <ActivityIndicator size="large" color={theme.secondary} />
      {serverDown && (
        <>
          <Text margin="20px">{t("general.serverDown")}</Text>
          <Button
            justifyContent="center"
            borderRadius={radiusSizes.smallRadius}
            background={theme.secondary}
            width="50%"
            onPress={() =>
              Platform.OS === "web"
                ? window.location.reload()
                : void DevSettings.reload()
            }
          >
            <Text size="21px" font="bold" textColor={theme.secondaryText}>
              {t("general.serverDownButton")}
            </Text>
          </Button>
        </>
      )}
    </LoadingContainer>
  ) : null;
};
export default LoadingComponent;
