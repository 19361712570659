import React from "react";

import { useRecoilValue } from "recoil";
import { Node } from "../../@types/tree";

import { View, TouchableOpacity } from "../Themed";
import Text from "../Text";
import { RowWrapper } from "../wrappers";
import Icon from "../Icon";

import { themeColors } from "../../atoms/persisted/theme";

type NodeProps = {
  item: Node<string>;
  hasNodes: boolean;
  level: number;
  onToggle: () => void;
  selected: boolean;
  onCheck: (id: string) => void;
  selectedCategories: string[];
};

const NodeContainer = ({
  item,
  hasNodes,
  level,
  selected,
  selectedCategories,
  onToggle,
  onCheck,
}: NodeProps) => {
  const theme = useRecoilValue(themeColors);
  const checked = selectedCategories?.some((a) => a === item.id) ?? false;
  return (
    <TouchableOpacity
      onPress={() => onCheck(item.id)}
      background={checked ? theme.primary : theme.white}
      style={{
        marginLeft: level * 22,
        borderRadius: 10,
        margin: 5,
        flex: 1,
      }}
    >
      <RowWrapper>
        {hasNodes && (
          <TouchableOpacity
            style={{
              height: "100%",
              width: 45,
              padding: 10,
            }}
            onPress={onToggle}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                type="entypo"
                name={selected ? "chevron-thin-down" : "chevron-thin-right"}
                size={24}
                color={checked ? theme.white : theme.text}
              />
            </View>
          </TouchableOpacity>
        )}
        <View style={{ flex: 1, padding: 10 }}>
          <View style={{ alignContent: "flex-start", alignSelf: "flex-start" }}>
            <Text
              font="regular"
              size="13px"
              textColor={checked ? theme.white : theme.text}
            >
              {item.title}
            </Text>
          </View>
        </View>
        <View style={{ padding: 10 }}>
          <Icon
            type="antDesign"
            name="checksquare"
            size={24}
            color={checked ? theme.secondary : theme.secondarySurface}
          />
        </View>
      </RowWrapper>
    </TouchableOpacity>
  );
};

export default NodeContainer;
