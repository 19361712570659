import React, { ReactNode } from "react";
import s from "styled-components/native";
import { useRecoilValue } from "recoil";

import { View, TouchableOpacity } from "./Themed";
import Text from "./Text";

import { themeColors } from "../atoms/persisted/theme";

const Wrapper = s(View)<{ border?: string; height?: number }>`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-height: 44px;
  ${(props) => (props.border ? `border: ${props.border};` : null)} 
  margin-bottom: 5px;
  ${(props) => (props.height ? `height: ${props.height}px;` : null)} 
`;

const Title = s(Text)`
  margin: 10px 5px;
  text-align: left;
  font-family: bold;
  font-size: 15px;
`;

type Props = {
  children: ReactNode;
  title?: string;
  titleColor?: string;
  border?: string;
  height?: number;
  background?: string;
  error?: string;
  action?: () => void;
  actionTitle?: string;
};

const ElementWrapper = (props: Props) => {
  const theme = useRecoilValue(themeColors);

  return (
    <>
      <View style={{ display: "flex", flexDirection: "row" }}>
        {props.title && (
          <Title textColor={props.titleColor ?? theme.highlightText}>
            {props.title}
          </Title>
        )}
        {props.action && props.actionTitle && (
          <TouchableOpacity onPress={() => props.action?.()}>
            <Text
              size="12px"
              margin="10px 5px"
              textColor={theme.highlightText}
              style={{ textDecorationLine: "underline" }}
              display
              textAlign="right"
            >
              {props.actionTitle.toLocaleLowerCase() ?? ""}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      <Wrapper
        background={props.background ?? theme.primarySurface}
        border={props.border}
        height={props.height}
      >
        {props.children}
      </Wrapper>
      <Text
        size="12px"
        textColor={theme.error}
        display={!!props.error}
        textAlign="left"
        width="100%"
      >
        {props.error ?? ""}
      </Text>
    </>
  );
};

export default ElementWrapper;
