import { Alert, Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";
import i18n from "i18next";
import { ImagePickerResult } from "expo-image-picker/build/ImagePicker.types";

export type ImageItem = {
  key: number;
  url: string;
  timestamp?: string;
};

const progressOpenCamera = async () => {
  // Ask the user for the permission to access the camera
  if (Platform.OS !== "web") {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      Alert.alert(i18n.t("general.noImagePicker"));
    } else {
      const result: ImagePickerResult = await ImagePicker.launchCameraAsync();
      if (!result.cancelled) {
        return result;
      }
    }
  }
  return null;
};

const ImagePickerOptions: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: true,
  aspect: [4, 3],
  quality: 1,
  base64: true,
  allowsMultipleSelection: false,
};

const pickImage = async () => {
  if (Platform.OS !== "web") {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      Alert.alert(i18n.t("general.noImagePicker"));
    } else {
      const result: ImagePickerResult =
        await ImagePicker.launchImageLibraryAsync(ImagePickerOptions);
      if (!result.cancelled) {
        return result;
      }
    }
  } else {
    const result: ImagePickerResult = await ImagePicker.launchImageLibraryAsync(
      ImagePickerOptions
    );
    if (!result.cancelled) {
      return result;
    }
  }

  return null;
};

export { progressOpenCamera, pickImage };
