import { TextInput } from "react-native";
import { atomFamily, selectorFamily } from "recoil";

export type RefValue = Record<string, TextInput>;

const refAtoms = atomFamily<RefValue, string>({
  key: "refState",
  default: {} as RefValue,
  dangerouslyAllowMutability: true,
});

export const formRefStatesAtom = selectorFamily<RefValue, string>({
  key: "refStateSelector",
  get:
    (refKey) =>
    ({ get }) =>
      get(refAtoms(refKey)),
  set:
    (refKey) =>
    ({ set, get }, newValue) => {
      const newValues = {
        ...get(refAtoms(refKey)),
        ...newValue,
      };

      set(refAtoms(refKey), newValues);
    },

  dangerouslyAllowMutability: true,
});
