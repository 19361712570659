import React, { useCallback } from "react";
import {
  ImageSourcePropType,
  Image,
  Platform,
  // ImageBackground,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import s from "styled-components/native";
// import Constants from "expo-constants";
import { useRecoilValue } from "recoil";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { View, TouchableOpacity } from "./Themed";
import Text from "./Text";
import Icon from "./Icon";

import { themeColors } from "../atoms/persisted/theme";

import { Wrapper } from "./wrappers";
import Switcher from "./Switcher";

import { radiusSizes } from "../constants/Sizes";
import { cutString } from "../services/text";

// const HeaderWrapper = s(ImageBackground)`
//   padding-vertical: ${
//     Platform.OS === "ios" ? Constants.statusBarHeight + 5 : 15
//   }px;
//   justify-content: center;
//   align-items: center;
//   min-height: ${Platform.OS === "android" ? 130 : 160}px;
//   max-height: 250px;
// `;

const HeaderWrapperWeb = s(View)<{ height: number }>`
  padding-vertical:  : 15px;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  max-height: ${(props) =>
    props.height ? `${props.height.toString()}px` : "250px"};
`;

const TopWrapper = s(View)<{ paddingBottom?: number }>`
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  ${(props) =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : null}
`;

const BottomWrapper = s(View)`
  padding-top: 30px;
  ${Platform.OS !== "ios" ? "padding-bottom: 30px;" : null}
  flex-direction: row;
`;

const CenterWrapper = s(View)`
  flex: 2;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-vertical: 10px;
`;

const LeftWrapper = s(View)`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
`;

const RightWrapper = s(View)`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TouchableWrapper = s(TouchableOpacity)<{ alignItems: string }>`
  min-width: 30px;
  align-items: ${(props) => props.alignItems};
  padding: 10px 15px;
`;

const LeftTitleWrapper = s(View)`
  flex-direction: row;
`;

const CenterIcon = s(Image)`
  margin-right: 10px;
  resize-mode: contain;
  height: 26px;
`;

const Body = s(View)<{ bodyOverflow?: string }>`
  max-width: 1200px;
  ${Platform.OS !== "web" ? "height: 100%;" : "flex: 1;"}
  ${Platform.OS !== "web" ? "top: -30px;" : null}
  ${Platform.OS !== "web" ? "position: relative;" : null}
  width: 100%;
  borderTopLeftRadius: 30px;
  borderTopRightRadius: 30px;
  align-self: center;
  ${(props) =>
    props.bodyOverflow
      ? `overflow: ${props.bodyOverflow};`
      : `overflow: hidden;`}
`;

const ScrollViewWrapper = s(ScrollView)`
  padding-horizontal: 15px;
`;

const BodyHeader = s(View)`
  padding-top: 15px;
  padding-bottom: 10px;
  padding-horizontal: 15px;
`;

export const RangeWrapper = s(View)`
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const RangeItem = s(View)`
  flex: 2;
  margin: 10px;
  flex-direction: row;
  align-items: center;
`;

export const RangeSubItem = s(View)`
  flex: 2;
`;

export const Tag = s(View)<{ marginLeft?: number }>`
  justify-content: center;
  align-items: center;
  borderRadius: 24px;
  padding: 0 10px;
  ${(props) =>
    props.marginLeft ? `margin-left: ${props.marginLeft}px;` : null}
  max-width: 40%;
`;

export const Button = s(View)`
  justify-content: center;
  align-items: center;
  borderRadius: 10px;
  width: 35px;
  height: 35px;
`;

export const Location = s(View)`
  flex: 1;
  alignItems: center;
  justify-content: flex-end;
  flexDirection: row;
`;

export const ItemImage = s(Image)`
position: relative;
top: -60px;
border-radius: ${radiusSizes.basicRadius}px;
height: 100px;
width: 100px;
resize-mode: cover;
`;

export const Row = s(View)<{ padding?: number }>`
  alignItems: center;
  flexDirection: row;
  ${(props) => (props.padding ? `padding: ${props.padding}px;` : null)}
  width: 100%;
`;

export const BodyRow = s(View)<{
  height?: number;
  marginBottom?: number;
  justifyContent?: string;
  alignItems?: string;
}>`
  flex-direction: row;
  ${(props) => (props.height ? `height: ${props.height}px;` : null)} 
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : null}
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : null)} 
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

const HeaderWrapperNative = s(View)<{ height: number }>`
  padding-vertical: 15px;
  justify-content: center;
  align-items: center;
  min-height: ${(props) =>
    props.height ? `${props.height.toString()}px` : "200px"};
`;

type HeaderProps = {
  rightAction?: (() => Promise<void>) | (() => void) | null;
  leftAction?: () => void;
  centerTitle?: string;
  rightIcon?: ImageSourcePropType;
  centerIcon?: string;
  leftTitle?: string;
  rightTitle?: string;
  rightTitleColor?: string;
  rightElement?: () => JSX.Element;
  body?: JSX.Element;
  bodyHeader?: JSX.Element;
  headerBottom?: JSX.Element;
  switcher?: boolean;
  scrollingBody?: boolean;
  bodyOverflow?: "hidden" | "visible" | "scroll";
  loadingBody?: boolean;
  loadingHeader?: boolean;
  heightHeader?: number;
};

const Header = ({
  centerTitle,
  centerIcon,
  rightIcon,
  rightElement,
  rightAction,
  leftAction,
  leftTitle,
  rightTitle,
  rightTitleColor,
  body,
  bodyHeader,
  headerBottom,
  heightHeader,
  switcher,
  scrollingBody,
  bodyOverflow,
  loadingBody,
  loadingHeader,
}: HeaderProps) => {
  const theme = useRecoilValue(themeColors);

  // const motionProps = useMemo(
  //   () =>
  //     Platform.OS !== "web" && typeof loadingBody === "boolean"
  //       ? {
  //           from: {
  //             opacity: 0,
  //           },
  //           animate: {
  //             opacity: 1,
  //           },
  //           exit: {
  //             opacity: 0,
  //           },
  //         }
  //       : {},
  //   [loadingBody]
  // );

  const getBody = (bodyElement: JSX.Element) => {
    if (loadingBody)
      return <ActivityIndicator size="large" color={theme.secondary} />;

    if (Platform.OS !== "web") {
      if (scrollingBody) {
        return (
          <ScrollView
            contentContainerStyle={{
              padding: 10,
              paddingTop: 10,
              paddingBottom: 200,
            }}
          >
            {bodyElement}
          </ScrollView>
        );
      }
      return bodyElement;
    }

    return (
      <ScrollViewWrapper
        contentContainerStyle={{
          paddingTop: 10,
          paddingBottom: 50,
        }}
      >
        {bodyElement}
      </ScrollViewWrapper>
    );
  };

  const getHeader = useCallback(() => {
    if (loadingHeader) return null;
    return bodyHeader ? (
      <View key="header">
        <BodyHeader>{bodyHeader}</BodyHeader>
      </View>
    ) : null;
  }, [bodyHeader, loadingHeader]);

  const renderContent = () => (
    <>
      <TopWrapper paddingBottom={bodyOverflow === "visible" ? 20 : null}>
        <LeftWrapper>
          {leftAction && (
            <TouchableWrapper
              alignItems="flex-start"
              onPress={() => leftAction && void leftAction()}
            >
              <LeftTitleWrapper>
                <Icon
                  type="simpleLineIcons"
                  name="arrow-left"
                  size={20}
                  color={theme.primaryText}
                />
                <Text // numberOfLines={1}
                  size="18px"
                  font="regular"
                  lineHeight="21px"
                  textColor={rightTitleColor ?? theme.primaryText}
                  padding="0 0 0 5px"
                >
                  {leftTitle}
                </Text>
              </LeftTitleWrapper>
            </TouchableWrapper>
          )}
        </LeftWrapper>
        <CenterWrapper>
          {centerIcon && <CenterIcon source={{ uri: centerIcon }} />}
          {centerTitle && (
            <Text
              size="23px"
              font="regular"
              lineHeight="26px"
              textColor={theme.primaryText}
              position="relative"
            >
              {cutString(centerTitle, 25)}
            </Text>
          )}
        </CenterWrapper>
        <RightWrapper>
          <TouchableWrapper
            alignItems="flex-end"
            onPress={() => rightAction && void rightAction()}
          >
            {rightTitle ? (
              <Text // numberOfLines={1}
                size="18px"
                font="regular"
                lineHeight="21px"
                textColor={rightTitleColor ?? theme.primaryText}
              >
                {rightTitle}
              </Text>
            ) : (
              <>
                {rightIcon && <Image source={rightIcon} />}
                {rightElement?.()}
              </>
            )}
          </TouchableWrapper>
        </RightWrapper>
      </TopWrapper>
      {switcher ? (
        <BottomWrapper>
          <Switcher />
        </BottomWrapper>
      ) : null}
      {headerBottom ? <BottomWrapper>{headerBottom}</BottomWrapper> : null}
    </>
  );

  return (
    <Wrapper background={theme.primary}>
      {Platform.OS === "web" ? (
        <HeaderWrapperWeb height={heightHeader}>
          {renderContent()}
        </HeaderWrapperWeb>
      ) : (
        // <HeaderWrapper
        //   source={Panorama}
        //   resizeMode={Platform.OS === "android" ? "cover" : "repeat"}
        // >
        <HeaderWrapperNative height={heightHeader}>
          {renderContent()}
        </HeaderWrapperNative>
        // </HeaderWrapper>
      )}
      <Body background={theme.background} bodyOverflow={bodyOverflow}>
        {getHeader()}
        {getBody(body)}
      </Body>
    </Wrapper>
  );
};

export default Header;
