/**
 * @generated SignedSource<<11a90d95ecb1c190a179d03e6ede345b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceCurrency = "CZK" | "EUR";
export type ServiceOption = "PROVIDE" | "REQUEST";
export type ServiceReward = "FREE" | "PRICE" | "EXCHANGE";
export type ServiceStatus = "PENDING" | "ACTIVE" | "REJECTED" | "PENDING_REVIEW" | "RESOLVED_REPORT";
export type Services_serviceQuery$variables = {
  id: string;
};
export type Services_serviceQuery$data = {
  readonly service: {
    readonly id: string;
    readonly user: {
      readonly id: string;
      readonly email: string;
      readonly fullName: string | null;
      readonly phoneNumber: string | null;
      readonly image: string | null;
      readonly showPhoneNumber: boolean;
    };
    readonly title: string;
    readonly description: string | null;
    readonly days: ReadonlyArray<string | null>;
    readonly categories: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly title: string;
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly timeTo: any | null;
    readonly timeFrom: any | null;
    readonly startDate: any | null;
    readonly endDate: any | null;
    readonly radius: number;
    readonly reward: ServiceReward | null;
    readonly price: any | null;
    readonly currency: ServiceCurrency | null;
    readonly coordinatesName: string | null;
    readonly coordinates: any | null;
    readonly option: ServiceOption;
    readonly isActive: boolean;
    readonly distance: any | null;
    readonly status: ServiceStatus;
  } | null;
};
export type Services_serviceQuery = {
  variables: Services_serviceQuery$variables;
  response: Services_serviceQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ServiceType",
    "kind": "LinkedField",
    "name": "service",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showPhoneNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "days",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceCategoryTypeConnection",
        "kind": "LinkedField",
        "name": "categories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceCategoryTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceCategoryType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeTo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeFrom",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "radius",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reward",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coordinatesName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coordinates",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "option",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "distance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_serviceQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Services_serviceQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6dd90acf758990cdac684c4c2efdf310",
    "id": null,
    "metadata": {},
    "name": "Services_serviceQuery",
    "operationKind": "query",
    "text": "query Services_serviceQuery(\n  $id: ID!\n) {\n  service(id: $id) {\n    id\n    user {\n      id\n      email\n      fullName\n      phoneNumber\n      image\n      showPhoneNumber\n    }\n    title\n    description\n    days\n    categories {\n      edges {\n        node {\n          title\n          id\n        }\n      }\n    }\n    timeTo\n    timeFrom\n    startDate\n    endDate\n    radius\n    reward\n    price\n    currency\n    coordinatesName\n    coordinates\n    option\n    isActive\n    distance\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "308649f2342f361c04478b6a7849372d";

export default node;
