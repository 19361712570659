import { atom, selector } from "recoil";

import { ColorSchemeChoices } from "../../types/graphql";
import persistAtom from "./utils";

const general = {
  background: "#F6F7FF",
  primarySurface: "#FFFFFF",
  secondarySurface: "#DFE4FA",
  successSurface: "#DFFBF0",
  text: "#525C7C",
  error: "#f24730",
  highlightText: "#161F3D",
  successText: "#00EA93",
  border: "#AAB0CC",
  black: "#161F3D",
  white: "#FFFFFF",
  transparent: "transparent",
  darkOpacity: "rgba(0, 0, 0, 0.3)",
  input: {
    text: "#161F3D",
    placeholder: "#AAB0CC",
    border: "#AAB0CC",
    background: "#FFFFFF",
  },
  title: "#161F3D",
};

export const Colors = {
  BLACK: {
    ...general,
    primary: "#161F3D",
    primaryOpacity: "rgba(22, 31, 61, 0.1)",
    secondary: "#FF4D00",
    secondaryDark: "#e64500",
    secondaryOpacity: "rgba(255, 77, 0, 0.1)",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF",
  },
  BLUE: {
    ...general,
    primary: "#2A54DD",
    primaryOpacity: "rgba(42, 84, 221, 0.3)",
    secondary: "#FF4D00",
    secondaryDark: "#e64500",
    secondaryOpacity: "rgba(255, 77, 0, 0.1)",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF",
  },
  GREEN: {
    ...general,
    primary: "#008066",
    primaryOpacity: "rgba(9, 128, 102, 0.3)",
    secondary: "#17EB82",
    secondaryDark: "#12b866",
    secondaryOpacity: "rgba(23, 235, 130, 0.1)",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF",
  },
  WINE: {
    ...general,
    primary: "#761B4C",
    primaryOpacity: "rgba(118, 27, 76, 0.3)",
    secondary: "#FFBB00",
    secondaryDark: "#ebac00",
    secondaryOpacity: "rgba(255, 187, 0, 0.1)",
    primaryText: "#FFFFFF",
    secondaryText: "#FFFFFF",
  },
};

export type ColorType = typeof Colors[keyof typeof Colors];

const atomKey = "themeAtom";
export const theme = atom<ColorSchemeChoices | null>({
  key: atomKey,
  default: null,
  effects_UNSTABLE: [persistAtom(atomKey)],
});

export const themeColors = selector<ColorType>({
  key: "themeColors",
  get: ({ get }) => {
    const currentTheme = get(theme);
    if (!Colors[currentTheme]) return Colors.BLUE;

    return Colors[currentTheme];
  },
});
