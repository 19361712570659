import React, { useState, useEffect, useMemo, useCallback } from "react";
import { fetchQuery } from "react-relay";
import { Keyboard, View } from "react-native";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { LatLng } from "react-native-maps";
import { uniqueId } from "lodash";
import Header, {
  RangeWrapper,
  RangeItem,
  RangeSubItem,
} from "../../components/Header";
import { BodyContentWrapper } from "../../components/wrappers";
import Slider from "../../components/Slider";
import EditValue from "../../components/EditValue";
import Button from "../../components/Button";
import Text from "../../components/Text";
import ElementWrapper from "../../components/ElementWrapper";
import TreeComponent from "../../components/tree";
import { Input } from "../../components/form/TextInput";

import { themeColors } from "../../atoms/persisted/theme";
import { DEFAULT_RADIUS, filterAtomState } from "../../atoms/filter";

import { RootStackScreenProps } from "../../@types/navigation";
import { radiusSizes } from "../../constants/Sizes";

import { getNavigationBack } from "../../utils/navigation";
import {
  CurrencyChoices,
  DayOfTheWeekChoices,
  ServiceRewardChoices,
} from "../../types/graphql";
import { getCategoriesQuery } from "../../relay/queries/Categories";
import FormInput from "../../components/form";
import { Tree } from "../../@types/tree";
import markersAtom from "./Maps/atoms";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import useGetServicesData from "../ad/serviceData";

type FormValues = {
  startDate?: string;
  endDate?: string;
  timeFrom?: string;
  timeTo?: string;
  priceFrom?: number;
  priceTo?: number;
};

const Filter = ({ navigation }: RootStackScreenProps<"Filter">) => {
  const theme = useRecoilValue(themeColors);
  const [markers, setMarkers] = useRecoilState(markersAtom);
  const { DAYS_DATA, REWARD_DATA } = useGetServicesData();
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useRecoilState(filterAtomState);

  const [categories, setCategories] = useState<Tree<string>>(null);
  const [loading, setLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState<string>("");

  const { environment } = useResettableRelayContext();

  const getCategories = useCallback(async () => {
    const { allServiceCategories: res } = await fetchQuery<{
      response: { allServiceCategories: Tree<string> };
      variables: Record<string, string>;
    }>(environment, getCategoriesQuery, {}).toPromise();

    if (!res) return null;

    return res;
  }, [environment]);

  const { control, handleSubmit, reset, setError } = useForm<FormValues>({
    mode: "onChange",
  });

  const [radius, setRadius] = useState(filter?.radius ?? DEFAULT_RADIUS);
  const [radiusSlider, setRadiusSlider] = useState(
    filter?.radiusSlider ?? false
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<DayOfTheWeekChoices[]>(
    filter?.days ?? []
  );

  const [rewardTypes, setRewardTypes] = useState<ServiceRewardChoices[]>(
    filter ? filter.rewards : ["FREE"]
  );

  const currentMarker = useMemo(
    () => (markers?.length > 0 ? markers[0] : null),
    [markers]
  );

  const refetch = useCallback(async () => {
    // setLoading(true);
    setCategories(await getCategories());
    const { startDate, endDate, timeFrom, timeTo, priceFrom, priceTo } = filter;
    setMarkers([
      {
        coordinate: filter.coordinates,
        title: filter.coordinatesName,
        identifier: uniqueId(),
        radius: (filter?.radius ?? DEFAULT_RADIUS) * 1000,
      },
    ]);
    reset({ startDate, endDate, timeFrom, timeTo, priceFrom, priceTo });
    // setLoading(false);
  }, [filter, getCategories, reset, setMarkers]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  useEffect(() => {
    if (selectedCategories?.length > 0) {
      setCategoriesError("");
    }
  }, [selectedCategories]);

  const currency: CurrencyChoices = useMemo(
    () => (i18n.language === "cs" ? "CZK" : "EUR"),
    [i18n.language]
  );

  const onSubmit = (data: FormValues): void => {
    Keyboard.dismiss();

    if (selectedCategories?.length <= 0) {
      setCategoriesError(t("form.selectCategories"));
      return;
    }
    if (radius <= 0) {
      setCategoriesError(t("form.selectRadius"));
      return;
    }

    const coordinates = currentMarker
      ? (currentMarker.coordinate as LatLng)
      : filter.coordinates;
    const coordinatesName = currentMarker
      ? currentMarker.title
      : filter.coordinatesName;

    setFilter({
      coordinates,
      coordinatesName,
      radius,
      radiusSlider,
      currency: data.priceFrom || data.priceTo ? currency : undefined,
      rewards: rewardTypes?.length > 0 ? rewardTypes : undefined,
      days:
        selectedDays?.length > 0
          ? selectedDays.filter((item) => (item as string) !== "EVERYDAY")
          : undefined,
      categories:
        selectedCategories?.length > 0 ? selectedCategories : undefined,
      ...data,
    });

    setMarkers([]);
    getNavigationBack(navigation);
  };

  const checkRadius = (s: string) => {
    setRadius(Number(s));
    if (Number(s) > 100) {
      setRadius(100);
    }
    if (Number(s) < 1) {
      setRadius(0);
    }
    if (Number(s).toString() === "NaN") {
      setRadius(0);
    }
  };

  return (
    <Header
      centerTitle={t("filter.header")}
      switcher
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      loadingBody={loading}
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <ElementWrapper
              title={`${t("filter.adType")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </ElementWrapper>
            <ElementWrapper title={`${t("filter.address")}:`}>
              <EditValue
                value={currentMarker?.title ?? filter?.coordinatesName ?? ""}
                buttonTitle={t("filter.change").toLocaleLowerCase()}
                onPress={() =>
                  navigation.navigate("Map", {
                    markerRadius: radius,
                    maxMarkers: 1,
                  })
                }
              />
            </ElementWrapper>
            <ElementWrapper
              title={`${t("filter.range")}:`}
              actionTitle={
                radiusSlider ? t("filter.changeType") : t("filter.change")
              }
              action={() => setRadiusSlider(!radiusSlider)}
            >
              {radiusSlider ? (
                <Slider
                  values={radius}
                  setValues={(num: number) => {
                    setRadius(num);
                    setMarkers((prev) =>
                      prev.map((prevState) => ({
                        ...prevState,
                        radius: num * 1000,
                      }))
                    );
                  }}
                  unit="km"
                />
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text size="13px">{t("filter.range")}</Text>
                  <Input
                    color={theme.text}
                    value={radius.toString()}
                    onChangeText={(text) => checkRadius(text)}
                    placeholder=""
                    placeholderTextColor={theme.input.placeholder}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    width="55px"
                  />
                  <Text size="13px">km</Text>
                </View>
              )}
            </ElementWrapper>
            <ElementWrapper
              title={`${t("filter.reward")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={REWARD_DATA}
                selectedCategories={rewardTypes}
                setSelectedCategories={setRewardTypes}
              />
            </ElementWrapper>
            {rewardTypes?.includes("PRICE") && (
              <RangeWrapper>
                <Text
                  flex="1"
                  font="bold"
                  size="15px"
                  textColor={theme.highlightText}
                  width="100%"
                  textAlign="left"
                >
                  {`${t("filter.price")}:`}
                </Text>
                <RangeItem>
                  <Text flex="1" size="13px">{`${t(
                    "filter.from"
                  ).toLocaleLowerCase()}:`}</Text>
                  <RangeSubItem>
                    <FormInput
                      name="priceFrom"
                      variant="text"
                      control={control}
                      placeholder={currency}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    />
                  </RangeSubItem>
                </RangeItem>
                <RangeItem>
                  <Text flex="1" size="13px">{`${t(
                    "filter.to"
                  ).toLocaleLowerCase()}:`}</Text>
                  <RangeSubItem>
                    <FormInput
                      name="priceTo"
                      variant="text"
                      control={control}
                      placeholder={currency}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    />
                  </RangeSubItem>
                </RangeItem>
              </RangeWrapper>
            )}
            <ElementWrapper
              title={`${t("filter.days")}:`}
              background={theme.transparent}
            >
              <TreeComponent
                data={DAYS_DATA}
                selectedCategories={selectedDays}
                setSelectedCategories={setSelectedDays}
              />
            </ElementWrapper>

            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                width="100%"
                textAlign="left"
              >
                {`${t("filter.time")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeFrom"
                    variant="masked"
                    setError={(message) => setError("timeFrom", { message })}
                    type="time"
                    control={control}
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeTo"
                    setError={(message) => setError("timeTo", { message })}
                    variant="masked"
                    type="time"
                    control={control}
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                textAlign="left"
              >
                {`${t("filter.date")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="startDate"
                    variant="masked"
                    type="date"
                    setError={(message) => setError("startDate", { message })}
                    control={control}
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="endDate"
                    setError={(message) => setError("endDate", { message })}
                    variant="masked"
                    type="date"
                    control={control}
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <Text
              size="12px"
              textColor={theme.error}
              display={!!categoriesError}
              textAlign="left"
              width="100%"
            >
              {categoriesError ?? ""}
            </Text>
            <Button
              width="100%"
              margin="10px 0 0 0"
              justifyContent="center"
              borderRadius={radiusSizes.smallRadius}
              background={categoriesError ? theme.error : theme.secondary}
              onPress={handleSubmit(onSubmit)}
            >
              <Text size="21px" font="regular" textColor={theme.secondaryText}>
                {t("filter.filter")}
              </Text>
            </Button>
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default Filter;
