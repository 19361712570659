/**
 * @generated SignedSource<<e8f39f0cef2bbfd743108ee3f781b170>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ColorSchemeChoices = "BLACK" | "BLUE" | "GREEN" | "WINE";
export type LanguageChoices = "CS" | "SK" | "DE" | "EN";
export type UserColorScheme = "BLACK" | "BLUE" | "GREEN" | "WINE";
export type UserLanguage = "CS" | "SK" | "DE" | "EN";
export type UpdateUserInput = {
  id: any;
  email?: string | null;
  password?: string | null;
  fullName?: string | null;
  phoneNumber?: string | null;
  showPhoneNumber?: boolean | null;
  overview?: string | null;
  image?: any | null;
  language?: LanguageChoices | null;
  colorScheme?: ColorSchemeChoices | null;
  clientMutationId?: string | null;
};
export type User_updateUserMutation$variables = {
  input: UpdateUserInput;
};
export type User_updateUserMutation$data = {
  readonly updateUser: {
    readonly obj: {
      readonly id: string;
      readonly email: string;
      readonly fullName: string | null;
      readonly colorScheme: UserColorScheme;
      readonly language: UserLanguage;
      readonly phoneNumber: string | null;
      readonly overview: string | null;
      readonly showPhoneNumber: boolean;
      readonly image: string | null;
    } | null;
  } | null;
};
export type User_updateUserMutation = {
  variables: User_updateUserMutation$variables;
  response: User_updateUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "obj",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "colorScheme",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overview",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_updateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_updateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5cea49004017d472521deede91a72c2",
    "id": null,
    "metadata": {},
    "name": "User_updateUserMutation",
    "operationKind": "mutation",
    "text": "mutation User_updateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    obj {\n      id\n      email\n      fullName\n      colorScheme\n      language\n      phoneNumber\n      overview\n      showPhoneNumber\n      image\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bb6e489a8ffb5c5ed24bcfd265e240a";

export default node;
