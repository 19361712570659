import { ReactNativeFile } from "extract-files";
import mime from "mime-types";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";

type ImageResultType =
  | {
      cancelled: true;
    }
  | ({
      cancelled: false;
    } & ImageInfo);

const getReactNativeFile = (result: ImageResultType) => {
  const r = result as ImageInfo;

  const type = mime.lookup(r.uri ?? "") as string;
  const name = r.uri.substring(r.uri.lastIndexOf("/") + 1);

  const file = new ReactNativeFile({
    uri: r.uri,
    name,
    type,
  });

  return file;
};

// eslint-disable-next-line import/prefer-default-export
export { getReactNativeFile };
