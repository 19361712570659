import React from "react";

import {
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialIcons,
  SimpleLineIcons,
  Entypo,
  AntDesign,
} from "@expo/vector-icons";

type Props = {
  type: string;
  color: string;
  name: string;
  size: number;
};

const Icon = (props: Props) => {
  if (props.type === "ionicons") {
    return (
      <Ionicons
        name={props.name as keyof typeof Ionicons.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "materialCommunityIcons") {
    return (
      <MaterialCommunityIcons
        name={props.name as keyof typeof MaterialCommunityIcons.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "fontAwesome") {
    return (
      <FontAwesome
        name={props.name as keyof typeof FontAwesome.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "fontAwesome5") {
    return (
      <FontAwesome5 name={props.name} size={props.size} color={props.color} />
    );
  }

  if (props.type === "materialIcons") {
    return (
      <MaterialIcons
        name={props.name as keyof typeof MaterialIcons.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "simpleLineIcons") {
    return (
      <SimpleLineIcons
        name={props.name as keyof typeof SimpleLineIcons.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "entypo") {
    return (
      <Entypo
        name={props.name as keyof typeof Entypo.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  if (props.type === "antDesign") {
    return (
      <AntDesign
        name={props.name as keyof typeof AntDesign.glyphMap}
        size={props.size}
        color={props.color}
      />
    );
  }

  return null;
};

export default Icon;
