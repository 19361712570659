import React, { useState, useEffect, useMemo, useCallback } from "react";
import { fetchQuery } from "react-relay";
import { Keyboard } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Location from "expo-location";
import { LatLng } from "react-native-maps";
import { uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import moment from "moment";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import Header, {
  RangeWrapper,
  RangeItem,
  RangeSubItem,
} from "../../components/Header";
import { BodyContentWrapper } from "../../components/wrappers";
import Slider from "../../components/Slider";
import EditValue from "../../components/EditValue";
import Button from "../../components/Button";
import Text from "../../components/Text";
import ElementWrapper from "../../components/ElementWrapper";
import TreeComponent from "../../components/tree";

import { RootStackScreenProps } from "../../@types/navigation";

import { themeColors } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

import { getNavigationBack } from "../../utils/navigation";

import markersAtom from "../home/Maps/atoms";
import FormInput from "../../components/form";
import {
  CurrencyChoices,
  DayOfTheWeekChoices,
  ServiceRewardChoices,
} from "../../types/graphql";
import { useCreateService } from "../../services/services";
import switcherAtom from "../../atoms/switcher";
import { getCategoriesQuery } from "../../relay/queries/Categories";
import { Tree } from "../../@types/tree";
import { DEFAULT_RADIUS } from "../../atoms/filter";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import useGetServicesData from "./serviceData";
import refetchKeyAtom from "../../atoms/refetchKey";
import getAddressFromCoords from "../../hooks/useGetAddress";

type FormValues = {
  title: string;
  price: string;
  startDate: string;
  endDate: string;
  timeFrom: string;
  timeTo: string;
  description: string;
};

const AddAd = ({ navigation }: RootStackScreenProps<"AddAd">) => {
  const [markers, setMarkers] = useRecoilState(markersAtom);
  const marker = useMemo(() => markers[0] ?? null, [markers]);
  const [sliderValue, setSliderValue] = useState(DEFAULT_RADIUS);
  const { DAYS_DATA, REWARD_DATA } = useGetServicesData();
  const { t, i18n } = useTranslation();

  const theme = useRecoilValue(themeColors);
  const switcher = useRecoilValue(switcherAtom);

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const [rewardTypes, setRewardTypes] = useState<ServiceRewardChoices[]>([]);

  const [selectedDays, setSelectedDays] = useState<DayOfTheWeekChoices[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [selectedCategoriesError, setSelectedCategoriesError] = useState("");
  const [selectedDaysError, setSelectedDaysError] = useState("");
  const [rewardTypesError, setRewardTypesError] = useState("");

  const [categories, setCategories] = useState<Tree<string>>(null);
  const [loading, setLoading] = useState(false);

  const { environment } = useResettableRelayContext();

  const [, setRefetchKey] = useRecoilState(refetchKeyAtom);

  const getCategories = useCallback(async () => {
    const { allServiceCategories: res } = await fetchQuery<{
      response: { allServiceCategories: Tree<string> };
      variables: Record<string, string>;
    }>(environment, getCategoriesQuery, {}).toPromise();

    if (!res) return null;

    return res;
  }, [environment]);

  const [createService] = useCreateService({
    onCompleted: (res) => {
      const { id } = res.createService.obj;

      if (id) navigation.navigate("DetailAd", { id });
      setRefetchKey((p) => p + 1);
      setMarkers([]);
      reset({});
    },
    onError: (e) => {
      console.warn(e);
    },
  });

  const getCurrentPosition = useCallback(async () => {
    try {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setLoading(false);
        return;
      }

      const location = await Location.getCurrentPositionAsync();

      const coords: LatLng = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      };

      const name = await getAddressFromCoords({ coords, skipCheck: true });

      setMarkers([
        {
          title: name ?? "",
          coordinate: coords,
          identifier: uniqueId(),
          radius: 1,
        },
      ]);
    } catch {
      setLoading(false);
    }
  }, [setMarkers]);

  const refetch = useCallback(async () => {
    setLoading(true);
    const cat = await getCategories();

    setCategories(cat);
    setLoading(false);
  }, [getCategories]);

  useEffect(() => {
    void refetch();
  }, [refetch]);

  useEffect(() => {
    void getCurrentPosition();
  }, [getCurrentPosition]);

  const currency: CurrencyChoices = useMemo(
    () => (i18n.language === "cs" ? "CZK" : "EUR"),
    [i18n.language]
  );

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setSelectedCategoriesError("");
    }
  }, [selectedCategories, t]);

  useEffect(() => {
    if (rewardTypes.length > 0) {
      setRewardTypesError("");
    }
  }, [rewardTypes, t]);

  useEffect(() => {
    if (selectedDays.length > 0) {
      setSelectedDaysError("");
    }
  }, [selectedDays, t]);

  const submit = (data: FormValues) => {
    Keyboard.dismiss();
    const { endDate, startDate, timeFrom, timeTo, ...submitData } = data;

    const sDate = moment(startDate, ["DD.MM.YYYY"]);
    const eDate = moment(endDate, ["DD.MM.YYYY"]);

    if (sDate > eDate) {
      setError("startDate", { message: t("form.errorRange") });
      return null;
    }

    if (sDate <= moment().add(-1, "day")) {
      setError("startDate", { message: t("form.errorRange") });
      return null;
    }

    const sTime = moment(timeFrom, ["HH:mm:ss"]);
    const eTime = moment(timeTo, ["HH:mm:ss"]);

    if (sTime > eTime) {
      setError("timeFrom", { message: t("form.errorRange") });
      return null;
    }

    if (selectedCategories.length <= 0) {
      setSelectedCategoriesError(t("form.selectValue"));
      return null;
    }

    const coords = marker.coordinate as LatLng;

    createService({
      ...submitData,
      startDate:
        sDate.format("YYYY-MM-DD") !== "Invalid date"
          ? sDate.format("YYYY-MM-DD")
          : null,
      timeFrom: timeFrom !== "Invalid date" ? timeFrom : null,
      timeTo: timeTo !== "Invalid date" ? timeTo : null,
      endDate:
        eDate.format("YYYY-MM-DD") !== "Invalid date"
          ? eDate.format("YYYY-MM-DD")
          : null,
      categories: selectedCategories,
      days: selectedDays.filter((item) => (item as string) !== "EVERYDAY"),
      reward: rewardTypes[0],
      currency,
      radius: sliderValue,
      option: switcher,
      coordinatesName: marker?.title ?? "",
      coordinates: coords
        ? `${coords.latitude?.toString()},${coords.longitude?.toString()}`
        : undefined,
    });

    return null;
  };

  const isFormValid = useMemo(
    () => isValid && selectedCategories.length >= 1,
    [isValid, selectedCategories.length]
  );

  return (
    <Header
      centerTitle={t("ad.createHeader")}
      leftAction={
        navigation.getState().type === "stack"
          ? () => getNavigationBack(navigation)
          : null
      }
      switcher
      loadingBody={loading}
      scrollingBody
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <FormInput
              name="title"
              control={control}
              required
              variant="text"
              placeholder={t("ad.titlePlaceholder")}
              label={`${t("ad.title")}:`}
              returnKeyType="done"
            />
            <ElementWrapper
              title={`${t("filter.adType")}:`}
              background={theme.transparent}
              error={selectedCategoriesError}
            >
              <TreeComponent
                data={categories}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
              />
            </ElementWrapper>
            <ElementWrapper title={`${t("filter.address")}:`}>
              <EditValue
                value={marker?.title ?? ""}
                buttonTitle={t("filter.change").toLocaleLowerCase()}
                onPress={() =>
                  navigation.navigate("Map", {
                    markerRadius: sliderValue,
                    maxMarkers: 1,
                  })
                }
              />
            </ElementWrapper>
            <ElementWrapper title={`${t("filter.range")}:`}>
              <Slider
                values={sliderValue}
                setValues={(num: number) => {
                  setSliderValue(num);
                  setMarkers((prev) =>
                    prev.map((prevState) => ({
                      ...prevState,
                      radius: num * 1000,
                    }))
                  );
                }}
                unit="km"
              />
            </ElementWrapper>
            <ElementWrapper
              title={`${t("filter.reward")}:`}
              background={theme.transparent}
              error={rewardTypesError}
            >
              <TreeComponent
                data={REWARD_DATA}
                selectedCategories={rewardTypes}
                setSelectedCategories={setRewardTypes}
                onlyOne
              />
            </ElementWrapper>
            {rewardTypes[0] === "PRICE" && (
              <FormInput
                name="price"
                variant="text"
                control={control}
                required
                placeholder={currency}
                keyboardType="number-pad"
                returnKeyType="done"
              />
            )}
            <ElementWrapper
              title={`${t("filter.days")}:`}
              background={theme.transparent}
              error={selectedDaysError}
            >
              <TreeComponent
                data={DAYS_DATA}
                selectedCategories={selectedDays}
                setSelectedCategories={setSelectedDays}
              />
            </ElementWrapper>
            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                width="100%"
                textAlign="left"
              >
                {`${t("filter.time")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeFrom"
                    variant="masked"
                    control={control}
                    setError={(message) => setError("timeFrom", { message })}
                    type="time"
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="timeTo"
                    variant="masked"
                    control={control}
                    setError={(message) => setError("timeTo", { message })}
                    type="time"
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <RangeWrapper>
              <Text
                flex="1"
                font="bold"
                size="15px"
                textColor={theme.highlightText}
                textAlign="left"
              >
                {`${t("filter.date")}:`}
              </Text>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.from"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="startDate"
                    variant="masked"
                    control={control}
                    setError={(message) => setError("startDate", { message })}
                    type="date"
                  />
                </RangeSubItem>
              </RangeItem>
              <RangeItem>
                <Text flex="1" size="13px">{`${t(
                  "filter.to"
                ).toLocaleLowerCase()}:`}</Text>
                <RangeSubItem>
                  <FormInput
                    name="endDate"
                    variant="masked"
                    control={control}
                    setError={(message) => setError("endDate", { message })}
                    type="date"
                  />
                </RangeSubItem>
              </RangeItem>
            </RangeWrapper>
            <FormInput
              name="description"
              variant="text"
              control={control}
              placeholder={t("ad.descriptionPlaceholder")}
              label={`${t("ad.description")}:`}
              returnKeyType="go"
              textarea
              height={100}
            />
            <Button
              width="100%"
              margin="10px 0 0 0"
              justifyContent="center"
              borderRadius={radiusSizes.smallRadius}
              background={isFormValid ? theme.secondary : theme.primaryOpacity}
              onPress={handleSubmit(submit)}
            >
              <Text size="21px" font="regular" textColor={theme.secondaryText}>
                {t("ad.submit")}
              </Text>
            </Button>
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default AddAd;
