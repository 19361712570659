import React, { useEffect, useState } from "react";
import { Keyboard, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import publicIP from "react-native-public-ip";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Linking from "expo-linking";

import { uniqueId } from "lodash";
import Device from "expo-device";

import { useForm } from "react-hook-form";
import { theme, themeColors } from "../../atoms/persisted/theme";

import LoadingComponent from "../../components/Loading";
import {
  EmptySpace,
  CenterWrapper,
  RowWrapper,
  BodyContentWrapper,
} from "../../components/wrappers";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Text from "../../components/Text";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";
import { useHandleRegister } from "../../services/auth";
import FormInput from "../../components/form";
import { ContextType } from "../../components/form/types";
import { UserLanguage } from "../../types/graphql";
import { TouchableOpacity } from "../../components/Themed";

const getUniqueDeviceId = async () => {
  try {
    if (Platform.OS === "web") {
      const ip = await publicIP();
      return ip;
    }
    const buildId = Device.osInternalBuildId;
    if (buildId) return buildId;
  } catch (e) {
    console.warn(e);
  }
  return uniqueId();
};

type FormValues = {
  fullName: string;
  email: string;
  phoneNumber: string;
  showPhoneNumber: boolean;
  checkGDPR: boolean;
};

const Register = ({ navigation }: RootStackScreenProps<"Register">) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isValid },
  } = useForm<FormValues, ContextType>({
    mode: "onChange",
    context: { refKey: uniqueId() },
  });

  const { t, i18n } = useTranslation();

  const [register] = useHandleRegister({
    onError: (e) => {
      setError("phoneNumber", { message: e.message ?? t("general.network") });
    },
    onCompleted: () => navigation.replace("Login", { type: "register" }),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const Colors = useRecoilValue(themeColors);
  const colorScheme = useRecoilValue(theme);

  const onSubmit = async ({
    showPhoneNumber,
    checkGDPR,
    email,
    ...submitData
  }: FormValues) => {
    setLoading(true);
    Keyboard.dismiss();

    const language = i18n.language.toUpperCase() as UserLanguage;
    const vendor = await getUniqueDeviceId();

    register({
      ...submitData,
      email: email.toLocaleLowerCase(),
      showPhoneNumber: showPhoneNumber ?? false,
      language,
      role: "CUSTOMER",
      colorScheme: colorScheme ?? "BLUE",
      vendor,
    });

    setLoading(false);
    return null;
  };

  useEffect(() => reset({}), [reset]);

  return (
    <Header
      centerTitle={t("auth.register.title")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      body={
        <BodyContentWrapper>
          <KeyboardAwareScrollView enableOnAndroid>
            <LoadingComponent visible={loading} />
            <EmptySpace height={35} />
            <Text
              size="30px"
              font="regular"
              lineHeight="36px"
              textColor={Colors.title}
            >
              {t("auth.register.header")}
            </Text>
            <EmptySpace height={35} />
            <Text size="15px" font="light" textColor={Colors.text}>
              {t("auth.register.text")}
            </Text>
            <EmptySpace height={35} />
            <FormInput
              name="fullName"
              variant="text"
              control={control}
              rules={{ minLength: 3 }}
              iconType="ionicons"
              iconName="person"
              required
              size={15}
              height={50}
              placeholder={t("auth.register.fullname")}
              returnKeyType="next"
            />
            <EmptySpace height={15} />
            <FormInput
              name="email"
              variant="email"
              control={control}
              iconType="materialCommunityIcons"
              iconName="email"
              required
              size={15}
              height={50}
              placeholder={t("auth.register.email")}
              returnKeyType="next"
              keyboardType="email-address"
            />
            <EmptySpace height={15} />
            <FormInput
              name="phoneNumber"
              variant="text"
              control={control}
              required
              iconType="fontAwesome"
              iconName="phone"
              size={15}
              height={50}
              placeholder={t("auth.register.phone")}
              returnKeyType="next"
              keyboardType="phone-pad"
            />
            <EmptySpace height={15} />
            <RowWrapper>
              <FormInput
                name="showPhoneNumber"
                variant="checkbox"
                control={control}
              />
              <Text
                size="15px"
                flex="1"
                textAlign="left"
                textColor={Colors.input.text}
              >
                {t("auth.register.checkbox")}
              </Text>
            </RowWrapper>
            <EmptySpace height={15} />
            <RowWrapper>
              <FormInput
                name="checkGDPR"
                variant="checkbox"
                control={control}
                required
              />
              <Text
                size="15px"
                flex="1"
                textAlign="left"
                textColor={Colors.input.text}
              >
                {t("auth.register.gdpr")}
              </Text>
            </RowWrapper>
            <RowWrapper style={{ marginLeft: 42 }}>
              <TouchableOpacity
                onPress={() => {
                  if (Platform.OS === "web") {
                    window.open(
                      "https://sousede-media.s3.eu-central-1.amazonaws.com/GDPR_Soused%C3%A92022nov%C3%A9.pdf"
                    );
                  } else {
                    void Linking.openURL(
                      "https://sousede-media.s3.eu-central-1.amazonaws.com/GDPR_Soused%C3%A92022nov%C3%A9.pdf"
                    );
                  }
                }}
              >
                <Text
                  size="14px"
                  underline
                  textAlign="left"
                  textColor={Colors.input.text}
                >
                  {t("auth.register.gdpr-link")}
                </Text>
              </TouchableOpacity>
            </RowWrapper>
            <EmptySpace height={55} />
            <CenterWrapper>
              <Button
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={isValid ? Colors.secondary : Colors.primaryOpacity}
                onPress={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                <Text
                  size="21px"
                  font="regular"
                  textColor={Colors.secondaryText}
                >
                  {t("auth.register.submit")}
                </Text>
              </Button>
            </CenterWrapper>
            <EmptySpace height={35} />
            <CenterWrapper>
              <Button
                justifyContent="center"
                onPress={() => navigation.navigate("Login")}
              >
                <RowWrapper>
                  <Text
                    size="15px"
                    font="light"
                    textColor={Colors.highlightText}
                  >
                    {t("auth.register.alreadyHasAccount").concat(" ")}
                  </Text>
                  <Text
                    size="15px"
                    font="regular"
                    underline
                    textColor={Colors.highlightText}
                  >
                    {t("auth.register.alreadyHasAccountSubmit")}
                  </Text>
                </RowWrapper>
              </Button>
            </CenterWrapper>
          </KeyboardAwareScrollView>
        </BodyContentWrapper>
      }
    />
  );
};

export default Register;
