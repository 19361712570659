import React, { useMemo } from "react";

import { RootStackScreenProps } from "../../../@types/navigation";

import NativeMap from "./Map";

const Map = ({ navigation, route }: RootStackScreenProps<"Map">) => {
  const markerRadiusInMeters = useMemo(() => {
    if (route.params.markerRadius) return route.params.markerRadius * 1000;

    return 1000;
  }, [route.params.markerRadius]);

  const MaxMarkers = useMemo(
    () => route?.params?.maxMarkers ?? null,
    [route?.params?.maxMarkers]
  );

  return (
    <NativeMap
      markerRadius={markerRadiusInMeters}
      navigation={navigation}
      maxMarkers={MaxMarkers}
    />
  );
};

export default Map;
