/**
 * @generated SignedSource<<92675f06882a2f18586bd48f9efffc3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Categories_AllServiceCategoriesQuery$variables = {};
export type Categories_AllServiceCategoriesQuery$data = {
  readonly allServiceCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly image: string | null;
        readonly parent: {
          readonly id: string;
        } | null;
        readonly subcategories: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly title: string;
              readonly parent: {
                readonly id: string;
              } | null;
              readonly subcategories: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly parent: {
                      readonly id: string;
                    } | null;
                    readonly subcategories: {
                      readonly edges: ReadonlyArray<{
                        readonly node: {
                          readonly id: string;
                          readonly title: string;
                          readonly parent: {
                            readonly id: string;
                          } | null;
                          readonly subcategories: {
                            readonly edges: ReadonlyArray<{
                              readonly node: {
                                readonly id: string;
                                readonly title: string;
                                readonly parent: {
                                  readonly id: string;
                                } | null;
                              } | null;
                            } | null>;
                          };
                        } | null;
                      } | null>;
                    };
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};
export type Categories_AllServiceCategoriesQuery = {
  variables: Categories_AllServiceCategoriesQuery$variables;
  response: Categories_AllServiceCategoriesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceCategoryType",
  "kind": "LinkedField",
  "name": "parent",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ServiceCategoryTypeConnection",
    "kind": "LinkedField",
    "name": "allServiceCategories",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceCategoryTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceCategoryType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceCategoryTypeConnection",
                "kind": "LinkedField",
                "name": "subcategories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceCategoryTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServiceCategoryType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ServiceCategoryTypeConnection",
                            "kind": "LinkedField",
                            "name": "subcategories",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ServiceCategoryTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ServiceCategoryType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v0/*: any*/),
                                      (v1/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ServiceCategoryTypeConnection",
                                        "kind": "LinkedField",
                                        "name": "subcategories",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ServiceCategoryTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ServiceCategoryType",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v0/*: any*/),
                                                  (v1/*: any*/),
                                                  (v2/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ServiceCategoryTypeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "subcategories",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ServiceCategoryTypeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ServiceCategoryType",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                              (v0/*: any*/),
                                                              (v1/*: any*/),
                                                              (v2/*: any*/)
                                                            ],
                                                            "storageKey": null
                                                          }
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Categories_AllServiceCategoriesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Categories_AllServiceCategoriesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b96e969a933dc5c28d75b461ca3d9f29",
    "id": null,
    "metadata": {},
    "name": "Categories_AllServiceCategoriesQuery",
    "operationKind": "query",
    "text": "query Categories_AllServiceCategoriesQuery {\n  allServiceCategories {\n    edges {\n      node {\n        id\n        title\n        image\n        parent {\n          id\n        }\n        subcategories {\n          edges {\n            node {\n              id\n              title\n              parent {\n                id\n              }\n              subcategories {\n                edges {\n                  node {\n                    id\n                    title\n                    parent {\n                      id\n                    }\n                    subcategories {\n                      edges {\n                        node {\n                          id\n                          title\n                          parent {\n                            id\n                          }\n                          subcategories {\n                            edges {\n                              node {\n                                id\n                                title\n                                parent {\n                                  id\n                                }\n                              }\n                            }\n                          }\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3050b3fc5817578e6f4a1013fdfccc3f";

export default node;
