import { useTranslation } from "react-i18next";

import { Tree } from "../../@types/tree";
import { ServiceRewardChoices, DayOfTheWeekChoices } from "../../types/graphql";

const useGetServicesData = () => {
  const { t } = useTranslation();

  const REWARD_DATA: Tree<ServiceRewardChoices> = {
    edges: [
      {
        node: {
          id: "FREE",
          title: t("filter.free"),
        },
      },
      {
        node: {
          id: "EXCHANGE",
          title: t("filter.exchange"),
        },
      },
      {
        node: {
          id: "PRICE",
          title: t("filter.price"),
        },
      },
    ],
  };

  const DAYS_DATA: Tree<DayOfTheWeekChoices | "EVERYDAY"> = {
    edges: [
      {
        node: {
          id: "EVERYDAY",
          title: `${t("filter.every")} ${t("filter.day").toLocaleLowerCase()}`,
          subcategories: {
            edges: [
              {
                node: {
                  id: "MONDAY",
                  title: t("filter.dayList.mo"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "TUESDAY",
                  title: t("filter.dayList.tu"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "WEDNESDAY",
                  title: t("filter.dayList.we"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "THURSDAY",
                  title: t("filter.dayList.th"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "FRIDAY",
                  title: t("filter.dayList.fr"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "SATURDAY",
                  title: t("filter.dayList.sa"),
                  parent: { id: "EVERYDAY" },
                },
              },
              {
                node: {
                  id: "SUNDAY",
                  title: t("filter.dayList.su"),
                  parent: { id: "EVERYDAY" },
                },
              },
            ],
          },
        },
      },
    ],
  };
  const RANGE_DATA: Tree<string> = {
    edges: [
      {
        node: {
          id: "0",
          title: t("filter.range"),
        },
      },
    ],
  };

  return {
    DAYS_DATA,
    REWARD_DATA,
    RANGE_DATA,
  };
};

export default useGetServicesData;
