import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Platform } from "react-native";
import i18n from "i18next";

import {
  commitMutation,
  FetchFunction,
  GraphQLResponseWithData,
  RequestParameters,
  Variables,
} from "relay-runtime";
import { extractFiles } from "extract-files";

// upstream (relay generation)
// eslint-disable-next-line import/no-cycle
import createRelayEnvironment from ".";

import {
  getStorageValue,
  removeStorageValue,
  setStorageValue,
} from "../services/storage";

import { GRAPHQL } from "../constants/Endpoints";
import { User_refreshTokenMutation } from "../__generated__/User_refreshTokenMutation.graphql";
import { refreshAccessToken } from "./queries/User";
import { navigate } from "../navigation/utils";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../atoms/accessToken";
import { handleLogout } from "../services/auth";

export const RELAY_AXIOS_INSTANCE = axios.create();

const relayRequest = async (
  accessToken: string | undefined | null,
  operation: RequestParameters,
  variables: Variables
) => {
  const request: AxiosRequestConfig = {
    url: GRAPHQL,
    method: "POST",
    headers: {},
  };

  const operations = {
    query: operation.text,
    variables,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { clone, files } = extractFiles<File>(operations);

  // Type refinement
  const extractedOperations = clone as typeof operations;
  if (files.size) {
    if (Platform.OS === "android") {
      (request.headers as Record<string, unknown>)["Content-Type"] =
        "multipart/form-data";
    }

    const formData = new FormData();
    formData.append("operations", JSON.stringify(extractedOperations));

    const pathMap: Record<number, string[]> = {};
    let i = 0;

    files.forEach((paths) => {
      pathMap[i++] = paths;
    });
    formData.append("map", JSON.stringify(pathMap));

    i = 0;

    files.forEach((__, file) => {
      formData.append(String(i++), file, file.name);
    });

    request.data = formData;
  } else {
    (request.headers as Record<string, unknown>)["Content-Type"] =
      "application/json";

    request.data = JSON.stringify(operations);
  }

  if (accessToken)
    (request.headers as Record<string, unknown>).Authorization = `Bearer ${
      accessToken ?? ""
    }`;

  (request.headers as Record<string, unknown>)["Accept-Language"] =
    i18n.language;

  const { data } = (await RELAY_AXIOS_INSTANCE(
    request
  )) as AxiosResponse<GraphQLResponseWithData>;

  return data;
};

const relayFetcher: FetchFunction = async (request, variables) => {
  let response: GraphQLResponseWithData = {
    data: {
      [request.name]: {
        edges: [],
      },
    },
  };

  const token = await getStorageValue(ACCESS_TOKEN_KEY);
  const refresh = await getStorageValue(REFRESH_TOKEN_KEY);
  response = await relayRequest(token, request, variables);

  if (response.errors) {
    const errorMessage = response.errors[0].message;

    if (errorMessage === "Signature has expired") {
      await removeStorageValue(ACCESS_TOKEN_KEY);

      if (refresh)
        commitMutation<User_refreshTokenMutation>(createRelayEnvironment(), {
          mutation: refreshAccessToken,
          variables: {
            refreshToken: refresh,
          },
          onCompleted: async (res) => {
            const accessToken = res.refreshToken.token;
            response = await relayRequest(accessToken, request, variables);
            await setStorageValue(ACCESS_TOKEN_KEY, accessToken);
            if (response.errors) {
              console.warn("refresh token expired");
              navigate("Login");
            }
          },
          onError: async () => {
            console.warn("refresh token expired");
            await handleLogout();
            navigate("Login");
          },
        });
      else navigate("Login");
    } else {
      if (
        response.errors[0].message === "User matching query does not exist."
      ) {
        await handleLogout();
        navigate("Login");
      }
      throw new Error(
        (response.errors ?? [{ message: "unknown error" }])
          .map((err) => err.message)
          .join(" \n")
      );
    }
  }

  return response;
};

export default relayFetcher;
