import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Platform } from "react-native";
import { DefaultResources, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import s from "styled-components/native";
import * as Linking from "expo-linking";

import { fetchQuery } from "react-relay";
import moment from "moment";
import Header, { Tag, ItemImage, BodyRow } from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Icon from "../../components/Icon";
import { View, TouchableOpacity } from "../../components/Themed";
import {
  RowWrapper,
  ColumnWrapper,
  CenterWrapper,
  EmptySpace,
} from "../../components/wrappers";
import ConfirmModal from "../../components/ConfirmModal";

import { themeColors } from "../../atoms/persisted/theme";
import userAtom from "../../atoms/persisted/user";
import refetchKeyAtom from "../../atoms/refetchKey";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";
import { Services_serviceQuery } from "../../__generated__/Services_serviceQuery.graphql";
import { getServiceQuery, ServiceDetail } from "../../relay/queries/Services";
import { useResettableRelayContext } from "../../relay/ResettableRelayProvider";
import { useUpdateService } from "../../services/services";
import useUserServices from "../../hooks/useUserServices";
import { cutString } from "../../services/text";

import useGoogleAds from "../../components/AdMob";

const BodyItemLeft = s(View)`
  flex: 1;
`;

const BodyItemRight = s(View)`
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

const DetailAd = ({ navigation, route }: RootStackScreenProps<"DetailAd">) => {
  const id = route.params?.id;
  if (!id) throw new Error("id not defined");

  const { t } = useTranslation();

  const { environment } = useResettableRelayContext();

  const [refetchKey, setRefetchKey] = useRecoilState(refetchKeyAtom);

  const [service, setService] = useState<ServiceDetail>(null);
  const [loading, setLoading] = useState(false);

  const { transferService } = useUserServices();

  const getData = useCallback(async () => {
    const { service: data } = await fetchQuery<Services_serviceQuery>(
      environment,
      getServiceQuery,
      { id }
    ).toPromise();
    if (!data) return null;

    return data;
  }, [environment, id]);

  const refetch = useCallback(async () => {
    setLoading(true);
    const data = await getData();
    setService(data);
    setLoading(false);
  }, [getData]);

  useEffect(() => {
    void refetch();
  }, [refetch, refetchKey]);

  const theme = useRecoilValue(themeColors);
  const user = useRecoilValue(userAtom);

  const isMine = useMemo(
    () => service?.user?.id === user?.id,
    [user?.id, service?.user?.id]
  );

  const isServiceActive = useMemo(
    () => service?.isActive ?? false,
    [service?.isActive]
  );

  const [reportModal, setReportModal] = useState(false);

  const [activeModal, setActiveModal] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const [deActiveModal, setDeActiveModal] = useState(false);

  const showAd = useGoogleAds();

  const onCompleted = async () => {
    setLoading(true);
    await refetch();
    setRefetchKey((p) => p + 1);
    transferService({ id: service.id, to: service.isActive });
    setLoading(false);
    setActiveModal(false);
    setDeActiveModal(false);
  };

  const [updateService] = useUpdateService({
    onCompleted: () => void onCompleted(),
    onError: () => console.warn("service update failed"),
  });

  const handleActiveOrContact = useCallback(() => {
    if (!isMine) {
      showAd();
      setInformationModal(true);
      return;
    }

    if (isServiceActive) setDeActiveModal(true);
    else setActiveModal(true);
  }, [isMine, isServiceActive, showAd]);

  const showReportModal = () => {
    setReportModal(true);
  };

  const getDayIndex = (day: string) => {
    switch (day) {
      case "mo":
        return 1;
      case "tu":
        return 2;
      case "we":
        return 3;
      case "th":
        return 4;
      case "fr":
        return 5;
      case "sa":
        return 6;
      case "su":
        return 7;
      default:
        return 0;
    }
  };

  const formattedDays = useMemo(() => {
    if (!service?.days || service?.days?.length === 0) return "";
    if (service.days.length === 7) return t("days.everyday");
    const days = service.days.slice().map((day) => {
      const d = day
        .slice(0, 2)
        .toLowerCase() as keyof DefaultResources["common"]["days"];
      const i = getDayIndex(d);
      const title = t(`days.${d}`);
      return { title, i };
    });

    return days
      .sort((a, b) => (a.i > b.i ? 1 : -1))
      .slice()
      .map((day) => day.title)
      .join(", ");
  }, [service?.days, t]);

  // show phone number only to registered users
  const showPhoneNumber = useMemo(
    () => service?.user?.showPhoneNumber || !!user,
    [service?.user?.showPhoneNumber, user]
  );

  const formattedCategories = useMemo(
    () => service?.categories?.edges.map(({ node }) => node.title).join(", "),
    [service?.categories?.edges]
  );

  return (
    <Header
      centerTitle={t("detail.header")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      bodyOverflow="visible"
      loadingBody={loading}
      loadingHeader={loading}
      bodyHeader={
        <>
          <BodyRow height={50} marginBottom={20}>
            <BodyItemLeft>
              <RowWrapper>
                <TouchableOpacity
                  onPress={() => {
                    if (user)
                      navigation.navigate("User", { id: service.user.id });
                  }}
                >
                  <ItemImage
                    source={{
                      uri: service?.user?.image,
                    }}
                  />
                </TouchableOpacity>
                <ColumnWrapper style={{ marginHorizontal: 25 }}>
                  <Text
                    font="bold"
                    textColor={theme.highlightText}
                    size="14px"
                    margin="5px 0"
                    textAlign="left"
                  >
                    {cutString(service?.user?.fullName ?? "", 20)}
                  </Text>
                  <RowWrapper>
                    <Icon
                      type="materialIcons"
                      name="location-pin"
                      size={15}
                      color={theme.border}
                    />
                    <Text textColor={theme.border} size="12px" textAlign="left">
                      {service?.coordinatesName}
                    </Text>
                  </RowWrapper>
                </ColumnWrapper>
              </RowWrapper>
            </BodyItemLeft>
            {user && (
              <BodyItemRight>
                <Button
                  width="60px"
                  height="60px"
                  justifyContent="center"
                  borderRadius={radiusSizes.biggerRadius}
                  background={theme.secondary}
                  onPress={() => {
                    if (isMine) navigation.navigate("EditAd", { id });
                    else showReportModal();
                  }}
                >
                  <Icon
                    type={isMine ? "fontAwesome5" : "ionicons"}
                    name={isMine ? "pen" : "warning"}
                    size={18}
                    color={theme.white}
                  />
                </Button>
              </BodyItemRight>
            )}
          </BodyRow>
        </>
      }
      body={
        <>
          <CenterWrapper>
            <View
              background={theme.white}
              style={{
                borderRadius: radiusSizes.basicRadius,
                padding: 25,
                justifyContent: "center",
                width: "100%",
                maxWidth: 1000,
                alignSelf: "center",
              }}
            >
              {service?.status === "RESOLVED_REPORT" && (
                <Text
                  size="15px"
                  flex="3"
                  textAlign="left"
                  textColor={theme.secondary}
                >
                  {`⚠ ${t("ad.reported")}: ${t("ad.reportedDescription")}`}
                </Text>
              )}
              <Text
                font="bold"
                textColor={theme.highlightText}
                margin="25px 5px"
                size="23px"
                lineHeight="23px"
                textAlign="left"
                style={{ minWidth: "50%" }}
              >
                {service?.title}
              </Text>
              <RowWrapper>
                <Text
                  font="bold"
                  textColor={theme.secondary}
                  size="23px"
                  lineHeight="23px"
                  textAlign="left"
                  margin="0 0 25px 5px"
                  style={{ minWidth: "50%" }}
                  flex="1"
                >
                  {service?.price ? Number(service.price).toFixed(0) : ""}
                </Text>
                <ColumnWrapper>
                  <Text
                    font="regular"
                    textColor={theme.highlightText}
                    size="14px"
                    lineHeight="14px"
                    textAlign="left"
                  >
                    {formattedDays}
                  </Text>
                  <Text
                    font="light"
                    textColor={theme.text}
                    size="14px"
                    lineHeight="14px"
                    textAlign="left"
                    flex="1"
                  >
                    {`${
                      service?.timeFrom
                        ? moment(service.timeFrom as string, [
                            "HH:mm:ss",
                          ]).format("HH:mm")
                        : ""
                    } - ${
                      service?.timeTo
                        ? moment(service.timeTo as string, ["HH:mm:ss"]).format(
                            "HH:mm"
                          )
                        : ""
                    }`}
                  </Text>
                </ColumnWrapper>
              </RowWrapper>
              <RowWrapper>
                <Tag
                  background={
                    service?.option === "PROVIDE"
                      ? theme.successSurface
                      : theme.primaryOpacity
                  }
                >
                  <Text
                    textColor={
                      service?.option === "PROVIDE"
                        ? theme.successText
                        : theme.primary
                    }
                    size="14px"
                    lineHeight="14px"
                  >
                    {service?.option === "PROVIDE"
                      ? t("filter.sell")
                      : t("filter.offer")}
                  </Text>
                </Tag>
                <Text
                  font="bold"
                  textColor={theme.highlightText}
                  size="14px"
                  padding="0 0 0 20px"
                  textAlign="left"
                  style={{ maxWidth: "50%" }}
                >
                  {formattedCategories}
                </Text>
              </RowWrapper>
              <Text
                textAlign="left"
                size="14px"
                font="light"
                margin="25px 5px"
                textColor={theme.text}
              >
                {service?.description}
              </Text>
              <Button
                justifyContent="center"
                borderRadius={radiusSizes.smallRadius}
                background={theme.secondary}
                onPress={() => handleActiveOrContact()}
              >
                <Text
                  size="14px"
                  font="regular"
                  textColor={theme.secondaryText}
                >
                  {!isMine ? t("detail.getContact") : null}
                  {isMine && isServiceActive ? t("detail.deactive") : null}
                  {isMine && !isServiceActive ? t("detail.active") : null}
                </Text>
              </Button>
            </View>
          </CenterWrapper>
          <ConfirmModal
            visible={activeModal}
            setVisible={setActiveModal}
            title={t("detail.modal.active.title")}
            description={t("detail.modal.active.description")}
            ok={t("general.yes")}
            okAction={() => updateService({ id, isActive: true })}
            nok={t("general.no")}
          />
          <ConfirmModal
            visible={deActiveModal}
            setVisible={setDeActiveModal}
            title={t("detail.modal.deactive.title")}
            description={t("detail.modal.deactive.description")}
            ok={t("general.yes")}
            okAction={() => updateService({ id, isActive: false })}
            nok={t("general.no")}
          />
          <ConfirmModal
            visible={informationModal}
            setVisible={setInformationModal}
            title={t("detail.modal.information.title")}
            body={
              <>
                <Button
                  borderRadius={radiusSizes.smallRadius}
                  background={theme.secondary}
                  width="200px"
                  onPress={() =>
                    Linking.openURL(`mailto:${service.user.email}`)
                  }
                >
                  <Icon
                    type="materialIcons"
                    size={14}
                    name="email"
                    color={theme.white}
                  />
                  <Text
                    size="14px"
                    font="regular"
                    textColor={theme.white}
                    margin="0 5px"
                  >
                    {t("detail.modal.contact.email")}
                  </Text>
                </Button>
                {showPhoneNumber && (
                  <>
                    <EmptySpace height={10} />
                    <Button
                      borderRadius={radiusSizes.smallRadius}
                      background={theme.white}
                      width="200px"
                      onPress={() =>
                        Linking.openURL(`tel:${service.user.phoneNumber}`)
                      }
                      borderColor={theme.text}
                    >
                      <Icon
                        type="fontAwesome"
                        size={14}
                        name="phone"
                        color={theme.text}
                      />
                      <Text
                        size="14px"
                        font="regular"
                        textColor={theme.text}
                        margin="0 5px"
                      >
                        {t("detail.modal.contact.phone")}
                      </Text>
                    </Button>
                  </>
                )}
              </>
            }
          />
          <ConfirmModal
            visible={reportModal}
            setVisible={setReportModal}
            title={t("detail.modal.report.title")}
            description={t("detail.modal.report.description")}
            ok={t("general.yes")}
            okAction={() => setReportModal(false)}
            nok={t("general.no")}
            nokAction={() => setReportModal(false)}
          />
        </>
      }
    />
  );
};

export default DetailAd;
