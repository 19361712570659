import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { MarkerProps } from "react-native-maps";
import s from "styled-components/native";
import { Platform, StyleProp, ViewStyle } from "react-native";
import { View } from "../../../components/Themed";
import { RootStackParamList } from "../../../@types/navigation";
import { DEFAULT_COORDS } from "../../../atoms/filter";

export type MarkerType = MarkerProps & { radius?: number };

export type MapProps = {
  navigation: NativeStackNavigationProp<RootStackParamList, "Map">;
  maxMarkers: number;
  markerRadius: number | undefined;
} | null;

export const SearchBar = s(View)`
  margin-horizontal: 8px;
`;

export const MapWrapper = s(View)`
`;
export const defaultMapLocation = {
  ...DEFAULT_COORDS,
  latitudeDelta: 0.01,
  longitudeDelta: 0.05,
};

export const mapStyles: StyleProp<ViewStyle> =
  Platform.OS === "web"
    ? {
        display: "flex",
        borderRadius: 15,
        height: "calc(100vh - 240px)",
        width: "100%",
      }
    : {
        display: "flex",
        borderRadius: 15,
        alignSelf: "stretch",
        height: "95%",
      };

export const searchBarStyles =
  Platform.OS === "web" ? { zIndex: 2, marginHorizontal: -4 } : undefined;
