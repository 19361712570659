import React from "react";
import { useTranslation } from "react-i18next";
import s from "styled-components/native";
import { useRecoilState, useRecoilValue } from "recoil";

import { TouchableOpacity } from "./Themed";
import Text from "./Text";

import switcherAtom from "../atoms/switcher";
import { themeColors } from "../atoms/persisted/theme";

import { CenterWrapper, RowWrapper } from "./wrappers";

const RowWrapperStyled = s(RowWrapper)`
  border-radius: 18px;
`;

const TouchableOpacityStyled = s(TouchableOpacity)`
  border-radius: 18px;
  margin: 5px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
`;

const Header = () => {
  const [switcher, setSwitcher] = useRecoilState(switcherAtom);
  const theme = useRecoilValue(themeColors);

  const { t } = useTranslation();

  return (
    <CenterWrapper>
      <RowWrapperStyled background={theme.white}>
        <TouchableOpacityStyled
          background={switcher === "REQUEST" ? theme.primary : theme.white}
          onPress={() => setSwitcher("REQUEST")}
        >
          <Text
            font="regular"
            size="13px"
            textColor={switcher === "REQUEST" ? theme.white : theme.primary}
          >
            {t("filter.offer")}
          </Text>
        </TouchableOpacityStyled>
        <TouchableOpacityStyled
          background={switcher === "REQUEST" ? theme.white : theme.successText}
          onPress={() => setSwitcher("PROVIDE")}
        >
          <Text
            font="regular"
            size="13px"
            textColor={switcher === "REQUEST" ? theme.primary : theme.white}
          >
            {t("filter.sell")}
          </Text>
        </TouchableOpacityStyled>
      </RowWrapperStyled>
    </CenterWrapper>
  );
};

export default Header;
