import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { uniqueId } from "lodash";

import { ContextType } from "../../components/form/types";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { View } from "../../components/Themed";
import {
  CenterWrapper,
  EmptySpace,
  RowWrapper,
} from "../../components/wrappers";
import FormInput from "../../components/form";

import { themeColors } from "../../atoms/persisted/theme";

import { radiusSizes } from "../../constants/Sizes";

import { RootStackScreenProps } from "../../@types/navigation";

import { getNavigationBack } from "../../utils/navigation";
import userAtom from "../../atoms/persisted/user";
import { useDeleteAccount, handleLogout } from "../../services/auth";

type FormValues = {
  check: boolean;
};

const DeleteUser = ({ navigation }: RootStackScreenProps<"DeleteUser">) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    formState: { isValid },
  } = useForm<FormValues, ContextType>({
    mode: "onChange",
    context: { refKey: uniqueId() },
  });

  const user = useRecoilValue(userAtom);
  const theme = useRecoilValue(themeColors);
  const [deleteAccount] = useDeleteAccount();

  const handleDelete = () => {
    deleteAccount(
      {
        id: user?.id,
      },
      {
        onError: (e: Error) => {
          console.warn(e);
        },
        onSuccess: () => {
          void handleLogout();
          navigation.navigate("Root");
        },
      }
    );
  };

  useEffect(() => reset({}), [reset]);

  return (
    <Header
      centerTitle={t("editProfile.deleteUser")}
      leftAction={() => getNavigationBack(navigation)}
      scrollingBody
      bodyOverflow="visible"
      body={
        <CenterWrapper>
          <View
            style={{
              borderRadius: radiusSizes.basicRadius,
              justifyContent: "center",
              width: "100%",
              maxWidth: 1000,
              alignSelf: "center",
            }}
          >
            <EmptySpace height={25} />
            <Text size="18px" font="regular" textColor={theme.text}>
              {t("editProfile.deleteUserText")}
            </Text>
            <EmptySpace height={50} />
            <RowWrapper>
              <FormInput
                name="check"
                variant="checkbox"
                required
                control={control}
              />
              <Text
                size="15px"
                flex="1"
                textAlign="left"
                textColor={theme.text}
              >
                {t("editProfile.deleteCheckbox")}
              </Text>
            </RowWrapper>
            <EmptySpace height={10} />
            <Button
              justifyContent="center"
              borderRadius={radiusSizes.smallRadius}
              background={isValid ? theme.secondary : theme.primaryOpacity}
              onPress={() => handleDelete()}
              disabled={!isValid}
            >
              <Text size="14px" font="regular" textColor={theme.secondaryText}>
                {t("editProfile.deleteUser")}
              </Text>
            </Button>
          </View>
        </CenterWrapper>
      }
    />
  );
};

export default DeleteUser;
