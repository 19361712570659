import {
  ProviderExoticComponent,
  ProviderProps,
  createContext,
  useContext,
} from "react";

type CreateCtx<A> = readonly [
  () => A,
  ProviderExoticComponent<ProviderProps<A | undefined>>
];

function createCtx<A>(): CreateCtx<A> {
  const ctx = createContext<A | undefined>(undefined);

  function useCtx(): A {
    const c = useContext(ctx);

    if (!c) throw new Error("useCtx must be inside a Provider with a value");

    return c;
  }

  return [useCtx, ctx.Provider] as const;
}

export default createCtx;
