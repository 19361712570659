import React, { FC } from "react";
import { FlatList, RefreshControl, Platform } from "react-native";
import s from "styled-components/native";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import SectionItem, { SectionItemProps } from "./SectionItem";
import { View } from "../Themed";
import Text from "../Text";
import { themeColors } from "../../atoms/persisted/theme";
import { radiusSizes } from "../../constants/Sizes";
import Icon from "../Icon";
import { EmptySpace } from "../wrappers";
import { Service, UserService } from "../../relay/queries/Services";

const Wrapper = s(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 7px 15px;
`;

const ItemLeft = s(View)`
  flex: 2;
  align-items: flex-start;
`;

const ItemRight = s(View)`
  flex: 1;
  align-items: flex-end;
`;

export type SectionListDataType = Service | UserService;

type PropsUISectionList = {
  data: SectionListDataType[];
  refresh?: boolean;
  onPress?: (item: SectionListDataType) => void;
  onButtonPress?: (item: SectionListDataType) => void;
  onRefresh?: () => void;
  sectionItem?: (props: SectionItemProps) => JSX.Element;
  sectionTitle?: string;
  sectionHeaderAction?: () => void;
  additionalHeaderComponent?: JSX.Element;
};

const UISectionList: FC<PropsUISectionList> = (props: PropsUISectionList) => {
  const theme = useRecoilValue(themeColors);
  const { t } = useTranslation();

  if (Array.isArray(props.data) && props.data.length === 0)
    return (
      <Text style={{ width: "100%", textAlign: "center", marginTop: 50 }}>
        {t("general.emptyData")}
      </Text>
    );

  return (
    <FlatList
      ListFooterComponent={Platform.OS !== "web" && <EmptySpace height={440} />}
      style={{ height: "100%" }}
      contentContainerStyle={{
        paddingHorizontal: Platform.OS !== "web" ? 15 : 0,
        width: "100%",
      }}
      scrollIndicatorInsets={{ right: 1 }}
      data={props.data}
      ListHeaderComponent={
        <>
          <Wrapper>
            <ItemLeft>
              {props.sectionTitle && (
                <Text size="15px" font="bold" textColor={theme.highlightText}>
                  {props.sectionTitle}
                </Text>
              )}
            </ItemLeft>
            <ItemRight>
              {props.sectionHeaderAction && (
                <Button
                  width="37px"
                  height="37px"
                  justifyContent="center"
                  padding="0"
                  borderRadius={radiusSizes.smallRadius}
                  background={theme.secondary}
                  onPress={() => props.sectionHeaderAction()}
                >
                  <Icon
                    type="antDesign"
                    name="plus"
                    size={20}
                    color={theme.primaryText}
                  />
                </Button>
              )}
            </ItemRight>
          </Wrapper>
          {props.additionalHeaderComponent}
        </>
      }
      refreshControl={
        props.onRefresh && (
          <RefreshControl
            refreshing={props.refresh ?? false}
            onRefresh={props.onRefresh}
          />
        )
      }
      renderItem={({ item }) =>
        props.sectionItem ? (
          <props.sectionItem
            item={item}
            onPress={props.onPress}
            onButtonPress={props.onButtonPress}
          />
        ) : (
          <SectionItem item={item} onPress={props.onPress} />
        )
      }
    />
  );
};

export default UISectionList;
